import { ReactNode } from "react";
import "./CollapseInformation.scss";

interface WrapperProps {
	children: ReactNode;
}

export const CollapseInformationWrapper: React.FC<WrapperProps> = ({
	children,
}) => {
	return (
		<div className="wrapper">
			<ul className="collapse-list">{children}</ul>
		</div>
	);
};
