/* eslint-disable no-undef */
import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routeConstants } from "routes";
import { connect } from "react-redux";
import * as R from "ramda";
import ReactGA from "react-ga";

const WithNavigationPrompt = ({
	shouldPromptOnRoute,
	children,
	generate_loaded_data,
}) => {
	const [shouldPrompt, setShouldPrompt] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();

	const handleBeforeUnload = useCallback(
		(e) => {
			if (shouldPrompt) {
				e.preventDefault();
				e.returnValue = "Are you sure you want to leave?";
			}
		},
		[shouldPrompt]
	);

	const handlePopState = useCallback(
		(e) => {
			if (
				shouldPrompt &&
				!window.confirm(
					"Are you sure you want to continue? All images may be lost!"
				)
			) {
				e.preventDefault();
				history.pushState({}, null, location.pathname);
			}
		},
		[shouldPrompt, location.pathname]
	);

	useEffect(() => {
		window.addEventListener("beforeunload", handleBeforeUnload);
		window.addEventListener("popstate", handlePopState);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
			window.removeEventListener("popstate", handlePopState);
		};
	}, [handleBeforeUnload, handlePopState]);

	useEffect(() => {
		if (
			location.pathname ===
				(shouldPromptOnRoute && routeConstants.generated_product.route) &&
			!R.isEmpty(generate_loaded_data) &&
			generate_loaded_data.data &&
			generate_loaded_data.data.length > 0
		) {
			setShouldPrompt(true);
		} else {
			setShouldPrompt(false);
		}
	}, [location.pathname, shouldPromptOnRoute, generate_loaded_data]);

	const handleNavigation = (path) => {
		ReactGA.event({
			category: "NavigationToAnotherPageConfirmation",
			action: "NavigationToAnotherPageConfirmation",
			label: path,
			value: 0,
		});
		if (
			!shouldPrompt ||
			(shouldPrompt &&
				window.confirm(
					"Are you sure you want to continue? All images may be lost!"
				))
		) {
			navigate(path);
		}
	};

	return (
		<>
			{children &&
				React.Children.map(children, (child) => {
					if (React.isValidElement(child) && typeof child.type !== "string") {
						return React.cloneElement(child, { handleNavigation });
					}
					return child;
				})}
		</>
	);
};

const mapStateToProps = (state) => ({
	generate_loaded_data: state.generate.data,
});

export default connect(mapStateToProps)(WithNavigationPrompt);
