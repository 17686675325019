import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import styles from "./ProductPage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";
import CategoryCollection from "components/CategoryCollection/CategoryCollection";
import ProductCardCollection from "components/ProductCardCollection/ProductCardCollection";

import { fetchResourceById } from "airedux/actions";
import { getResourceById } from "airedux/selectors";
import { resourcePage } from "airedux/reducers/resourcePage.reducer";

import ReactGA from "react-ga";
import Spinner from "modules/loaders/Spinner/Spinner";
import { serviceConfig } from "configs";
import GeneratedProductItem from "common/GeneratedProductItem/GeneratedProductItem";
import SEOHelmet from "components/SEOHelmet/SEOHelmet";

interface IPropsProductPage {
	res_id: any;
	storeType: "client" | "admin";
}

const ProductPage: React.FC<IPropsProductPage> = (props: any) => {
	const [loading, setLoading] = useState(true);
	const { fetchResourceById, resource, res_id, storeType } = props;

	console.log("Resource in product page", resource);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		setLoading(true);
		try {
			fetchResourceById(res_id, storeType)
				.then(() => setLoading(false))
				.catch((err: any) => {
					console.log(err);
					setLoading(false);
				});
		} catch (err: any) {
			console.log(err);
			setLoading(false);
		}
	}, [res_id, fetchResourceById, storeType]);

	if (loading) {
		return <Spinner />;
	}

	return (
		resource && (
			<div className={cn(styles.productpage)}>
				{resource && (
					<SEOHelmet
						{...{
							title: `${resource.name} - Discover Unique Designs | AI Printable`,
							description: `Explore the intricate details of ${resource.name} and how it brings creativity to life. Perfect for those who appreciate unique designs and innovation.`,
							keywords: `unique designs, ${resource.name}, AI Printable, generated products`,
							imageUrl: `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${resource.thumbnail}`,
							url: window.location.href,
							contentType: "product",
							subject: resource.name,
							copyright: "AI Printable, © 2024",
							language: "EN",
							robots: "index, follow",
							revised: new Date().toISOString(),
							abstract: `An in-depth look at ${resource.name}, showcasing its features and the creative process behind it.`,
							topic: "Product Details",
							summary: `Detailing the features and uniqueness of ${resource.name}, available at AI Printable.`,
							classification: "E-commerce, Design",
							author: "AI Printable Team",
							designer: "AI Printable Team",
							replyTo: "info@aiprintable.com",
							owner: "AI Printable",
							identifierURL: `${window.location.origin}/products`,
							directory: "submission",
							pagename: `${resource.name} Product Page`,
							category: "Design Products",
							coverage: "Worldwide",
							distribution: "Global",
							rating: "General",
							revisitAfter: "7 days",
							subtitle: `${resource.name}: A Symphony of Design and Creativity`,
							target: "all",
							handheldFriendly: "True",
							mobileOptimized: "320",
							date: new Date().toISOString().split("T")[0],
							searchDate: new Date().toISOString().split("T")[0],
							DCtitle: resource.name,
						}}
					/>
				)}
				<SubNavbar
					disabled="Home / Generate /"
					active="Generated Image"
					pageName="Generated Image"
				/>
				<div className={cn(styles.productdiv)}>
					<GeneratedProductItem
						productPage={true}
						productImage={`${resource.thumbnail}`}
						desc={resource.name}
						id={resource.id}
					/>
					<div className={cn(styles.container)}>
						{/* <CategoryCollection
							mainpage={false}
							label="Products with this design"
						/> */}

						<ProductCardCollection resource={resource} storeType={storeType} />
					</div>
				</div>
			</div>
		)
	);
};

const mapStateToProps = (state: any) => ({
	resource: getResourceById(state.resource.data, state.resourcePage.id),
});

const mapDispatchToProps = {
	fetchResourceById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
