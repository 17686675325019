/* eslint-disable no-case-declarations */
// airedux/reducers/designReducer.js

import { designConstants } from "airedux/constants/design.constants";
import * as R from "ramda";

const initialState = { loading: false, error: false };

export const design = (state = initialState, action: any) => {
	switch (action.type) {
		case designConstants.FETCH_SUCCESS:
			const newValues = R.indexBy(R.prop("_id"), action.payload.data);
			const newState = {
				loading: false,
				error: false,
				data: newValues,
			};
			return R.mergeRight(state, newState);

		case designConstants.FETCH_START:
			return {
				loading: true,
				error: false,
			};

		case designConstants.FETCH_FAILURE:
			return {
				loading: false,
				error: true,
			};

		default:
			return state;
	}
};
