import { fetchWithCred, handleResponse } from "airedux/helpers";
import { serviceConfig } from "configs";
import {
	transformTaxResponse,
	transformTaxRate,
	transformCountryStateCode,
	transformShippingRate,
} from "./transform_data";

import { all_countries } from "./mock/countries.mock";
import { taxable_countries } from "./mock/taxable.mock";

const fetchCountryStateCode = async () => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(all_countries);
			}, 200);
		});
	}

	const requestOptions = {
		method: "GET",
	};
	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.countries}`,
		requestOptions
	).then(handleResponse);
};

const fetchAll_mockupData = async () => {
	const response = await fetchCountryStateCode();
	return response.map(transformCountryStateCode);
};

const fetchTaxRate = async () => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(taxable_countries);
			}, 200);
		});
	}
	const tunnel_printful_url = `${serviceConfig.apiTunnelUrl}`;
	const requestOptions = {
		headers: {
			req_url: "https://api.printful.com/tax/countries",
			method: "get",
		},
	};
	return await fetchWithCred(`${tunnel_printful_url}`, requestOptions).then(
		handleResponse
	);
};

const fetchAll_TaxData = async () => {
	const response = await fetchTaxRate();
	return response.result.map(transformTaxRate);
};

const fetchTaxRateValue = async (payload: any) => {
	const mock = {
		code: 200,
		result: {
			required: true,
			rate: 0.095,
			shipping_taxable: true,
		},
		extra: [],
	};
	if (serviceConfig.useMockApi) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(mock);
			}, 200);
		});
	}

	const tunnel_printful_url = `${serviceConfig.apiTunnelUrl}`;
	const requestOptions = {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			req_url: `https://api.printful.com/tax/rates`,
			method: "post",
			"Content-Type": "application/json",
		},
	};

	return await fetchWithCred(tunnel_printful_url, requestOptions)
		.then(handleResponse)
		.then((response: any) => ({
			status: response.status,
			...transformTaxResponse(response.result),
		}));
};

const fetchShippingRateValue = async (payload: any) => {
	const mock = {
		code: 200,
		result: {
			required: true,
			rate: 0.095,
			shipping_taxable: true,
		},
		extra: [],
	};
	if (serviceConfig.useMockApi) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(mock);
			}, 200);
		});
	}

	const tunnel_printful_url = `${serviceConfig.apiTunnelUrl}`;
	const requestOptions = {
		body: JSON.stringify(payload),
		headers: {
			req_url: `https://api.printful.com/shipping/rates`,
			method: "post",
		},
	};

	return await fetchWithCred(tunnel_printful_url, requestOptions)
		.then(handleResponse)
		.then((response: any) => ({
			status: response.status,
			...transformShippingRate(response.result),
		}));
};

export const shippingService = {
	fetchCountryStateCode,
	fetchAll_mockupData,
	fetchTaxRate,
	fetchAll_TaxData,
	fetchTaxRateValue,
	fetchShippingRateValue,
};
