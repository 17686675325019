import { fetchWithCred, handleResponse } from "airedux/helpers";
import { serviceConfig } from "configs";
import { blog_posts } from "./mock/other.mock";
import { transformBlogs } from "./transform_data";

const fetchAllBlogs = async () => {
	const requestOptions = {
		method: "GET",
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.apiPrefix}/blog`, // Using your route for getting all blogs
		requestOptions
	).then(handleResponse);
};

const fetchAll_data = async () => {
	return await fetchAllBlogs();
	//return transformBlogs(response);
};

const fetchBlogById = async (id: any) => {
	const requestOptions = {
		method: "GET",
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.apiPrefix}/blog/${id}`, // Using your route for getting a blog by id
		requestOptions
	).then(handleResponse);
};
const fetchDataById = async (id: any) => {
	return await fetchBlogById(id);
	//return transformBlogs(response);
};

export const blogService = {
	fetchAll_data,
	fetchDataById,
};
