import React from "react";

import { TextAreaProps } from "./TextArea.props";
import styles from "./TextArea.module.css";
import cn from "classnames";
import { P } from "common/P/P";

export const TextArea = ({
	appearance = "default",
	label,
	className,
	value,
	name,
	placeholder,
	onChange,
	rows = 5,
	cols = 5,
	...props
}: TextAreaProps): JSX.Element => {
	return (
		<>
			{label ? (
				<div className={cn(styles.withlabel)}>
					<label>
						<P size="b1medium">{label}</P>
					</label>

					<textarea
						value={value}
						name={name}
						onChange={onChange}
						{...props}
						className={cn(styles.textarea, className, {
							[styles.default]: appearance === "default",
							[styles.error]: appearance === "error",
							[styles.bordernone]: appearance === "bordernone",
						})}
						placeholder={placeholder}
						cols={cols}
						rows={rows}
					></textarea>
				</div>
			) : (
				<>
					<textarea
						value={value}
						name={name}
						onChange={onChange}
						{...props}
						className={cn(styles.textarea, className, {
							[styles.default]: appearance === "default",
							[styles.error]: appearance === "error",
							[styles.bordernone]: appearance === "bordernone",
						})}
						placeholder={placeholder}
						cols={cols}
						rows={rows}
					></textarea>
				</>
			)}
		</>
	);
};
