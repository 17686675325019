import React from "react";

import { DiscountBadgeProps } from "./DiscountBadge.props";
import styles from "./DiscountBadge.module.css";
import cn from "classnames";
import { P } from "common/P/P";

export const DiscountBadge = ({
	className,
	appearance = "card",
	children,
	...props
}: DiscountBadgeProps): JSX.Element => {
	return (
		<div
			className={cn(styles.discountbadge, className, {
				[styles.card]: appearance === "card",
				[styles.squared]: appearance === "squared",
			})}
			{...props}
		>
			<P size="b2medium" className={cn(styles.disc)}>
				{children}
			</P>
		</div>
	);
};
