/* eslint-disable no-mixed-spaces-and-tabs */
export const transformTaxResponse = (data: any) => ({
	required: data.required,
	rate: data.rate,
	shippingTaxable: data.shipping_taxable,
});

export const transformTaxRate = (data: any) => ({
	code: data.code,
	name: data.name,
	states:
		data.states &&
		data.states.map((state: any) => ({
			code: state.code,
			name: state.name,
			shippingTaxable: state.shipping_taxable,
		})),
});

export const transformCountryStateCode = (data: any) => ({
	name: data.name,
	code: data.code,
	states: data.states
		? data.states.map((state: any) => ({
				code: state.code,
				name: state.name,
		  }))
		: null,
});

export const transformShippingRate = (data: any) => ({
	id: data.id,
	name: data.name,
	rate: data.rate,
	currency: data.currency,
	minDeliveryDays: data.minDeliveryDays,
	maxDeliveryDays: data.maxDeliveryDays,
	minDeliveryDate: data.minDeliveryDate,
	maxDeliveryDate: data.maxDeliveryDate,
});
