import { giftConstants as actionConstants } from "airedux/constants";

const initialState = {
	giftInfo: {
		from: "",
		to: "",
		message: "",
	},
};

const giftReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case actionConstants.SET_GIFT_INFO:
		case actionConstants.EDIT_GIFT_INFO:
			return {
				...state,
				giftInfo: action.payload,
			};
		case actionConstants.REMOVE_GIFT_INFO:
			return {
				...state,
				giftInfo: { ...initialState.giftInfo },
			};
		default:
			return state;
	}
};

export default giftReducer;
