import { fetchWithCred, handleResponse } from "airedux/helpers";
import { serviceConfig } from "configs";
import {
	all_categories,
	all_variants,
	product_sizes,
	single_category,
} from "./mock/catalog.mock";
import {
	transformCategories,
	transformCategory,
	transformProductSizes,
	transformVariants,
} from "./transform_data";

const fetchAllPFCategories = async (query_string = "") => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(all_categories);
			}, 200);
		});
	}
	const tunnel_printful_url = `${serviceConfig.apiTunnelUrl}`;
	const requestOptions = {
		headers: {
			req_url: "https://api.printful.com/categories",
			method: "get",
		},
	};
	return await fetchWithCred(
		`${tunnel_printful_url}${query_string}`,
		requestOptions
	).then(handleResponse);
};

const fetchAllCategories = async () => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve({
					data: [
						{
							catalog_position: null,
							id: 1,
							image_url: null,
							parent_id: null,
							size: null,
							title: "something",
						},
						{
							catalog_position: null,
							id: 2,
							image_url: null,
							parent_id: null,
							size: null,
							title: "clothes",
						},
						{
							catalog_position: null,
							id: 3,
							image_url: null,
							parent_id: null,
							size: null,
							title: "food",
						},
						{
							catalog_position: null,
							id: 4,
							image_url: null,
							parent_id: null,
							size: null,
							title: "mono",
						},
					],
					message: "Categories",
					status: 1,
				});
			}, 200);
		});
	}
	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.category}`
	).then(handleResponse);
};

const fetchCategoryVariants = async (id: any) => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve({
					data: {
						catalog_position: null,
						id: 1,
						image_url: null,
						parent_id: null,
						size: null,
						title: "something",
						variants: [3678794710],
					},
					message: "CategoryVariant",
					status: 1,
				});
			}, 200);
		});
	}
	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.category}${id}/`
	).then(handleResponse);
};

const fetchAll_data = async () => {
	const response = await fetchAllCategories();
	return response.data.map(transformCategory);
};

export const catalogService = {
	fetchAllCategories,
	fetchCategoryVariants,
	fetchAll_data,
};
