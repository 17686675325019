import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import styles from "./ProductDetailsPage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";
import ProductDetails from "components/ProductDetails/ProductDetails";
import ReactGA from "react-ga";
import Spinner from "modules/loaders/Spinner/Spinner";
import { fetchResourceById } from "airedux/actions";
import { getResourceById } from "airedux/selectors";
import { connect } from "react-redux";
import { serviceConfig } from "configs";
import SEOHelmet from "components/SEOHelmet/SEOHelmet";

interface IPropsProductDetailsPage {
	storeType: "client" | "shop";
}

const ProductDetailsPage: React.FC<IPropsProductDetailsPage> = (props: any) => {
	const { storeType, resource, fetchResourceById } = props;
	const [loading, setLoading] = useState(true);
	const { id } = useParams(); // Assuming 'id' is the parameter name in your route
	const search_params = new URLSearchParams(useLocation().search);
	const product_id = search_params.get("product_id");

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	useEffect(() => {
		if (id && product_id) {
			setLoading(true);

			// Fetch resource for other storeTypes
			fetchResourceById(id, storeType, product_id)
				.then(() => setLoading(false))
				.catch((err: any) => {
					console.error(err);
					setLoading(false);
				});
		}
	}, [id, product_id, fetchResourceById, storeType]);

	if (loading) {
		return <Spinner />;
	}
	const filtered_resource = resource?.products?.find(
		(product: any) => product.product_id === product_id
	);
	console.log("----- RESOURCE", resource);

	return (
		resource && (
			<div className={cn(styles.productdetailspage)}>
				{resource && (
					<SEOHelmet
						{...{
							title: `${
								storeType === "shop"
									? resource.product.product_name
									: filtered_resource.product_name
							} | AI Printable`,
							description: resource.description,
							keywords: `AI Printable, ${
								storeType === "shop"
									? resource.product.product_name
									: filtered_resource.product_name
							}`,
							imageUrl:
								storeType === "shop"
									? `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${resource.product.product_image}`
									: `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${filtered_resource.product_image}`,
							url: `${window.location.origin}${window.location.pathname}`,
							contentType: "article",
							subject:
								storeType === "shop"
									? resource.product.product_name
									: filtered_resource.product_name,
							copyright: "AI Printable, © 2024",
							language: "EN",
							robots: "index, follow",
							topic: "Product Details",
							author: "AI Printable Team",
							designer: "AI Printable Team",
							replyTo: "contact@aiprintable.com",
							owner: "AI Printable",
							identifierURL: `${window.location.origin}/products`,
							directory: "submission",
							pagename: `${
								storeType === "shop"
									? resource.product.product_name
									: filtered_resource.product_name
							} Product Page`,
							category: "E-commerce",
							coverage: "Worldwide",
							distribution: "Global",
							rating: "Safe for Kids",
							revisitAfter: "7 days",
							subtitle: `Learn more about ${
								storeType === "shop"
									? resource.product.product_name
									: filtered_resource.product_name
							}`,
							target: "all",
							handheldFriendly: "True",
							mobileOptimized: "320",
							DCtitle:
								storeType === "shop"
									? resource.product.product_name
									: filtered_resource.product_name,
						}}
					/>
				)}
				<SubNavbar
					disabled="Home / Generate / Generate Image /  "
					active={filtered_resource?.product_name || ""}
					pageName="Product Details"
				/>
				<ProductDetails
					resource={storeType === "shop" ? resource.product : resource}
					isShop={storeType === "shop" && true}
				/>
			</div>
		)
	);
};

const mapStateToProps = (state: any) => ({
	resource: getResourceById(state.resource.data, state.resourcePage.id),
});

const mapDispatchToProps = {
	fetchResourceById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsPage);
