import { AddressItemProps } from "./AddressItem.props";
import styles from "./AddressItem.module.css";
import cn from "classnames";
import { P } from "common/P/P";

import { ReactComponent as CheckIcon } from "common/Icons/check.svg";
import { Htag } from "common/Htag/Htag";

export const AddressItem = ({
	name,
	active,
	address,
	phone,
	country,
	city,
	state,
	invoicePage,
	className,
	data,
	onSelect,
	onRemove,
	onEdit,
	onClick,
	...props
}: AddressItemProps): JSX.Element => {
	return (
		<>
			{invoicePage ? (
				<div className={cn(styles.invoiceaddress)}>
					<span className={cn(styles.invoicediv)}>
						<Htag tag="h4">Address</Htag>
					</span>
					<div className={cn(styles.addresscontent)}>
						<P size="b1regular">{name}</P>
						<div className={cn(styles.content)}>
							<P size="b1regular">{address}</P>
							<span className={cn(styles.country)}>
								<P size="b1regular">{state}</P>
								&#8226;
								<P size="b1regular">{city}</P>
								&#8226;
								<P size="b1regular">{country}</P>
							</span>
							<P size="b1regular">{phone}</P>
						</div>
					</div>
				</div>
			) : (
				<div
					className={cn(
						active ? styles.activediv : styles.addressdiv,
						className
					)}
					{...props}
					onClick={() => onSelect(data.id)}
				>
					{active && (
						<span className={cn(styles.checked)}>
							<CheckIcon />
						</span>
					)}
					<P size="b1regular">{name}</P>
					<div className={cn(styles.content)}>
						<P size="b1regular">{address}</P>
						<span className={cn(styles.country)}>
							<P size="b1regular">{state}</P>
							&#8226;
							<P size="b1regular">{city}</P>
							&#8226;
							<P size="b1regular">{country}</P>
						</span>
						<P size="b1regular">{phone}</P>
					</div>
					<div className={cn(styles.buttons)}>
						<P size="b1underline" onClick={() => onEdit(data)}>
							Edit
						</P>
						<P size="b1underline" onClick={() => onRemove(data.id)}>
							Remove
						</P>
					</div>
				</div>
			)}
		</>
	);
};
