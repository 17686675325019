import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { TooltipProps } from "./Tooltip.props";

import styles from "./Tooltip.module.css";
import cn from "classnames";
import { P } from "common/P/P";

export const Tooltip = ({
	children,
	title,
	description,
	className,
}: TooltipProps): JSX.Element => {
	const [isVisible, setIsVisible] = useState(false);
	const tooltipRef = useRef<HTMLDivElement>(null);

	// Function to toggle tooltip visibility
	const toggleVisibility = () => {
		setIsVisible(!isVisible);
	};

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
				setIsVisible(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className={cn(styles.tooltipContainer, className)} ref={tooltipRef}>
			<div onClick={toggleVisibility}>{children}</div>
			<AnimatePresence>
				{isVisible && (
					<motion.div
						initial={{ opacity: 0, scale: 0.95 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.95 }}
						transition={{ duration: 0.2 }}
						className={styles.tooltip}
					>
						<P size="b2medium" className={cn(styles.text)}>
							{title}
						</P>
						<P size="b2regular" className={cn(styles.text)}>
							{description}
						</P>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
