/* eslint-disable no-case-declarations */
import { addressConstants } from "airedux/constants";

export const shipping = (state = {}, { type, payload }: any) => {
	switch (type) {
		case addressConstants.ON_ADD_SHIPPING:
			return payload;
		case addressConstants.ON_REMOVE_SHIPPING:
			return {};

		default:
			return state;
	}
};

export default shipping;
