/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect } from "react";

const useBlockLogs = (showLogs: number): void => {
	useEffect(() => {
		if (showLogs === 0) {
			const originalConsoleLog = console.log;
			const originalConsoleWarn = console.warn;
			const originalConsoleError = console.error;
			const originalConsoleInfo = console.info;
			const originalConsoleDebug = console.debug;

			console.log = () => {};
			console.warn = () => {};
			console.error = () => {};
			console.info = () => {};
			console.debug = () => {};

			return () => {
				console.log = originalConsoleLog;
				console.warn = originalConsoleWarn;
				console.error = originalConsoleError;
				console.info = originalConsoleInfo;
				console.debug = originalConsoleDebug;
			};
		}
	}, [showLogs]);
};

export default useBlockLogs;
