/* eslint-disable no-case-declarations */
import * as R from "ramda";

import { get_local_data_by_key, set_local_data_by_key } from "airedux/helpers";
import { authConstants } from "airedux/constants";
import { authService } from "airedux/services";
import { wishlistConstants } from "airedux/constants/wishlist.constants";

const user = get_local_data_by_key("user");
const initialState: any = !R.isEmpty(user)
	? { loggedIn: true, loading: false, error: false, data: user }
	: { loggedIn: false, loading: false, error: false, data: {} };

export const auth = (state = initialState, action: any) => {
	switch (action.type) {
		case authConstants.LOGIN_REQUEST:
			return {
				loggedIn: false,
				loading: true,
				error: false,
				data: {},
			};
		case authConstants.LOGIN_SUCCESS:
			set_local_data_by_key("user", action.payload);
			return {
				loggedIn: true,
				loading: false,
				error: false,
				data: action.payload,
			};
		case authConstants.LOGIN_FAILURE:
			authService.logout();
			return {
				loggedIn: false,
				loading: false,
				error: true,
				data: action.payload ?? {},
			};
		case authConstants.PROFILE_UPDATE:
			const data = {
				...state.data,
				...action.payload,
			};
			set_local_data_by_key("user", data);
			return {
				loggedIn: true,
				loading: false,
				error: false,
				data: { ...state.data, ...data },
			};
		case authConstants.LOGOUT:
			authService.logout();
			return {
				loggedIn: false,
				loading: false,
				error: false,
				data: {},
			};

		case wishlistConstants.ADD_WISHLIST_SUCCESS: {
			// Ensure the structure exists to prevent runtime errors
			if (state.data && state.data.data && state.data.data[0]) {
				// Clone the current state to avoid direct state mutations
				const newState = JSON.parse(JSON.stringify(state));

				// Initialize wishlists if not present
				if (!newState.data.data[0].wishlists) {
					newState.data.data[0].wishlists = { images: [], products: [] };
				}

				const newWish = action.payload.data; // The new wishlist item
				const wishType = newWish.type; // 'image' or 'product'

				// Append the new wishlist item to the appropriate list based on its type
				if (wishType === "image") {
					newState.data.data[0].wishlists.images.push(newWish);
				} else if (wishType === "product") {
					newState.data.data[0].wishlists.products.push(newWish);
				}

				return newState;
			}
			return state; // Return the current state if the structure isn't as expected
		}
		case wishlistConstants.FETCH_WISHLIST_SUCCESS: {
			// Ensure the user data structure exists to prevent runtime errors
			if (
				state.data &&
				state.data.data &&
				state.data.data[0] &&
				state.data.data[0].wishlists
			) {
				// Deep clone the current state to avoid direct mutations
				const newState = JSON.parse(JSON.stringify(state));

				// Extract images and products from the action payload
				const { images, products } = action.payload.data;

				// Update the wishlists with new images and products data
				newState.data.data[0].wishlists.images = images || [];
				newState.data.data[0].wishlists.products = products || [];

				return newState;
			}
			return state; // Return the unchanged state if the data structure isn't as expected
		}

		case wishlistConstants.DELETE_WISHLIST_SUCCESS: {
			const { id, type } = action.payload;

			// Ensure the structure exists to prevent runtime errors
			if (
				state.data &&
				state.data.data &&
				state.data.data[0] &&
				state.data.data[0].wishlists
			) {
				// Clone the current state to avoid direct state mutations
				const newState = JSON.parse(JSON.stringify(state));

				// Filter out the deleted wishlist item based on its type
				if (type === "image") {
					newState.data.data[0].wishlists.images =
						newState.data.data[0].wishlists.images.filter(
							(item: any) => item._id !== id
						);
				} else if (type === "product") {
					newState.data.data[0].wishlists.products =
						newState.data.data[0].wishlists.products.filter(
							(item: any) => item._id !== id
						);
				}

				// Return the updated state
				return newState;
			}
			return state; // Return the current state if the structure isn't as expected
		}

		default:
			return state;
	}
};
