import { ReactNode, useState } from "react";

import "./Accordion.scss";

interface ItemProps {
	title: string | JSX.Element;
	children: ReactNode;
	accordionOpened?: boolean;
}

export const AccordionItem: React.FC<ItemProps> = ({
	title,
	children,
	accordionOpened = true,
}) => {
	const [opened, setOpened] = useState(accordionOpened);
	return (
		<li className="accordion-list__item">
			<div
				className={`accordion-item, ${opened && "accordion-item--opened"}`}
				onClick={() => {
					setOpened(!opened);
				}}
			>
				<div className="accordion-item__line">
					<span className="accordion-item__title">{title}</span>
					<span className="accordion-item__icon" />
				</div>
				<div className="accordion-item__inner">
					<div className="accordion-item__content">
						<span className="accordion-item__paragraph">{children}</span>
					</div>
				</div>
			</div>
		</li>
	);
};
