export const resourceConstants = {
	FETCH_START: "RESOURCE_FETCH_START",
	FETCH_SUCCESS: "RESOURCE_FETCH_SUCCESS",
	FETCH_FAILURE: "RESOURCE_FETCH_FAILURE",

	FETCH_BY_ID_START: "RESOURCE_FETCH_BY_ID_START",
	FETCH_BY_ID_SUCCESS: "RESOURCE_FETCH_BY_ID_SUCCESS",
	FETCH_BY_ID_FAILURE: "RESOURCE_FETCH_BY_ID_FAILURE",

	ADDED_TO_CART: "RESOURCE_ADDED_TO_CART",
	REMOVED_FROM_CART: "RESOURCE_REMOVED_FROM_CART",
	ALL_REMOVED_FROM_CART: "RESOURCE_ALL_REMOVED_FROM_CART",

	FETCH_SIZE_BY_ID_START: "SIZE_FETCH_BY_ID_START",
	FETCH_SIZE_BY_ID_SUCCESS: "SIZE_FETCH_BY_ID_SUCCESS",
	FETCH_SIZE_BY_ID_FAILURE: "SIZE_FETCH_BY_ID_FAILURE",
};
