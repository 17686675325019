import React, { useEffect, useState } from "react";

import { SearchProps } from "./Search.props";
import styles from "./Search.module.css";
import cn from "classnames";
import { P } from "common/P/P";
import { ReactComponent as SearchIcon } from "common/Icons/search.svg";
import { Input } from "common/Input/Input";
import { Button } from "common/Button/Button";

export const Search = ({
	className,
	appearance,
	handleSubmit,
	name = "search",
	handleChange,
	placeholder = "Search from community",
	value,
	...props
}: SearchProps): JSX.Element => {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [searchValue, setSearchValue] = useState(value || "");

	useEffect(() => {
		const updateScreenWidth = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener("resize", updateScreenWidth);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener("resize", updateScreenWidth);
		};
	}, []);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
		if (handleChange) handleChange(e);
	};

	const handleFormSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		handleSubmit(searchValue);
	};
	return (
		<form
			className={cn(styles.container, className)}
			{...props}
			onSubmit={handleFormSubmit}
		>
			<div
				className={cn(
					screenWidth >= 1024 ? styles.searchdiv : styles.mobilesearchdiv
				)}
			>
				{screenWidth >= 1024 && (
					<div className={cn(styles.icondiv)}>
						<SearchIcon className={cn(styles.icon)} />
					</div>
				)}

				<Input
					className={cn(
						screenWidth >= 1024 ? styles.searchinput : styles.mobilesearchinput
					)}
					appearance="default"
					type="text"
					name={name}
					placeholder={placeholder}
					value={searchValue}
					onChange={handleInputChange}
					withoutBorder={true}
					onKeyDown={(e: any) => {
						if (e.key === "Enter") {
							handleSubmit(e);
						}
					}}
				/>
				{screenWidth >= 1024 ? (
					<Button
						appearance="black"
						type="submit"
						value="submit"
						className={cn(styles.button)}
					>
						Search
					</Button>
				) : (
					<Button
						className={cn(styles.mobileicondiv)}
						appearance="black"
						icon={<SearchIcon className={cn(styles.mobileicon)} />}
					/>
				)}
			</div>
		</form>
	);
};
