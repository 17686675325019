import { fetchWithCred, handleResponse } from "airedux/helpers";
import { serviceConfig } from "configs";
import { authBearerHeaderAsync } from "airedux/helpers";

const fetch_payment_cards = async () => {
	const requestOptions = {
		headers: { ...(await authBearerHeaderAsync()) },
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.payment_cards}`,
		requestOptions
	)
		.then(handleResponse)
		.then((response: any) => response.data);
};

const post_payment_cards = async (payload: any, action = "add") => {
	const requestOptions = {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json",
			...(await authBearerHeaderAsync()),
		},
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.payment_cards}?action=${action}`,
		requestOptions
	)
		.then(handleResponse)
		.then((response: any) => ({
			status: response.status,
			...response,
		}));
};

export const paymentService = {
	fetch_payment_cards,
	post_payment_cards,
};
