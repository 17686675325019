import * as R from "ramda";

import { variantConstants } from "airedux/constants";

const initialState = {
	id: null,
};

export const variantPage = (state = initialState, { type, payload }: any) => {
	switch (type) {
		case variantConstants.FETCH_BY_ID_SUCCESS:
			return R.mergeRight(state, {
				id: R.prop("id", { id: payload.product_id }),
			});
		default:
			return state;
	}
};
