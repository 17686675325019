import { addressConstants as actionConstants } from "airedux/constants";

function saveAddress(address: any) {
	return { type: actionConstants.SAVE_ADDRESS, address };
}

function clearAddress() {
	return { type: actionConstants.CLEAR_ADDRESS };
}

export const shippingAddressActions = {
	saveAddress,
	clearAddress,
};
