/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";

import styles from "./Auth.module.css";
import cn from "classnames";

import { Modal } from "common/Modal/Modal";
import { Button, Checkbox, Input, P } from "common";
import Spinner from "modules/loaders/Spinner/Spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { routeConstants } from "routes";
import { authActions } from "airedux/actions";
import { ReactComponent as GoogleIcon } from "common/Icons/google.svg";
import GoogleLogin from "react-google-login";
import { authService } from "airedux/services";

interface IPropsLogin {
	onSignUpClick?: any;
	handleForgetClick?: any;
	loading?: boolean;
}

const AuthLogin: React.FC<IPropsLogin> = ({ loading }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [inputs, setInputs] = useState({ email: "", password: "" });
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	};
	const dispatch = useDispatch();

	const handleSubmit = (e: any) => {
		e.preventDefault();
		console.log("INPUTS", inputs);
		dispatch(authActions.login(inputs.email, inputs.password));
	};

	const handleCloseModal = () => {
		navigate(location.pathname, { replace: true });
	};

	const responseGoogle = (response: any) => {
		const tokenId = response.tokenId;

		if (tokenId) {
			// Temporarily set loading to true
			// Ideally, manage loading state more robustly for better UX
			const set_loading = (isLoading: boolean) => {
				// Implement loading state management
			};

			// Dispatch googleAuth action with the tokenId
			dispatch(authActions.googleAuth(tokenId, set_loading));
		} else {
			console.error("Error logging in with Google: No token ID found");
		}
	};

	return (
		<>
			<Modal
				title="Log In"
				openModal={true}
				clickOut={false}
				setOpenModal={handleCloseModal}
				modalClass={true}
			>
				<form onSubmit={!loading ? handleSubmit : () => ({})}>
					<div className={cn(styles.logincontent)}>
						<div className={cn(styles.inputs)}>
							<Input
								label="Email address"
								appearance="default"
								type="email"
								name="email"
								placeholder="hello@artifical.com"
								onChange={handleChange}
								required
							/>
							<Input
								label="Password"
								appearance="default"
								type="password"
								name="password"
								placeholder="Your password"
								onChange={handleChange}
								required
							/>
						</div>
						<div className={cn(styles.save)}>
							<span className={cn(styles.remember)}>
								<Checkbox appearance="default" />
								<P size="b1medium">Remember me</P>
							</span>
							<P
								size="b1underline"
								className={cn(styles.passive)}
								onClick={() =>
									navigate(`${routeConstants.root.route}?auth=forgot-password`)
								}
							>
								Forgot password?
							</P>
						</div>
						<div className={cn(styles.signup)}>
							<P size="b1medium" className={cn(styles.passivetwo)}>
								Don’t have an account?
							</P>
							<P
								size="b1medium"
								className={cn(styles.cursor)}
								onClick={() =>
									navigate(`${routeConstants.root.route}?auth=register`)
								}
							>
								Sign up
							</P>
						</div>
						<div className={cn(styles.twobuttons)}>
							{!loading ? (
								<Button
									appearance="black"
									className={cn(styles.buttoncomponent)}
									onClick={handleSubmit}
								>
									Sign In
								</Button>
							) : (
								<Spinner />
							)}
							<GoogleLogin
								clientId="919411038787-n4g3jr4lokeia30m8isp59mlb9o48vj8.apps.googleusercontent.com"
								render={(renderProps) => (
									<Button
										appearance="ghost-white"
										className={cn(styles.buttoncomponentwithicon)}
										icon={<GoogleIcon />}
										onClick={renderProps.onClick}
										disabled={renderProps.disabled}
									>
										Sign in with Google
									</Button>
								)}
								onSuccess={responseGoogle}
								onFailure={responseGoogle}
								cookiePolicy={"single_host_origin"}
							/>
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	loading: state.auth.loading,
});

export default connect(mapStateToProps)(AuthLogin);
