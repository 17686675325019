/* eslint-disable no-mixed-spaces-and-tabs */
import * as R from "ramda";
import { serviceConfig } from "configs";

export const getResourceById = (data: any, id: any) => R.prop(id, data);

export const getResources = (state: any) => {
	const resources = state.resource.data
		? R.map(
				(id: any) => getResourceById(state.resource.data, id),
				state.resourcesPage.ids
		  )
		: [];
	return resources;
};

// export const getVariantById = (data: any, id: any) => R.prop(id, data);

export const getVariantById = (data: any, productId: string) => {
	console.log("Data passed to getVariantById:", data);

	for (const storeKey of Object.keys(data)) {
		const store = data[storeKey];
		console.log(`Processing store: ${storeKey}`, store);

		// First, try finding the product directly in the products array
		if (store && store.products && Array.isArray(store.products)) {
			const productVariant = store.products.find(
				(product: any) => product.product_id === productId
			);

			if (productVariant) {
				return productVariant;
			}
		}

		// If not found, check within the nested 'product' object
		if (store && store.product && store.product.product_id === productId) {
			return store.product;
		}
	}

	return null; // Return null if no matching product variant is found
};

//export const getVariants = (state: any) => {
//	const variants = state.variant.data
//		? R.map(
//				(id: any) => getVariantById(state.variant.data, id),
//				state.variantsPage.ids
//		  )
//		: [];
//	return variants;
//};

export const getFeaturedResources = (state: any) => {
	const resources = state.resource.data
		? R.map(
				(id: any) => getResourceById(state.resource.data, id),
				state.featuredResourceIds.ids
		  )
		: [];
	return resources;
};

export const getDiscountResources = (state: any) => {
	const resources = state.resource.data
		? R.map(
				(id: any) => getResourceById(state.resource.data, id),
				state.discountResourceIds.ids
		  )
		: [];
	return resources;
};

export const getLatestResources = (state: any) => {
	const resources = state.resource.data
		? R.map(
				(id: any) => getResourceById(state.resource.data, id),
				state.latestResourceIds.ids
		  )
		: [];
	return resources;
};

export const getWishlistResources = (state: any) => {
	const resources = state.resource.data
		? R.map(
				(id: any) => getResourceById(state.resource.data, id),
				state.wishlist.resource
		  )
		: [];
	return resources;
};

export const getWishlistVariants = (state: any) => {
	const variants = state.variant.data
		? R.map(
				(id: any) => getVariantById(state.variant.data, id),
				state.wishlist.variant
		  )
		: [];
	return variants;
};

export const getFilterVariants = (state: any, variant_ids: any) => {
	const variants = state.variant.data
		? R.map((id: any) => getVariantById(state.variant.data, id), variant_ids)
		: [];
	return variants;
};

export const isWishlisted = (state: any, id = 0, data_type = "variant") => {
	let wishlisted = false;
	if (data_type === "variant") {
		state.wishlist.variant.map((item: any) => {
			if (item === id) {
				wishlisted = true;
			}
		});
	} else {
		state.wishlist.resource.map((item: any) => {
			if (item === id) {
				wishlisted = true;
			}
		});
	}
	return wishlisted;
};

export const getTotalCount = (state: any, id = 0) => {
	let totalPrice = 0;
	let totalCount = 0;
	state.cart.map((item: any) => {
		if (id > 0) {
			if (item.id === id) {
				//const resource = getResourceById(state.resource.data, item.id);
				totalPrice = 0; //resource.priceValue * item.quantity;
				totalCount = item.quantity;
			}
		} else {
			const resource = getResourceById(state.resource.data, item.id);
			if (resource) {
				totalPrice += 0; // resource.priceValue * item.quantity;
				totalCount = R.length(state.cart);
			}
		}
	});
	return {
		totalCount: totalCount,
		totalPrice: totalPrice.toFixed(2),
	};
};

export const getTotalPrice = (state: any) => {
	const totalPrice = R.compose(
		R.sum,
		R.pluck("retail_price"),
		R.map((id: any) => getResourceById(state.resource.data, id))
	)(state.cart);
	return totalPrice.toFixed(2);
};

export const getCartItems = (state: any) => {
	const items = R.compose(
		R.map((cartItem: any) => cartItem),
		R.map((cartItem: any) => getResourceById(state.resource.data, cartItem.id))
	)(state.cart);

	return items ?? [];
};

///////////////////////

export const getVariantTotalCount = (state: any, id = "") => {
	let totalPrice = 0;
	let totalTaxPrice = 0; // Initialize totalTaxPrice
	let totalCount = 0;

	state.cart.forEach((item: any) => {
		if (id && item.id === id) {
			console.log("IDLAY", id, item.id);
			// If a specific ID is provided and matches the item ID
			totalCount += item.quantity; // Accumulate quantity
			totalPrice += item.price * item.quantity; // Calculate total price
		} else if (!id) {
			// If no specific ID is provided
			const variant = getVariantById(state.variant.data, item.id);
			if (variant) {
				totalCount += item.quantity; // Accumulate quantity
				totalPrice += item.sale_price * item.quantity; // Calculate total price
			}
		}
	});

	if (state.tax && typeof state.tax === "number") {
		// Directly assign the tax value if it's a number
		totalTaxPrice = state.tax;
	}

	return {
		totalCount,
		totalPrice: totalPrice.toFixed(2),
		totalTaxPrice: totalTaxPrice.toFixed(2),
	};
};

export const getVariantTotalPrice = (state: any) => {
	const totalPrice = R.compose(
		R.sum,
		R.pluck("product_price"),
		R.map((id: any) => getVariantById(state.variant.data, id))
	)(state.cart);
	return totalPrice.toFixed(2);
};

export const getVariantCartItems = (state: any) => {
	return state.cart
		.map((cartItem: any) => {
			// First attempt: Directly check if the store's product ID matches the cartItem's ID
			for (const storeKey in state.variant.data) {
				const store = state.variant.data[storeKey];
				if (store.product && store.product.product_id === cartItem.id) {
					return store.product;
				}
			}

			// Fallback attempt: Use getVariantById to search across all products
			const variant = getVariantById(state.variant.data, cartItem.id);
			return variant;
		})
		.filter((item: any) => item != null); // Filter out null values
};

interface CartItem {
	id: any;
	dimension: string;
	price: string;
	shipping_price: string;
	quantity: number;
}

interface State {
	cart: CartItem[];
}

interface ServiceConfig {
	shipping_count_percentage: any;
}

const configs: ServiceConfig = serviceConfig;

export const getTotalShippingPrice = (state: State) =>
	R.compose(
		R.sum,
		R.map((item: CartItem) => {
			const shippingPrice = parseFloat(item.shipping_price);
			if (item.quantity === 1) {
				return shippingPrice;
			} else {
				return (
					shippingPrice +
					(item.quantity - 1) *
						(shippingPrice * configs.shipping_count_percentage)
				);
			}
		}),
		R.prop("cart")
	)(state);

///////////////////////
export const getResourceNextUrl = (state: any) =>
	state.productFilter.next_url ?? "";
