/* eslint-disable no-case-declarations */
import { categoryConstants as actionConstants } from "airedux/constants";
import * as R from "ramda";

const initialState = { loading: false, error: false };

export const category = (
	state = initialState,
	action: { [name: string]: any }
) => {
	switch (action.type) {
		case actionConstants.FETCH_SUCCESS:
			const newValues = R.indexBy(R.prop<string, string>("id"), action.payload);
			const newState = {
				loading: false,
				error: false,
				data: newValues,
			};
			return R.mergeRight(state, newState);

		case actionConstants.FETCH_START:
			return {
				loading: true,
				error: false,
			};

		case actionConstants.FETCH_FAILURE:
			return {
				loading: false,
				error: true,
			};

		default:
			return state;
	}
};
