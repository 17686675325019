import React, { useState } from "react";

import styles from "./Footer.module.css";
import cn from "classnames";
import { Button, Input, P } from "common";
import { otherServices } from "airedux/services";
import { toastMessage } from "airedux/helpers";
import { routeConstants } from "routes";
import ReactGA from "react-ga";

const Footer: React.FC = ({ handleNavigation }: any): JSX.Element => {
	const [inputs, setInputs] = useState({
		subscribe: "",
	});
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		ReactGA.event({
			category: "FooterSubscribeNewsletter",
			action: "FooterSubscribeNewsletter",
			label: "Footer Subscribe Newsletter",
			value: 0,
		});
		try {
			const subscribe_response = await otherServices.post_subscribe({
				email: inputs.subscribe,
			});
			if (subscribe_response.success === true) {
				toastMessage({
					type: "success",
					message: subscribe_response.message,
					position: "top-right",
				});
			} else {
				toastMessage({
					type: "error",
					message: subscribe_response.message,
					position: "top-right",
				});
			}
		} catch (error: any) {
			toastMessage({
				type: "error",
				message: `Error occured!`,
				position: "top-right",
			});
		}
	};

	const instagramLink = "https://www.instagram.com/aiprintable/";
	const youtubeLink =
		"https://www.youtube.com/channel/UCByXMxx8DCaIgmi6qRzYcSg";
	const facebookLink =
		"https://m.facebook.com/Aiprintable-107550558949868/?mibextid=LQQJ4d";

	return (
		<footer className={cn(styles.footer)}>
			<div className={cn(styles.firstrow)}>
				<div className={cn(styles.about)}>
					<P size="b2medium">About</P>
					<P size="b2regular" className={cn(styles.paragraph)}>
						AI Printable is Making Personalized Merchandise More Accessible with
						AI. Our goal is to help you bring your imagination to life.
					</P>
					<div
						onClick={() => handleNavigation(routeConstants.about_us.route)}
						style={{ width: "max-content" }}
					>
						<Button appearance="ghost-white" className={cn(styles.button)}>
							Read More
						</Button>
					</div>
				</div>
				<div className={cn(styles.info)}>
					<div className={cn(styles.services)}>
						<P size="b2medium">Services</P>
						<div onClick={() => handleNavigation(routeConstants.blog.route)}>
							<P size="b2regular" style={{ cursor: "pointer" }}>
								Blogs
							</P>
						</div>
						<div
							onClick={() => handleNavigation(routeConstants.help_centre.route)}
						>
							<P size="b2regular" style={{ cursor: "pointer" }}>
								Help centre
							</P>
						</div>
						<P size="b2regular" style={{ cursor: "pointer" }}>
							FAQ
						</P>
						<div
							onClick={() => handleNavigation(routeConstants.contact_us.route)}
						>
							<P size="b2regular" style={{ cursor: "pointer" }}>
								Contact us
							</P>
						</div>
					</div>
					<div className={cn(styles.services)}>
						<P size="b2medium" style={{ cursor: "pointer" }}>
							Follow us
						</P>
						<a
							href={instagramLink}
							style={{
								textDecoration: "none",
								color: "inherit",
								cursor: "pointer",
							}}
							target="_blank"
						>
							<P size="b2regular" style={{ cursor: "pointer" }}>
								Instagram
							</P>
						</a>
						<a
							href={facebookLink}
							style={{
								textDecoration: "none",
								color: "inherit",
								cursor: "pointer",
							}}
							target="_blank"
						>
							<P size="b2regular" style={{ cursor: "pointer" }}>
								Facebook
							</P>
						</a>
						<a
							href={youtubeLink}
							style={{
								textDecoration: "none",
								color: "inherit",
								cursor: "pointer",
							}}
							target="_blank"
						>
							<P size="b2regular" style={{ cursor: "pointer" }}>
								Youtube
							</P>
						</a>
						<div
							onClick={() => handleNavigation(routeConstants.affiliate.route)}
						>
							<P size="b2regular" style={{ cursor: "pointer" }}>
								Affiliate
							</P>
						</div>
					</div>
				</div>
			</div>
			<div className={cn(styles.secondrow)}>
				<form className={cn(styles.subscribediv)} onSubmit={handleSubmit}>
					<Input
						className={cn(styles.input)}
						appearance="default"
						type="email"
						name="subscribe"
						placeholder="Write your mail to join our newsletter"
						onChange={handleChange}
					/>
					<Button appearance="black" className={cn(styles.subscribe)}>
						Subscribe
					</Button>
				</form>
				<div className={cn(styles.privacy)}>
					<div
						onClick={() =>
							handleNavigation(routeConstants.terms_conditions.route)
						}
					>
						<P size="b2regular" style={{ cursor: "pointer" }}>
							Terms & Conditions
						</P>
					</div>
					<div
						onClick={() =>
							handleNavigation(routeConstants.privacy_statement.route)
						}
					>
						<P size="b2regular" style={{ cursor: "pointer" }}>
							Privacy Statements
						</P>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
