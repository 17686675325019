import React from "react";
import { Helmet } from "react-helmet";
import { IPropsSEOHelmet } from "./SEOHelmet.props";

const SEOHelmet: React.FC<IPropsSEOHelmet> = ({
	title,
	description,
	keywords,
	imageUrl,
	url,
	contentType = "website",
	subject,
	copyright,
	language,
	robots,
	revised,
	abstract,
	topic,
	summary,
	classification,
	author,
	designer,
	replyTo,
	owner,
	identifierURL,
	directory,
	pagename,
	category,
	coverage,
	distribution,
	rating,
	revisitAfter,
	subtitle,
	target,
	handheldFriendly,
	mobileOptimized,
	date,
	searchDate,
	DCtitle,
}) => (
	<Helmet>
		{/* Standard HTML Meta Tags */}
		<meta charSet="UTF-8" />
		<title>{title}</title>
		<meta name="description" content={description} />
		<meta name="keywords" content={keywords} />
		<meta name="subject" content={subject} />
		<meta name="copyright" content={copyright} />
		<meta name="language" content={language} />
		<meta name="robots" content={robots} />
		<meta name="revised" content={revised} />
		<meta name="abstract" content={abstract} />
		<meta name="topic" content={topic} />
		<meta name="summary" content={summary} />
		<meta name="Classification" content={classification} />
		<meta name="author" content={author} />
		<meta name="designer" content={designer} />
		<meta name="reply-to" content={replyTo} />
		<meta name="owner" content={owner} />
		<meta name="url" content={url} />
		<meta name="identifier-URL" content={identifierURL} />
		<meta name="directory" content={directory} />
		<meta name="pagename" content={pagename} />
		<meta name="category" content={category} />
		<meta name="coverage" content={coverage} />
		<meta name="distribution" content={distribution} />
		<meta name="rating" content={rating} />
		<meta name="revisit-after" content={revisitAfter} />
		<meta name="subtitle" content={subtitle} />
		<meta name="target" content={target} />
		<meta name="HandheldFriendly" content={handheldFriendly} />
		<meta name="MobileOptimized" content={mobileOptimized} />
		<meta name="date" content={date} />
		<meta name="search_date" content={searchDate} />
		<meta name="DC.title" content={DCtitle} />

		{/* Open Graph / Facebook Meta Tags */}
		<meta property="og:url" content={url} />
		<meta property="og:type" content={contentType} />
		<meta property="og:title" content={title} />
		<meta property="og:description" content={description} />
		<meta property="og:image" content={imageUrl} />

		{/* Twitter Card Meta Tags */}
		<meta name="twitter:card" content="summary_large_image" />
		<meta name="twitter:url" content={url} />
		<meta name="twitter:title" content={title} />
		<meta name="twitter:description" content={description} />
		<meta name="twitter:image" content={imageUrl} />
	</Helmet>
);

export default SEOHelmet;
