import React from "react";

import { CounterButtonProps } from "./CounterButton.props";
import styles from "./CounterButton.module.css";
import cn from "classnames";
import { Htag } from "common/Htag/Htag";

import { ReactComponent as MinusIcon } from "common/Icons/minus.svg";
import { ReactComponent as PlusIcon } from "common/Icons/plus.svg";
import { Button } from "common/Button/Button";

export const CounterButton = ({
	className,
	counter,
	onDecrease,
	onIncrease,
	...props
}: CounterButtonProps): JSX.Element => {
	return (
		<div className={cn(styles.counterbutton, className)} {...props}>
			<Button
				onClick={onDecrease}
				appearance="ghost-white"
				icon={<MinusIcon />}
			></Button>
			<Htag tag="s2-medium" className={cn(styles.counter)}>
				{counter}
			</Htag>
			<Button
				onClick={onIncrease}
				appearance="ghost-white"
				icon={<PlusIcon />}
			></Button>
		</div>
	);
};
