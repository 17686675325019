export const taxable_countries = {
	code: 200,
	result: [
		{
			code: "US",
			name: "United States",
			states: [
				{
					code: "CA",
					name: "California",
					shipping_taxable: false,
				},
				{
					code: "NC",
					name: "North Carolina",
					shipping_taxable: true,
				},
				{
					code: "SD",
					name: "South Dakota",
					shipping_taxable: true,
				},
				{
					code: "NJ",
					name: "New Jersey",
					shipping_taxable: true,
				},
				{
					code: "ND",
					name: "North Dakota",
					shipping_taxable: true,
				},
				{
					code: "OH",
					name: "Ohio",
					shipping_taxable: true,
				},
				{
					code: "OK",
					name: "Oklahoma",
					shipping_taxable: false,
				},
				{
					code: "PA",
					name: "Pennsylvania",
					shipping_taxable: true,
				},
				{
					code: "SC",
					name: "South Carolina",
					shipping_taxable: true,
				},
				{
					code: "TN",
					name: "Tennessee",
					shipping_taxable: true,
				},
				{
					code: "NE",
					name: "Nebraska",
					shipping_taxable: true,
				},
				{
					code: "UT",
					name: "Utah",
					shipping_taxable: false,
				},
				{
					code: "VT",
					name: "Vermont",
					shipping_taxable: true,
				},
				{
					code: "WA",
					name: "Washington",
					shipping_taxable: true,
				},
				{
					code: "WV",
					name: "West Virginia",
					shipping_taxable: true,
				},
				{
					code: "WI",
					name: "Wisconsin",
					shipping_taxable: true,
				},
				{
					code: "WY",
					name: "Wyoming",
					shipping_taxable: false,
				},
				{
					code: "NV",
					name: "Nevada",
					shipping_taxable: false,
				},
				{
					code: "RI",
					name: "Rhode Island",
					shipping_taxable: true,
				},
				{
					code: "MS",
					name: "Mississippi",
					shipping_taxable: true,
				},
				{
					code: "IN",
					name: "Indiana",
					shipping_taxable: true,
				},
				{
					code: "AL",
					name: "Alabama",
					shipping_taxable: false,
				},
				{
					code: "MN",
					name: "Minnesota",
					shipping_taxable: true,
				},
				{
					code: "CO",
					name: "Colorado",
					shipping_taxable: false,
				},
				{
					code: "CT",
					name: "Connecticut",
					shipping_taxable: true,
				},
				{
					code: "GA",
					name: "Georgia",
					shipping_taxable: true,
				},
				{
					code: "HI",
					name: "Hawaii",
					shipping_taxable: true,
				},
				{
					code: "IL",
					name: "Illinois",
					shipping_taxable: true,
				},
				{
					code: "AR",
					name: "Arkansas",
					shipping_taxable: true,
				},
				{
					code: "IA",
					name: "Iowa",
					shipping_taxable: false,
				},
				{
					code: "KY",
					name: "Kentucky",
					shipping_taxable: true,
				},
				{
					code: "LA",
					name: "Louisiana",
					shipping_taxable: false,
				},
				{
					code: "MD",
					name: "Maryland",
					shipping_taxable: false,
				},
				{
					code: "MA",
					name: "Massachusetts",
					shipping_taxable: false,
				},
				{
					code: "KS",
					name: "Kansas",
					shipping_taxable: true,
				},
				{
					code: "MI",
					name: "Michigan",
					shipping_taxable: true,
				},
				{
					code: "AZ",
					name: "Arizona",
					shipping_taxable: false,
				},
				{
					code: "TX",
					name: "Texas",
					shipping_taxable: true,
				},
				{
					code: "DC",
					name: "District of Columbia",
					shipping_taxable: false,
				},
				{
					code: "FL",
					name: "Florida",
					shipping_taxable: false,
				},
				{
					code: "ID",
					name: "Idaho",
					shipping_taxable: false,
				},
				{
					code: "ME",
					name: "Maine",
					shipping_taxable: false,
				},
				{
					code: "NM",
					name: "New Mexico",
					shipping_taxable: true,
				},
				{
					code: "VA",
					name: "Virginia",
					shipping_taxable: false,
				},
				{
					code: "NY",
					name: "New York",
					shipping_taxable: true,
				},
			],
		},
	],
	extra: [],
};
