import { paymentCardConstants as actionConstants } from "airedux/constants";

export const selectPaymentCard = (data: any) => {
	return {
		type: actionConstants.ON_SELECT,
		payload: data,
	};
};

export const deselectPaymentCard = () => {
	return {
		type: actionConstants.ON_DESELECT,
		payload: {},
	};
};
