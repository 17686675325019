/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import styles from "./Auth.module.css";
import cn from "classnames";

import { Modal } from "common/Modal/Modal";
import { Button, Checkbox, Input, P } from "common";
import { Link, useNavigate } from "react-router-dom";
import { routeConstants } from "routes";
import { authService } from "airedux/services";
import { toastMessage } from "airedux/helpers";

import { ReactComponent as GoogleIcon } from "common/Icons/google.svg";
import { authActions } from "airedux/actions";
import GoogleLogin from "react-google-login";

interface IPropsRegister {
	onStageChange?: any;
	validationData?: any;
	handleValidationData: any;
}

const AuthRegister: React.FC<IPropsRegister> = ({
	onStageChange,
	validationData,
	handleValidationData,
}) => {
	const [inputs, setInputs] = useState({
		name: "",
		email: "",
		password: "",
		agreement: false,
		newsletter: false,
	});
	const [loading, set_loading] = useState(false);

	const handleChange = (e: any) => {
		const { name, type, checked, value } = e.target;
		setInputs((prev) => ({
			...prev,
			[name]: type === "checkbox" ? checked : value,
		}));
	};

	const toggleAgreement = () => {
		setInputs((prev) => ({
			...prev,
			agreement: !prev.agreement,
		}));
	};

	const toggleNewsletter = () => {
		setInputs((prev) => ({
			...prev,
			newsletter: !prev.newsletter,
		}));
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		// Check if all inputs are filled and valid before proceeding
		if (inputs.name.length < 1) {
			toastMessage({
				type: "warning",
				message: "Please enter your name",
				position: "center-top",
			});
			return; // Exit the function early
		}
		if (inputs.email.length < 1) {
			toastMessage({
				type: "warning",
				message: "Please enter your email",
				position: "center-top",
			});
			return; // Exit the function early
		}
		if (inputs.password.length < 8) {
			toastMessage({
				type: "warning",
				message: "Password length should be greater than 8 symbols",
				position: "center-top",
			});
			return; // Exit the function early
		}
		if (!inputs.agreement) {
			toastMessage({
				type: "warning",
				message: "You must accept the agreement",
				position: "center-top",
			});
			return; // Exit the function early
		}

		// If all validations pass, proceed to set loading and make the backend request
		set_loading(true);
		authService
			.requestAuth(inputs.email)
			.then(
				(response: any) => {
					if (response.success === true) {
						handleSuccess(response);
					} else {
						toastMessage({
							type: "error",
							message: response.message,
							position: "center-top",
						});
					}
				},
				(error: any) => {
					toastMessage({
						type: "error",
						message: error,
						position: "center-top",
					});
				}
			)
			.finally(() => set_loading(false));
	};

	const handleSuccess = (response: any) => {
		toastMessage({
			type: "success",
			message: response.message,
			position: "center-top",
		});
		const newValidationData = {
			...validationData,
			credentials: inputs,
			responseMessage: response.message,
		};
		handleValidationData(newValidationData);
		onStageChange(2);
	};

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const responseGoogle = (response: any) => {
		const tokenId = response.tokenId;

		if (tokenId) {
			// Temporarily set loading to true
			// Ideally, manage loading state more robustly for better UX
			const set_loading = (isLoading: boolean) => {
				// Implement loading state management
			};

			// Dispatch googleAuth action with the tokenId
			dispatch(authActions.googleAuth(tokenId, set_loading));
		} else {
			console.error("Error logging in with Google: No token ID found");
		}
	};
	return (
		<>
			<Modal
				title="Sign up"
				openModal={true}
				clickOut={false}
				setOpenModal={(e: any) =>
					e === false && navigate(`${routeConstants.root.route}`)
				}
				modalClass={true}
			>
				<form onSubmit={!loading ? handleSubmit : () => ({})}>
					<div className={cn(styles.logincontent)}>
						<P size="b1regular">Create account and start creating products</P>
						<div className={cn(styles.inputs)}>
							<Input
								label="Full name"
								appearance="default"
								type="text"
								name="name"
								placeholder="Full name"
								onChange={handleChange}
								maxLength="100" // Increased maxLength to accommodate full names
								pattern="^([a-zA-Z]+(\s[a-zA-Z]+)?)$"
								onInput={(event: any) => {
									event.target.value = event.target.value.replace(
										/[^a-zA-Z\s]/g,
										""
									);
								}}
								required
							/>

							<Input
								label="Email address"
								appearance="default"
								type="email"
								name="email"
								placeholder="hello@artifical.com"
								onChange={handleChange}
								required
							/>
							<Input
								label="Create password"
								appearance="default"
								type="password"
								name="password"
								placeholder="must be 8 character"
								onChange={handleChange}
								required
							/>
						</div>
						<div className={cn(styles.agree)}>
							<Checkbox
								appearance="default"
								name="agreement"
								checked={inputs.agreement}
								onChange={handleChange}
							/>
							<P
								size="b1medium"
								onClick={toggleAgreement}
								style={{ cursor: "pointer" }}
							>
								I agree to
							</P>

							<Link to={routeConstants.terms_conditions.route}>
								<P size="b1underline" style={{ cursor: "pointer" }}>
									Terms & Conditions
								</P>
							</Link>
						</div>
						<div className={cn(styles.remember)}>
							<Checkbox
								appearance="default"
								name="newsletter"
								checked={inputs.newsletter}
								onChange={handleChange}
							/>
							<P
								size="b1medium"
								onClick={toggleNewsletter}
								style={{ cursor: "pointer" }}
							>
								Subscribe to newsletters
							</P>
						</div>

						<div className={cn(styles.signup)}>
							<P size="b1medium" className={cn(styles.passivetwo)}>
								Already have an account?
							</P>
							<P
								size="b1medium"
								className={cn(styles.cursor)}
								onClick={() =>
									navigate(`${routeConstants.root.route}?auth=login`)
								}
							>
								Log in
							</P>
						</div>
						<div className={cn(styles.twobuttons)}>
							<Button
								appearance="black"
								className={cn(styles.buttoncomponent)}
								onClick={handleSubmit}
							>
								Sign Up
							</Button>
							<GoogleLogin
								clientId="919411038787-n4g3jr4lokeia30m8isp59mlb9o48vj8.apps.googleusercontent.com"
								render={(renderProps) => (
									<Button
										appearance="ghost-white"
										className={cn(styles.buttoncomponentwithicon)}
										icon={<GoogleIcon />}
										onClick={renderProps.onClick}
										disabled={renderProps.disabled}
									>
										Sign in with Google
									</Button>
								)}
								onSuccess={responseGoogle}
								onFailure={responseGoogle}
								cookiePolicy={"single_host_origin"}
							/>
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	loggedIn: state.auth.loggedIn,
	loading: state.auth.loading,
});

export default connect(mapStateToProps)(AuthRegister);
