import React from "react";

import { SelectProps } from "./Select.props";
import styles from "./Select.module.css";
import cn from "classnames";
import { P } from "common/P/P";

export const Select = ({
	children,
	className,
	defaultSelect,
	label,
	name,
	...props
}: SelectProps): JSX.Element => {
	return (
		<div className={cn(styles.selectdiv)}>
			{label && (
				<label htmlFor="">
					<P size="b1medium">{label}</P>
				</label>
			)}
			<select
				name={name}
				id=""
				{...props}
				className={cn(styles.select, className)}
			>
				{defaultSelect !== undefined && (
					<>
						<option value="" className={cn(styles.defaultselectcolor)}>
							{defaultSelect}
						</option>
						<hr />
					</>
				)}
				{children}
			</select>
		</div>
	);
};
