import React from "react";
import { HtagProps } from "./Htag.props";
import styles from "./Htag.module.css";
import cn from "classnames";

export const Htag = ({
	tag,
	children,
	className,
	style,
	...props
}: HtagProps): JSX.Element => {
	switch (tag) {
		case "h1":
			return (
				<h1 className={cn(styles.h1, className)} {...props} style={style}>
					{children}
				</h1>
			);
		case "h2":
			return (
				<h2 className={cn(styles.h2, className)} {...props} style={style}>
					{children}
				</h2>
			);
		case "h3":
			return (
				<h3 className={cn(styles.h3, className)} {...props} style={style}>
					{children}
				</h3>
			);
		case "h4":
			return (
				<h4 className={cn(styles.h4, className)} {...props} style={style}>
					{children}
				</h4>
			);
		case "s1":
			return (
				<h5 className={cn(styles.s1, className)} {...props} style={style}>
					{children}
				</h5>
			);
		case "s2-bold":
			return (
				<h6 className={cn(styles.s2bold, className)} {...props} style={style}>
					{children}
				</h6>
			);
		case "s2-medium":
			return (
				<h6 className={cn(styles.s2medium, className)} {...props} style={style}>
					{children}
				</h6>
			);
		default:
			return <></>;
	}
};
