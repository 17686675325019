/* eslint-disable no-case-declarations */
import { paymentCardConstants as actionConstants } from "airedux/constants";

export const paymentCard = (state = {}, { type, payload }: any) => {
	switch (type) {
		case actionConstants.ON_SELECT:
			return payload;
		case actionConstants.ON_DESELECT:
			return payload;

		default:
			return state;
	}
};

export default paymentCard;
