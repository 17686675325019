import { addressConstants as actionConstants } from "airedux/constants";
const initialState = {
	currentAddress: null,
};

export const shippingAddress = (state = initialState, action: any) => {
	switch (action.type) {
		case actionConstants.SAVE_ADDRESS:
			return {
				...state,
				currentAddress: action.address,
			};
		case actionConstants.CLEAR_ADDRESS:
			return {
				...initialState,
			};
		default:
			return state;
	}
};
