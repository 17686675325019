import React from "react";

import { PProps } from "./P.props";
import styles from "./P.module.css";
import cn from "classnames";

export const P = ({
	children,
	size = "b1regular",
	className,
	...props
}: PProps): JSX.Element => {
	return (
		<p
			className={cn(styles.p, className, {
				[styles.b1regular]: size === "b1regular",
				[styles.b1medium]: size === "b1medium",
				[styles.b1bold]: size === "b1bold",
				[styles.b1underline]: size === "b1underline",
				[styles.b1line]: size === "b1line",
				[styles.b2regular]: size === "b2regular",
				[styles.b2medium]: size === "b2medium",
				[styles.p1]: size === "p1",
				[styles.t1]: size === "t1",
			})}
			{...props}
		>
			{children}
		</p>
	);
};
