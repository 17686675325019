import React, { useEffect, useState } from "react";

import styles from "./CategoryCollection.module.css";
import cn from "classnames";
import { Htag } from "common/Htag/Htag";
import { CategoryItem, P } from "common";
import { CategoryCollectionProps } from "./CategoryCollection.props";
import { connect } from "react-redux";
import { getCategories } from "airedux/selectors";
import { Link, useParams } from "react-router-dom";
import { routeConstants } from "routes";

const CategoryCollection: React.FC<CategoryCollectionProps> = ({
	mainpage,
	label,
	categories,
	listClassName,
}): JSX.Element => {
	const [limit, setLimit] = useState(12);
	const [current_category, set_current_category] = useState(0);
	const params = useParams();
	useEffect(() => {
		if (params && params.id) {
			set_current_category(parseInt(params.id));
		}
	}, [params]);

	return (
		<>
			{mainpage ? (
				<div className={cn(styles.container)}>
					<Htag tag="h2">Community showcase</Htag>
					<P size="p1" className={cn(styles.paragraph)}>
						We’ll custom make it for a look that matches your <br />
						exact style. The possibilities are endless!
					</P>
					<div className={cn(styles.categorylistdiv)}>
						{categories
							.slice(0, limit ? limit : categories.length)
							.map((category: any, idx: number) => (
								<Link
									key={idx}
									to={`${routeConstants.category_filter.route}/${category.id}`}
								>
									<CategoryItem
										withIcon={true}
										icon={category.image}
										iconalt={category.title}
										label={category.title}
										key={idx}
									/>
								</Link>
							))}
					</div>
				</div>
			) : (
				<>
					<Htag tag="s1">{label}</Htag>
					<div
						className={
							listClassName
								? cn(styles.categorylistclass)
								: cn(styles.categorycollectionlist)
						}
					>
						<Link to={`${routeConstants.category_filter.route}/0`}>
							<CategoryItem
								labelcatlist="#All"
								withIcon={false}
								active={current_category === 0 ? true : false}
								onClick={() => set_current_category(0)}
							/>
						</Link>
						{categories
							.slice(0, limit ? limit : categories.length)
							.map((category: any, idx: number) => (
								<Link
									key={idx}
									to={`${routeConstants.category_filter.route}/${category.id}`}
								>
									<CategoryItem
										labelcatlist={category.title}
										withIcon={false}
										active={category.id === current_category ? true : false}
									/>
								</Link>
							))}
					</div>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => ({
	categories: getCategories(state),
	categories_loading: state.resource.loading,
	categories_error: state.resource.error,
});

export default connect(mapStateToProps)(CategoryCollection);
