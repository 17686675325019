/* eslint-disable no-mixed-spaces-and-tabs */
import { generateImageConstants as actionConstants } from "airedux/constants";
import { imageService } from "airedux/services";

export const generateImageAction =
	(
		prompt: string,
		negativePrompt: string | "",
		model: any,
		style: any,
		userId: string | null,
		image: any
	) =>
	async (dispatch: any) => {
		dispatch({
			type: actionConstants.GENERATE_IMAGE_REQUEST,
		});

		try {
			let data;
			image
				? (data = await imageService.remakeImage(
						prompt,
						negativePrompt,
						style,
						model,
						userId,
						image
				  ))
				: (data = await imageService.generateImage(
						prompt,
						negativePrompt,
						model,
						style,
						userId
				  )); // Pass userId to the service function
			dispatch({
				type: actionConstants.GENERATE_IMAGE_SUCCESS,
				payload: data,
			});
		} catch (err: any) {
			dispatch({
				type: actionConstants.GENERATE_IMAGE_FAILURE,
				payload: err,
				error: true,
			});
		}
	};
