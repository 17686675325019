/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

interface PromptContextType {
	prompt: string;
	setPrompt: (value: string) => void;
}

export const PromptContext = React.createContext<PromptContextType>({
	prompt: "",
	setPrompt: () => {},
});

export const usePrompt = (): PromptContextType => {
	return React.useContext(PromptContext);
};
