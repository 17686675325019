import React, { useEffect, useState } from "react";

import styles from "./ShopPage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";
import ReactGA from "react-ga";
import ProductCardCollection from "components/ProductCardCollection/ProductCardCollection";
import { serviceConfig } from "configs";
import ProductCardItem from "common/ProductCardItem/ProductCardItem";
import { Link } from "react-router-dom";
import { routeConstants } from "routes";
import SEOHelmet from "components/SEOHelmet/SEOHelmet";
import Spinner from "modules/loaders/Spinner/Spinner";
import { Modal } from "common/Modal/Modal";
import { CategoryItem, Htag, P } from "common";
import { Search } from "common/Search/Search";

import { ReactComponent as CheckIcon } from "common/Icons/check.svg";
import { ReactComponent as CategoryIcon } from "common/Icons/Sliders.svg";

const ShopPage: React.FC = (): JSX.Element => {
	const [shopStores, setShopStores] = useState<any>([]);
	const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
	const [filteredShops, setFilteredShops] = useState(shopStores);
	const [isFiltering, setIsFiltering] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
	const [isSearchActive, setIsSearchActive] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	useEffect(() => {
		const fetchShopStores = async () => {
			try {
				const response = await fetch(
					`${serviceConfig.apiHost}${serviceConfig.apiPrefix}/all-shop-stores`
				); // Replace '/api/shop-stores' with your actual API endpoint
				const result = await response.json();
				if (result.success) {
					// const replicatedData = Array(5).fill(result.data).flat();
					// setShopStores(replicatedData);
					setShopStores(result.data);
				} else {
					console.error("Failed to fetch shop stores:", result.message);
				}
			} catch (error) {
				console.error("Error fetching shop stores:", error);
			}
		};

		fetchShopStores();
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const updateScreenWidth = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener("resize", updateScreenWidth);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener("resize", updateScreenWidth);
		};
	}, []);

	const determineImageSrc = (variant: any) => {
		if (variant.product_colors && variant.product_colors.length > 0) {
			return `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${variant.product_colors[0].image}`;
		} else {
			return `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${variant.product_image}`;
		}
	};

	useEffect(() => {
		let filtered = shopStores;
		if (selectedCategories.length > 0) {
			filtered = filtered.filter((shop: any) =>
				shop.categories.some((category: any) =>
					selectedCategories.includes(category.name)
				)
			);
		}

		setFilteredShops(filtered);
	}, [shopStores, selectedCategories]);

	useEffect(() => {
		if (selectedCategories.length > 0) {
			setIsFiltering(true);
			const timer = setTimeout(() => {
				const filtered = shopStores.filter((shop: any) =>
					shop.categories.some((category: any) =>
						selectedCategories.includes(category.name)
					)
				);
				setFilteredShops(filtered);
				setIsFiltering(false);
			}, 1000);

			return () => clearTimeout(timer);
		} else {
			setFilteredShops(shopStores);
		}
	}, [selectedCategories, shopStores]);

	const handleSearch = async (searchValue: string) => {
		setIsFiltering(true);
		setIsSearchActive(true); // Activate search
		if (searchValue.length === 0) {
			// setFilteredDesigns(Object.values(designs));
			setIsSearchActive(false); // Deactivate search if input is empty
			setIsFiltering(false);
			return; // Exit early
		}
		try {
			const response = await fetch(
				`${serviceConfig.apiHost}${serviceConfig.apiPrefix}/search-shops?q=${searchValue}`
			);
			const data = await response.json();
			setFilteredShops(data.data);
		} catch (error) {
			console.error("Error fetching search results:", error);
		}
		setIsFiltering(false);
	};

	const handleCategorySelection = (category: any) => {
		const newSelectedCategories = selectedCategories.includes(category)
			? selectedCategories.filter((cat) => cat !== category)
			: [...selectedCategories, category];

		setSelectedCategories(newSelectedCategories);
	};

	const uniqueCategories = Array.from(
		new Set(
			shopStores.flatMap((d: any) => d.categories.map((cat: any) => cat.name))
		)
	);

	const seoProps = {
		title: "Explore Our Shop - Find Your Perfect Product | AI Printable",
		description:
			"Browse through a wide selection of AI-generated designs and products on AI Printable's Shop. Discover unique items perfect for gifts or personal use, all powered by the latest in AI technology.",
		keywords:
			"AI Printable shop, AI-generated designs, unique gifts, personal use products, AI technology products, online shopping",
		url: "https://www.aiprintable.com/shop",
		contentType: "website",
		subject: "Online Shop for AI-Generated Products",
		language: "EN",
		robots: "index, follow",
		revised: "Sunday, March 8th, 2024, 5:15 pm",
		abstract:
			"AI Printable offers a diverse range of products featuring unique, AI-generated designs for all occasions.",
		topic: "AI-Generated Design Products",
		summary:
			"From custom t-shirts to personalized canvases, find the perfect AI-generated item for you or as a gift in our online shop.",
		classification: "E-commerce, AI Design Products",
		author: "AI Printable, info@aiprintable.com",
		designer: "AI Printable Design Team",
		replyTo: "info@aiprintable.com",
		owner: "AI Printable",
		identifierURL: "https://www.aiprintable.com/shop",
		directory: "submission",
		pagename: "AI Printable Shop",
		category: "E-commerce, Design",
		coverage: "Worldwide",
		distribution: "Global",
		rating: "General",
		revisitAfter: "7 days",
		subtitle: "Shop AI-Generated Designs",
		target: "all",
		handheldFriendly: "True",
		mobileOptimized: "320",
		date: new Date().toISOString().split("T")[0],
		searchDate: new Date().toISOString().split("T")[0],
		DCtitle: "Shop at AI Printable",
	};

	return (
		<>
			<SEOHelmet {...seoProps} />
			{/* <SubNavbar disabled="Home / " active="Shop" pageName="Shops" /> */}
			<div className={cn(styles.container)}>
				{isLoading ? (
					<Spinner />
				) : (
					<div>
						<div className={cn(styles.categoryitemscontainer)}>
							<div className={cn(styles.titlecontainer)}>
								<Htag tag="h2" style={{ textAlign: "center" }}>
									Discover and Remix
								</Htag>
								<P
									size="b1regular"
									style={{ color: "#6D6D6D", textAlign: "center" }}
								>
									Select creation to remix
								</P>
							</div>

							{screenWidth > 1024 ? (
								<Search
									appearance="ellipse"
									name="search"
									placeholder="Search from community"
									handleSubmit={handleSearch}
								/>
							) : (
								<div className={cn(styles.mobilefilterdiv)}>
									<Search
										appearance="ellipse"
										name="search"
										placeholder="Search from community"
										handleSubmit={handleSearch}
									/>
									<div className={cn(styles.mobilefiltericon)}>
										<CategoryIcon
											onClick={() => setIsCategoryModalOpen(true)}
										/>
									</div>
								</div>
							)}

							{screenWidth > 1024 && (
								<div className={cn(styles.categoryitemdiv)}>
									{uniqueCategories.map((item: any, idx: number) => (
										<CategoryItem
											key={idx}
											withIcon={false}
											labelcatlist={item}
											onClick={() => handleCategorySelection(item)}
											active={selectedCategories.includes(item)}
										/>
									))}
								</div>
							)}
						</div>

						{isCategoryModalOpen && (
							<Modal
								title="Filter by category"
								openModal={isCategoryModalOpen}
								setOpenModal={setIsCategoryModalOpen}
							>
								{uniqueCategories.map((item: any) => (
									<div
										key={item}
										onClick={() => handleCategorySelection(item)}
										className={cn(styles.categorymodalitemdiv)}
									>
										<P size="b1medium">{item}</P>
										{selectedCategories.includes(item) && (
											<CheckIcon className={cn(styles.mobilecategoryicon)} />
										)}
									</div>
								))}
							</Modal>
						)}
					</div>
				)}

				<div className={cn(styles.designimagesandfilterdiv)}>
					{!isFiltering &&
						(selectedCategories.length > 0 || isSearchActive) && (
							<Htag tag="h4" style={{ textAlign: "start" }}>
								Filtered data
							</Htag>
						)}
				</div>
				<div className={cn(styles.productcardcollection)}>
					{filteredShops.map((store: any, idx: number) => {
						const sortedDimensions = store.product.product_dimensions.sort(
							(a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
						);
						const sortedSizes = store.product.product_sizes.sort(
							(a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
						);
						let lowestPrice = store.product.product_price;
						if (sortedDimensions.length > 0) {
							lowestPrice = sortedDimensions[0].price;
						} else if (sortedSizes.length > 0) {
							lowestPrice = sortedSizes[0].price;
						}
						return (
							<Link
								key={idx}
								to={`${routeConstants.shop_product_details.route}/${store.id}?product_id=${store.product.product_id}`} // Update the URL as needed
							>
								<ProductCardItem
									name={store.product.product_name}
									src={determineImageSrc(store.product)}
									price={`${lowestPrice} ${store.product.product_currency}`}
									alt={store.product.product_name}
									imgSize="218"
									id={store.id}
									productColors={store.product.product_colors}
								/>
							</Link>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default ShopPage;
