import React, { useState } from "react";

import AuthVerification from "components/Auth/AuthVerification";
import AuthRegister from "components/Auth/AuthRegister";

const RegisterModal: React.FC = () => {
	const [stage, set_stage] = useState(1);
	const [validationData, set_validationData] = useState({
		authMethod: "",
		credentials: {},
		validator_phone_number: "",
		responseMessage: "",
		registerToken: "",
	});

	return (
		<>
			{stage === 1 && (
				<AuthRegister
					onStageChange={(stage: number) => set_stage(stage)}
					validationData={validationData}
					handleValidationData={(data: any) => set_validationData(data)}
				/>
			)}
			{stage === 2 && (
				<AuthVerification
					onStageChange={(stage: number) => set_stage(stage)}
					validationData={validationData}
					handleValidationData={(data: any) => set_validationData(data)}
					register_mode={true}
				/>
			)}
		</>
	);
};

export default RegisterModal;
