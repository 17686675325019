import { combineReducers } from "redux";

// Assuming each reducer is properly typed
import { generateImage as generate } from "./generateImage.reducer";
import { resource } from "./resource.reducer";
import { resourcesPage } from "./resourcesPage.reducer";
import { resourcePage } from "./resourcePage.reducer";
import { category } from "./category.reducer";
import { auth } from "./auth.reducer";
import { alert } from "./alert.reducer";
import cartReducer from "./cart.reducer";
import { variantPage } from "./variantPage.reducer";
import { categoryPage } from "./categoryPage.reducer";
import { address } from "./address.reducer";
import { tax } from "./tax.reducer";
import { shipping } from "./shipping.reducer";
import { paymentCard } from "./paymentCard.reducer";
import wishlistReducer from "./wishlist.reducer";
import { variant } from "./variant.reducer";
import { design } from "./design.reducer";
import { shippingAddress } from "./shippingAddress.reducer";
import giftReducer from "./gift.reducer";

// Export individual reducers if needed
export * from "./generateImage.reducer";

const rootReducer = combineReducers({
	wishlist: wishlistReducer,
	address: address,
	tax: tax,
	shipping: shipping,
	paymentCard: paymentCard,
	variant: variant,
	variantPage: variantPage,
	generate: generate,
	resource: resource,
	resourcePage: resourcePage,
	resourcesPage: resourcesPage,
	categoryPage: categoryPage,
	category: category,
	auth: auth,
	alert: alert,
	cart: cartReducer,
	design: design,
	shippingAddress: shippingAddress,
	gift: giftReducer,
	// Add other reducers here...
});

// Define RootState type based on rootReducer
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
