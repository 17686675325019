import React from "react";
import { useParams } from "react-router-dom";
import ProductDetailsPage from "./ProductDetailsPage";

interface ProductDetailsWrapperProps {
	storeType: "client" | "shop";
}

const ProductDetailsWrapper: React.FC<ProductDetailsWrapperProps> = ({
	storeType,
}) => {
	return <ProductDetailsPage storeType={storeType} />;
};

export default ProductDetailsWrapper;
