export const addressConstants = {
	ON_SELECT: "ADDRESS_ON_SELECT",
	ON_DESELECT: "ADDRESS_ON_DESELECT",
	ON_ADD_TAX: "ADDRESS_ON_ADD_TAX",
	ON_REMOVE_TAX: "ADDRESS_ON_REMOVE_TAX",
	ON_ADD_SHIPPING: "ADDRESS_ON_ADD_SHIPPING",
	ON_REMOVE_SHIPPING: "ADDRESS_ON_REMOVE_SHIPPING",
	SAVE_ADDRESS: "SAVE_ADDRESS",
	CLEAR_ADDRESS: "CLEAR_ADDRESS",
};
