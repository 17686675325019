export const authConstants = {
	REGISTER_REQUEST: 'AUTH_REGISTER_REQUEST',
	REGISTER_SUCCESS: 'AUTH_REGISTER_SUCCESS',
	REGISTER_FAILURE: 'AUTH_REGISTER_FAILURE',

	LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
	LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
	LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',

	RESET_PASSWORD: 'AUTH_RESET_PASSWORD',

	LOGOUT: 'AUTH_LOGOUT',
	PROFILE_UPDATE: 'AUTH_PROFILE_UPDATE',
};