import React, { useState } from "react";
import { motion } from "framer-motion";
import styles from "./FeatureItem.module.css";
import cn from "classnames";
import { Button, Htag, P } from "common";

import { ReactComponent as InfoIcon } from "common/Icons/Info.svg";
import { routeConstants } from "routes";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "common/Tooltip/Tooltip";

interface DataProps {
	icon: JSX.Element;
	name: string;
	description: string;
	button: {
		appearance: "black" | "disabled";
		label: string;
		icon: JSX.Element | null;
		link: string | null;
	};
}

interface FeatureItemProps {
	data: DataProps;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ data }) => {
	const navigate = useNavigate();

	return (
		<div className={cn(styles.productcontainer)}>
			<div className={cn(styles.productinfocontainer)}>
				<div className={cn(styles.productnamecontainer)}>
					<div className={cn(styles.iconcontainer)}>{data.icon}</div>
					<Htag tag="h4">{data.name}</Htag>
				</div>
				<Tooltip
					title="This is title"
					description="You can write words that feature do"
				>
					<div className={cn(styles.moreinfocontainer)}>
						<InfoIcon style={{ cursor: "pointer" }} />

						<P size="b2regular" style={{ cursor: "pointer" }}>
							More info
						</P>
					</div>
				</Tooltip>
			</div>
			<P size="b2regular" style={{ color: "#5D5D5D" }}>
				{data.description}
			</P>

			<div className={cn(styles.detailcontainer)}>
				<Button
					appearance={data.button.appearance}
					icon={data.button.icon ? data.button.icon : "none"}
					widthFull={true}
					onClick={() => navigate(data.button.link ? data.button.link : "")}
				>
					{data.button.label}
				</Button>
			</div>
		</div>
	);
};

export default FeatureItem;
