export const transformCategory = (data: any) => ({
	id: data.id,
	parentId: data.parent_id,
	image: data.image_url,
	catalogPosition: data.catalog_position,
	size: data.size,
	title: data.title,
});

export const transformCategories = (data: any) => ({
	categories: data.categories && data.categories.map(transformCategory),
});

export const transformProductSizes = (data: any) => ({
	productId: data.product_id,
	availableSizes: data.available_sizes.map((sizes: any) => sizes),
});

export const transformVariants = (data: any) => ({
	variant: {
		id: data.id,
		productId: data.product_id,
		name: data.name,
		size: data.size,
		color: data.color,
		colorCode: data.color_code,
		colorCode2: data.color_code2,
		image: data.image,
		price: data.price,
		inStock: data.in_stock,
		availabilityRegions: {
			US: data.US,
			EU: data.EU,
			EU_LV: data.EU_LV,
			EU_ES: data.EU_ES,
			AU: data.AU,
			JP: data.JP,
			CA: data.CA,
			BR: data.BR,
			UK: data.UK,
		},
	},
});
