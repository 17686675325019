import React, { useState } from "react";
import { connect } from "react-redux";

import AuthVerification from "components/Auth/AuthVerification";
import { useNavigate } from "react-router-dom";
import AuthLogin from "components/Auth/AuthLogin";

interface IPropsLogin {
	authOpen?: any;
	setAuthOpen?: any;
	loggedIn?: any;
	children?: any;
}

const LoginModal: React.FC<IPropsLogin> = ({
	authOpen,
	setAuthOpen,
	loggedIn,
}) => {
	const [validationData, set_validationData] = useState({
		authMethod: "",
		credentials: "",
		validator_phone_number: "",
		responseMessage: "",
		registerToken: "",
	});

	const navigate = useNavigate();
	loggedIn && navigate("/");

	return <></>;
};

const mapStateToProps = (state: any) => ({
	loggedIn: state.auth.loggedIn,
});

export default connect(mapStateToProps)(LoginModal);
