import { generateImageConstants as actionConstants } from "airedux/constants";
import * as R from "ramda";

const initialState = {
	loading: false,
	error: false,
	data: {},
};

export const generateImage = (state = initialState, { type, payload }: any) => {
	switch (type) {
		case actionConstants.GENERATE_IMAGE_SUCCESS:
			return R.mergeRight(state, {
				loading: false,
				error: false,
				data: payload,
			});

		case actionConstants.GENERATE_IMAGE_REQUEST:
			return R.mergeRight(state, {
				loading: true,
				error: false,
			});

		case actionConstants.GENERATE_IMAGE_FAILURE:
			return R.mergeRight(state, {
				loading: false,
				error: true,
				data: payload,
			});

		default:
			return state;
	}
};
