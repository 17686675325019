import { addressConstants as actionConstants } from "airedux/constants";

export const selectAddress = (data: any) => {
	return {
		type: actionConstants.ON_SELECT,
		payload: data,
	};
};

export const deselectAddress = () => {
	return {
		type: actionConstants.ON_DESELECT,
		payload: {},
	};
};

export const addTax = (data: any) => {
	return {
		type: actionConstants.ON_ADD_TAX,
		payload: data,
	};
};
export const removeTax = () => {
	return {
		type: actionConstants.ON_REMOVE_TAX,
		payload: {},
	};
};

export const addShipping = (data: any) => {
	return {
		type: actionConstants.ON_ADD_SHIPPING,
		payload: data,
	};
};
export const removeShipping = () => {
	return {
		type: actionConstants.ON_REMOVE_SHIPPING,
		payload: {},
	};
};
