import React, { useEffect, useState } from "react";

import styles from "./PrivacyStatementPage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";
import { Button, Htag, P } from "common";
import { ReactComponent as HamburgerIcon } from "common/Icons/menu.svg";
import { ReactComponent as CancelIcon } from "common/Icons/x.svg";

import ReactGA from "react-ga";
import SEOHelmet from "components/SEOHelmet/SEOHelmet";

interface Section {
	id: any;
	title: string;
	content: string;
	subcontent?: string;
}

const sections: Section[] = [
	{
		id: 1,
		title: "Privacy Statement",
		content:
			"At aiprintable, we are committed to protecting your privacy. This Privacy Statement explains how we collect, use, and disclose personal information through our website. By using our website, you consent to the terms of this Privacy Statement.",
	},
	{
		id: 2,
		title: "Information We Collect",
		content:
			"We may collect personal information from you when you use our website, such as your name, email address,  and shipping address. We may also collect information about your use of our website, such as your IP address, browser type, and device information.",
	},
	{
		id: 3,
		title: "How We Use Your Information",
		content:
			"We use your personal information to fulfill your orders and communicate with you about your orders. We may also use your information to send you marketing emails and newsletters, but you can opt-out of these communications at any time. We may use your information to improve our website and services, such as by analyzing user behavior and preferences.",
	},
	{
		id: 4,
		title: "How We Disclose Your Information",
		content:
			"We may disclose your personal information to third-party service providers who help us operate our website and fulfill your orders. We may also disclose your information to comply with legal requirements or to protect our rights and property. We do not sell or rent your personal information to third parties.",
	},
	{
		id: 5,
		title: "Security of Your Information",
		content:
			"We take reasonable measures to protect your personal information from unauthorized access and disclosure. However, we cannot guarantee the security of your information, and you use our website at your own risk.",
	},
	{
		id: 6,
		title: "Cookies and Tracking Technologies",
		content:
			"We use cookies and other tracking technologies to improve your user experience on our website. Cookies are small text files that are stored on your device when you visit our website. You can disable cookies in your browser settings, but this may limit your ability to use some features of our website.",
	},
	{
		id: 7,
		title: "Children's Privacy",
		content:
			"Our website is not intended for children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you believe we have collected personal information from a child under the age of 13, please contact us immediately.",
	},
	{
		id: 8,
		title: "Changes to This Privacy Statement",
		content:
			"We reserve the right to update or modify this Privacy Statement at any time without notice. Your continued use of our website after any changes to this Privacy Statement constitutes your acceptance of the updated terms",
		subcontent:
			"If you have any questions or concerns about this Privacy Statement, please contact us at info@aiprintable.com.",
	},
];

const PrivacyStatementPage: React.FC = (): JSX.Element => {
	const [open, setOpen] = useState(false);
	const handleLinkClick = (sectionid: any) => {
		const sectionElement = document.querySelector(
			`[data-section-id="${sectionid}"]`
		);

		if (sectionElement) {
			sectionElement.scrollIntoView({ behavior: "smooth" });
		}
		setOpen(false);
	};

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	const toggleBurger = () => {
		setOpen(!open);
	};

	useEffect(() => {
		if (open) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.removeProperty("overflow");
		};
	}, [open]);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const seoProps = {
		title: "Privacy Statement - AI Printable",
		description:
			"Read AI Printable's Privacy Statement. Learn about our commitment to protecting your privacy and how we handle your personal information.",
		keywords:
			"privacy statement, AI Printable privacy, data protection, personal information security",
		url: "https://www.aiprintable.com/privacy-statement",
		contentType: "website",
		subject: "AI Printable Privacy and Data Protection",
		copyright: "AI Printable, © 2024",
		language: "en",
		robots: "index,follow",
		revised: "Sunday, March 7th, 2024, 5:15 pm",
		abstract:
			"AI Printable is dedicated to protecting your privacy. This statement outlines our practices regarding personal information collection and usage.",
		topic: "Privacy Policy",
		summary:
			"AI Printable's Privacy Statement details our approach to safeguarding user privacy, including information collection, usage, and security measures.",
		classification: "Privacy, Security",
		author: "AI Printable, info@aiprintable.com",
		designer: "AI Printable Team",
		replyTo: "info@aiprintable.com",
		owner: "AI Printable",
		identifierURL: "https://www.aiprintable.com",
		directory: "submission",
		pagename: "Privacy Statement - AI Printable",
		category: "Legal, Privacy",
		coverage: "Worldwide",
		distribution: "Global",
		rating: "General",
		revisitAfter: "7 days",
		subtitle: "Our Commitment to Your Privacy",
		target: "all",
		handheldFriendly: "True",
		mobileOptimized: "320",
		date: "2024-03-07",
		searchDate: "2024-03-07",
		DCtitle: "Privacy Statement of AI Printable",
	};

	return (
		<div className={cn(styles.privacystatementpage)}>
			<SEOHelmet {...seoProps} />
			<SubNavbar
				disabled="Home /"
				active="Privacy Statement"
				pageName="Privacy Statement"
			/>
			<div className={cn(styles.container)}>
				{window.innerWidth > 767 ? (
					<div className={styles.links}>
						<Htag tag="h3" className={styles.topics}>
							Topics
						</Htag>
						{sections.map((section) => (
							<Htag tag="s2-bold">
								<a
									key={section.id}
									className={styles.linkitem}
									onClick={() => handleLinkClick(section.id)}
								>
									{section.title}
								</a>
							</Htag>
						))}
					</div>
				) : (
					<>
						<div className={cn(styles.hamburgerfirstrow)}>
							{open === false && (
								<Button
									appearance="ghost-white"
									onClick={toggleBurger}
									className={styles.buttontopic}
									icon={<HamburgerIcon className={styles.hamburgericon} />}
								>
									Choose topics
								</Button>
							)}
						</div>
						{open === true && (
							<div className={styles.darkbg} onClick={() => setOpen(false)}>
								<div className={styles.mobilelinks}>
									<div className={styles.mobileburger}>
										<Htag tag="h3">Topics</Htag>
										<CancelIcon onClick={toggleBurger} />
									</div>
									{sections.map((section) => (
										<Htag tag="s2-bold">
											<a
												key={section.id}
												className={styles.linkitem}
												onClick={() => handleLinkClick(section.id)}
											>
												{section.title}
											</a>
										</Htag>
									))}
								</div>
							</div>
						)}
					</>
				)}

				<div
					className={
						window.innerWidth > 767 ? styles.sections : styles.mobilesections
					}
				>
					{sections.map((section) => (
						<div
							key={section.id}
							data-section-id={section.id}
							className={styles.contentitem}
						>
							<Htag tag="s2-bold">{section.title}</Htag>
							<P size="p1">{section.content}</P>
							<P size="p1">{section.subcontent && section.subcontent}</P>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default PrivacyStatementPage;
