// route to get single variant in product (exaple variant_id = 10291)

// curl --request GET \
//   --url https://api.printful.com/products/variant/10291 \

export const single_sync_variant = {
	code: 200,
	result: {
		sync_product: {
			id: 296045769,
			external_id: "63ae5a6b5ab4b5",
			name: "Company Logo2",
			variants: 16,
			synced: 16,
			thumbnail_url:
				"https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
			is_ignored: false,
		},
		sync_variant: {
			id: 3678794710,
			external_id: "63ae5a6b5b72f6",
			sync_product_id: 296045769,
			name: "Company Logo2 - Asphalt / S",
			synced: true,
			variant_id: 8623,
			main_category_id: 23,
			warehouse_product_variant_id: null,
			retail_price: null,
			sku: null,
			currency: "USD",
			product: {
				variant_id: 8623,
				product_id: 248,
				image:
					"https://files.cdn.printful.com/products/248/8623_1581415258.jpg",
				name: "Bella + Canvas 3480 Unisex Jersey Tank with Tear Away Label (Asphalt / S)",
			},
			files: [
				{
					id: 509653287,
					type: "default",
					hash: "4cab22f94bfa237b5ba414e8db2aad86",
					url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
					filename: "output.jpg",
					mime_type: "image/jpeg",
					size: 2519617,
					width: 3200,
					height: 3200,
					dpi: null,
					status: "ok",
					created: 1672370795,
					thumbnail_url:
						"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
					preview_url:
						"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
					visible: true,
					is_temporary: false,
				},
				{
					id: 509653503,
					type: "preview",
					hash: "bb3e0ef5a986bebafc6d4548a8a74b18",
					url: null,
					filename: "mens-staple-tank-top-asphalt-front-63ae5aab027ee.png",
					mime_type: "image/png",
					size: 635132,
					width: 1000,
					height: 1000,
					dpi: null,
					status: "ok",
					created: 1672370860,
					thumbnail_url:
						"https://files.cdn.printful.com/files/bb3/bb3e0ef5a986bebafc6d4548a8a74b18_thumb.png",
					preview_url:
						"https://files.cdn.printful.com/files/bb3/bb3e0ef5a986bebafc6d4548a8a74b18_preview.png",
					visible: false,
					is_temporary: false,
				},
			],
			options: [
				{
					id: "lifelike",
					value: false,
				},
			],
			is_ignored: false,
		},
	},
	extra: [],
};

export const single_variant_details = {
	code: 200,
	result: {
		variant: {
			id: 10291,
			product_id: 362,
			name: " Unisex Organic T-shirt (White / S)",
			size: "S",
			color: "White",
			color_code: "#ffffff",
			color_code2: null,
			image: "https://files.cdn.printful.com/products/362/10291_1554804730.jpg",
			price: "17.95",
			in_stock: true,
			availability_regions: {
				US: "United States",
				CA: "Canada",
			},
			availability_status: [
				{
					region: "US",
					status: "in_stock",
				},
				{
					region: "CA",
					status: "in_stock",
				},
			],
		},
		product: {
			id: 362,
			main_category_id: 24,
			type: "T-SHIRT",
			type_name: "T-Shirt",
			title: "Unisex Organic T-Shirt | Econscious EC1000",
			brand: "Econscious",
			model: "Unisex Organic T-Shirt",
			image:
				"https://files.cdn.printful.com/o/upload/product-catalog-img/3e/3eb6dee7f9d2b332b5a165592db03dbe_l",
			variant_count: 10,
			currency: "USD",
			options: [
				{
					id: "thread_colors_chest_left",
					title: "Left chest thread colors",
					type: "multi_select",
					values: {
						"#FFFFFF": "1801 White",
						"#000000": "1800 Black",
						"#96A1A8": "1718 Grey",
						"#A67843": "1672 Old Gold",
						"#FFCC00": "1951 Gold",
						"#E25C27": "1987 Orange",
						"#CC3366": "1910 Flamingo",
						"#CC3333": "1839 Red",
						"#660000": "1784 Maroon",
						"#333366": "1966 Navy",
						"#005397": "1842 Royal",
						"#3399FF": "1695 Aqua/Teal",
						"#6B5294": "1832 Purple",
						"#01784E": "1751 Kelly Green",
						"#7BA35A": "1848 Kiwi Green",
					},
					additional_price: null,
					additional_price_breakdown: [],
				},
				{
					id: "thread_colors_chest_center",
					title: "Center chest thread colors",
					type: "multi_select",
					values: {
						"#FFFFFF": "1801 White",
						"#000000": "1800 Black",
						"#96A1A8": "1718 Grey",
						"#A67843": "1672 Old Gold",
						"#FFCC00": "1951 Gold",
						"#E25C27": "1987 Orange",
						"#CC3366": "1910 Flamingo",
						"#CC3333": "1839 Red",
						"#660000": "1784 Maroon",
						"#333366": "1966 Navy",
						"#005397": "1842 Royal",
						"#3399FF": "1695 Aqua/Teal",
						"#6B5294": "1832 Purple",
						"#01784E": "1751 Kelly Green",
						"#7BA35A": "1848 Kiwi Green",
					},
					additional_price: null,
					additional_price_breakdown: [],
				},
				{
					id: "thread_colors_large_center",
					title: "Large center thread colors",
					type: "multi_select",
					values: {
						"#FFFFFF": "1801 White",
						"#000000": "1800 Black",
						"#96A1A8": "1718 Grey",
						"#A67843": "1672 Old Gold",
						"#FFCC00": "1951 Gold",
						"#E25C27": "1987 Orange",
						"#CC3366": "1910 Flamingo",
						"#CC3333": "1839 Red",
						"#660000": "1784 Maroon",
						"#333366": "1966 Navy",
						"#005397": "1842 Royal",
						"#3399FF": "1695 Aqua/Teal",
						"#6B5294": "1832 Purple",
						"#01784E": "1751 Kelly Green",
						"#7BA35A": "1848 Kiwi Green",
					},
					additional_price: null,
					additional_price_breakdown: [],
				},
				{
					id: "notes",
					title: "Embroidery notes",
					type: "text",
					values: null,
					additional_price: null,
					additional_price_breakdown: [],
				},
				{
					id: "lifelike",
					title: "Lifelike",
					type: "bool",
					values: null,
					additional_price: null,
					additional_price_breakdown: [],
				},
			],
			dimensions: null,
			is_discontinued: false,
			avg_fulfillment_time: null,
			techniques: [
				{
					key: "EMBROIDERY",
					display_name: "Embroidery",
					is_default: false,
				},
				{
					key: "DTG",
					display_name: "DTG printing",
					is_default: true,
				},
			],
			files: [
				{
					id: "embroidery_chest_left",
					type: "embroidery_chest_left",
					title: "Left chest",
					additional_price: "2.95",
				},
				{
					id: "embroidery_chest_center",
					type: "embroidery_chest_center",
					title: "Center chest",
					additional_price: "2.95",
				},
				{
					id: "embroidery_large_center",
					type: "embroidery_large_center",
					title: "Large center",
					additional_price: "3.95",
				},
				{
					id: "default",
					type: "front",
					title: "Front print",
					additional_price: null,
				},
				{
					id: "back",
					type: "back",
					title: "Back print",
					additional_price: "5.95",
				},
				{
					id: "label_inside",
					type: "label_inside",
					title: "Inside label",
					additional_price: "2.49",
				},
				{
					id: "label_outside",
					type: "label_outside",
					title: "Outside label",
					additional_price: "2.49",
				},
				{
					id: "sleeve_left",
					type: "sleeve_left",
					title: "Left sleeve",
					additional_price: "2.49",
				},
				{
					id: "sleeve_right",
					type: "sleeve_right",
					title: "Right sleeve",
					additional_price: "2.49",
				},
				{
					id: "preview",
					type: "mockup",
					title: "Mockup",
					additional_price: null,
				},
			],
			description:
				"Go natural—order this t-shirt and enjoy the comfort of a truly all-natural organic cotton that is grown and harvested without any fertilizers and pesticides. The high-quality ringspun cotton yarns make sure that the fabric is extra soft and durable. If you’re looking to invest in ethical clothing that is sure to last and has a low environmental impact, look no further than this organic t-shirt!\r\n\r\n• 100% certified organic ring-spun cotton\r\n• Fabric weight: 5.5 oz/yd² (186.5 g/m²)\r\n• Regular fit\r\n• Side-seamed construction\r\n• 1×1 rib neck\r\n• Shoulder seam finished with a self-fabric tape\r\n• Double-needle coverstitched seams\r\n• Low environmental impact\r\n• Blank product sourced from Mexico",
		},
	},
	extra: [],
};
