import { fetchWithCred, handleResponse } from "airedux/helpers";
import { serviceConfig } from "configs";
import {
	single_sync_variant,
	single_variant_details,
} from "./mock/variants.mock";

const fetchRealVariantById = async (storeid: any, id: any) => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve, reject) => {
			resolve(single_variant_details);
		});
	}
	const requestOptions = {
		method: "GET",
	};
	return await fetchWithCred(
		`${serviceConfig.apiHost}/api/v1/store/${storeid}/product/${id}`,
		requestOptions
	).then(handleResponse);
};

const fetchVariantById = async (id: any) => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve, reject) => {
			resolve(single_sync_variant);
		});
	}

	const tunnel_printful_url = `${serviceConfig.apiTunnelUrl}`;
	const requestOptions = {
		headers: {
			req_url: `https://api.printful.com/store/variants/${id}`,
			method: "get",
		},
	};
	return await fetchWithCred(`${tunnel_printful_url}`, requestOptions).then(
		handleResponse
	);
};

const fetchRealById_data = async (storeid: any, id: any) => {
	const response = await fetchRealVariantById(storeid, id);
	//return transformResource(response.result);
	return response;
};

export const variantService = {
	fetchRealById_data,
	fetchVariantById,
};
