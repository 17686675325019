import { fetchWithCred, handleResponse } from "airedux/helpers";
import { serviceConfig } from "configs";

const create_online_payment = async (
	payload: any,
	order_id: any,
	email: any
) => {
	const requestOptions = {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json",
		},
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.apiPrefix}${serviceConfig.routes.create_online_payment}${order_id}?email=${email}`,
		requestOptions
	).then(handleResponse);
};

const validate_online_payment = async (order_id: any, email: any) => {
	const requestOptions = {
		method: "POST",
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.apiPrefix}${serviceConfig.routes.validate_online_payment}${order_id}?email=${email}`,
		requestOptions
	).then(handleResponse);
};

const cancel_online_payment = async (order_id: any, email: any) => {
	const requestOptions = {
		method: "POST",
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.apiPrefix}${serviceConfig.routes.cancel_online_payment}${order_id}?email=${email}`,
		requestOptions
	).then(handleResponse);
};

export const onlinePaymentService = {
	create_online_payment,
	validate_online_payment,
	cancel_online_payment,
};
