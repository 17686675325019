import { ReactNode } from "react";
import "./Accordion.scss";

interface WrapperProps {
	children: ReactNode;
}

export const AccordionWrapper: React.FC<WrapperProps> = ({ children }) => {
	return (
		<div className="wrapper">
			<ul className="accordion-list">{children}</ul>
		</div>
	);
};
