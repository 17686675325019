import ProductCardItem from "common/ProductCardItem/ProductCardItem";
import styles from "./ProductCardCollection.module.css";
import cn from "classnames";
import { serviceConfig } from "configs";

import { Link } from "react-router-dom";
import { routeConstants } from "routes";

const ProductCardCollection: any = ({
	resource,
	storeType,
}: any): JSX.Element => {
	const determineImageSrc = (variant: any) => {
		if (variant.product_colors && variant.product_colors.length > 0) {
			return `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${variant.product_colors[0].image}`;
		} else {
			return `${serviceConfig.apiHost}${serviceConfig.apiPrefix}${variant.product_image}`;
		}
	};
	return (
		<div className={cn(styles.container)}>
			<div className={cn(styles.productcardcollection)}>
				{resource &&
					resource.products &&
					resource.products.length > 0 &&
					resource.products.map((variant: any, idx: number) => {
						const sortedDimensions = variant.product_dimensions.sort(
							(a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
						);
						const sortedSizes = variant.product_sizes.sort(
							(a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
						);
						let lowestPrice = variant.product_price;
						if (sortedDimensions.length > 0) {
							lowestPrice = sortedDimensions[0].price;
						} else if (sortedSizes.length > 0) {
							lowestPrice = sortedSizes[0].price;
						}
						return (
							<Link
								key={idx}
								to={`${
									storeType === "client"
										? routeConstants.client_product_details.route
										: routeConstants.shop_product_details.route
								}/${resource.id}?product_id=${variant.product_id}`}
							>
								<ProductCardItem
									name={variant.product_name}
									src={determineImageSrc(variant)}
									price={`${lowestPrice} ${variant.product_currency}`}
									alt={determineImageSrc(variant)}
									imgSize="218"
									id={variant.id}
									productColors={variant.product_colors}
									variantData={variant}
									productData={resource}
									// discount="43%"
									// discountPrice="45$"
								/>
							</Link>
						);
					})}
			</div>
			{/* <Button appearance="ghost-white">View all products (87)</Button> */}
		</div>
	);
};

export default ProductCardCollection;
