import {
	applyMiddleware,
	createStore,
	Store,
	AnyAction,
	Dispatch,
} from "redux";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer, { RootState } from "airedux/reducers"; // Adjust the import path as necessary

// Define AppState based on the state shape returned by rootReducer
export type AppState = RootState;

// Initialize loggerMiddleware without explicitly specifying the type
const loggerMiddleware = createLogger();

// Define the Middleware array with explicit type to ensure compatibility
const middlewares: any = [thunk as any];

// Conditionally add loggerMiddleware in development mode
if (process.env.NODE_ENV === "development") {
	middlewares.push(loggerMiddleware);
}

// Create the Redux store with rootReducer, middleware, and Redux DevTools extension
const store: Store<AppState, AnyAction> & {
	dispatch: Dispatch<AnyAction>;
} = createStore(
	rootReducer, // The root reducer that combines all your app's reducers
	composeWithDevTools(applyMiddleware(...middlewares)) // Apply middleware along with any development tools
);

export { store };
