import { get_local_data_by_key } from "airedux/helpers";
import { authService } from "airedux/services";
import { authConstants } from "airedux/constants";
import { store } from "airedux/helpers";

export const authBearerHeader = (key: string) => {
	if (key) {
		return { "aicommerce-access-token": key };
	}
	return {};
};

export const authBearerHeaderAsync = async (key = "user") => {
	const token = await getTokenWithAuth(key);
	if (token) {
		return { "aicommerce-access-token": token };
	}
	return {};
};

export const getTokenWithAuth = async (key = "user", secondCheck = false) => {
	const user: any = get_local_data_by_key(key);
	let token = "";
	if (user && user.token) {
		const current_date = new Date();
		const exp_date = new Date(user.exp);
		if (exp_date > current_date) {
			token = user.token;
			return token;
		} else {
			if (!secondCheck) {
				if (user.auth_username && user.auth_password) {
					await authService.login(user.auth_username, user.auth_password).then(
						(response: any) => {
							store.dispatch({
								type: authConstants.LOGIN_SUCCESS,
								payload: response,
							});
						},
						(error: any) => {
							console.log(error);
							store.dispatch({
								type: authConstants.LOGIN_FAILURE,
								payload: error,
							});
						}
					);
					token = await getTokenWithAuth(key, (secondCheck = true));
				}
			}
		}
	}
	return token;
};

export const authTokenHeader = () => {
	const user: any = get_local_data_by_key("user");
	if (user && user.token) {
		const current_date = new Date();
		const exp_date = new Date(user.exp);
		if (exp_date > current_date) {
			return { "aicommerce-access-token": user.token };
		}
	}
	return { "aicommerce-access-token": "" };
};

export const checkLoginAndGetToken = (key = "user") => {
	const user: any = get_local_data_by_key(key);
	if (user && user.token) {
		const current_date = new Date();
		const exp_date = new Date(user.exp);
		if (exp_date > current_date) {
			return user.token;
		}
	}
	return "";
};

export const authBasicHeader = (key = "user") => {
	let response_header = {};
	const user: any = get_local_data_by_key(key);
	const authorization = btoa(`${user.auth_username}:${user.auth_password}`);

	if (user) {
		response_header = { "aicommerce-access-token": authorization };
	}

	return response_header;
};
