import React, { useEffect, useState } from "react";

import styles from "./AffiliatePage.module.css";
import cn from "classnames";
import { Button, Htag, P } from "common";
import ReactGA from "react-ga";
import { ReactComponent as MedalIcon } from "common/Icons/Medal.svg";
import { ReactComponent as PercentageIcon } from "common/Icons/Percent.svg";
import { ReactComponent as HeartHandIcon } from "common/Icons/HandHeart.svg";
import { ReactComponent as SettingIcon } from "common/Icons/Wrench.svg";
import SEOHelmet from "components/SEOHelmet/SEOHelmet";

const AffiliatePage: React.FC = (): JSX.Element => {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const seoProps = {
		title: "Join the AI Printable Affiliate Program",
		description:
			"Earn commissions by promoting AI Printable. Join our Affiliate Program today and help us revolutionize the future of design and personalized gift-giving.",
		keywords:
			"AI Printable, Affiliate Program, Earn Commissions, Design, Personalized Gifts",
		url: "https://www.aiprintable.com/affiliate",
		contentType: "website",
		subject: "AI Printable Affiliate Program",
		copyright: "AI Printable, © 2024",
		language: "en",
		robots: "index,follow",
		revised: "Sunday, March 7th, 2024, 5:15 pm",
		abstract:
			"Join the AI Printable Affiliate Program and start earning by promoting our innovative, AI-powered personalized gifts.",
		topic: "Affiliate Marketing",
		summary:
			"Become an affiliate partner and leverage your network to promote AI Printable, earning competitive commissions on each sale.",
		classification: "Affiliate Program, E-commerce",
		author: "AI Printable, info@aiprintable.com",
		designer: "AI Printable Team",
		replyTo: "info@aiprintable.com",
		owner: "AI Printable",
		identifierURL: "https://www.aiprintable.com",
		directory: "submission",
		pagename: "AI Printable Affiliate Program",
		category: "E-commerce, Affiliate Marketing",
		coverage: "Worldwide",
		distribution: "Global",
		rating: "General",
		revisitAfter: "7 days",
		subtitle: "Empower Your Creativity and Earn with AI Printable",
		target: "all",
		handheldFriendly: "True",
		mobileOptimized: "320",
		date: "2024-03-07",
		searchDate: "2024-03-07",
		DCtitle: "AI Printable Affiliate Program",
	};

	return (
		<div className={cn(styles.affiliatepage)}>
			<SEOHelmet {...seoProps} />
			<div className={cn(styles.topcontainer)}>
				<div className={cn(styles.titlecontainer)}>
					<P size="b1bold" className={cn(styles.topcontainertext)}>
						Join the Future of Design & Earn!
					</P>
					<Htag tag="h2" style={{ textAlign: "center" }}>
						AI Printable Affiliate Program
					</Htag>
				</div>
				<P size="p1" className={cn(styles.topcontainertextp)}>
					Welcome to the AI Printable Affiliate Program! If you're passionate
					about design, AI technology, and innovative merchandise, you're in the
					right place. By joining our program, you can earn commissions by
					promoting AI Printable and bringing more creators into the future of
					design.
				</P>
			</div>

			<div className={cn(styles.partnercontainer)}>
				<div style={{ display: "grid", gap: "8px" }}>
					<Htag tag="h3" style={{ textAlign: "center" }}>
						Why Partner
					</Htag>
					<P size="p1" className={cn(styles.topcontainertext)}>
						Open positions in our design team.
					</P>
				</div>
				<div className={cn(styles.partneritemcontainer)}>
					<div className={cn(styles.partneritem)}>
						<Htag tag="s2-medium">Innovative Product</Htag>
						<P size="p1" style={{ color: "#5D5D5D" }}>
							AI Printable is at the forefront of AI-powered design, offering
							unique, personalized merchandise in seconds.
						</P>
					</div>
					<div className={cn(styles.partneritem)}>
						<Htag tag="s2-medium">Generous Commissions:</Htag>
						<P size="p1" style={{ color: "#5D5D5D" }}>
							Earn competitive commissions for every sale made through your
							referral.
						</P>
					</div>
					<div className={cn(styles.partneritem)}>
						<Htag tag="s2-medium">Dedicated Support:</Htag>
						<P size="p1" style={{ color: "#5D5D5D" }}>
							Our team is here to support you every step of the way, ensuring
							you have all the tools you need to succeed.
						</P>
					</div>
					<div className={cn(styles.partneritem)}>
						<Htag tag="s2-medium">Timely Payments:</Htag>
						<P size="p1" style={{ color: "#5D5D5D" }}>
							Receive your earnings on time, every time.
						</P>
					</div>
				</div>
			</div>

			<div className={cn(styles.howitworkscontainer)}>
				<Htag tag="h3" style={{ textAlign: "center" }}>
					How it works
				</Htag>
				<div className={cn(styles.howitworksitemcontainer)}>
					<div className={cn(styles.howitworksitem)}>
						<div
							style={{
								padding: "21px 23px",
								borderRadius: "100%",
								background: "#F6F6F6",
								height: "max-content",
							}}
						>
							<Htag tag="s2-bold">01</Htag>
						</div>
						<div style={{ display: "grid", gap: "8px" }}>
							<Htag tag="s2-medium">Sign Up:</Htag>
							<P size="p1" style={{ color: "#667085" }}>
								Join the AI Printable Affiliate Program.
							</P>
						</div>
					</div>

					<div className={cn(styles.howitworksitem)}>
						<div
							style={{
								padding: "21px 23px",
								borderRadius: "100%",
								background: "#F6F6F6",
								height: "max-content",
							}}
						>
							<Htag tag="s2-bold">02</Htag>
						</div>
						<div style={{ display: "grid", gap: "8px" }}>
							<Htag tag="s2-medium">Promote:</Htag>
							<P size="p1" style={{ color: "#667085" }}>
								Use our range of promotional materials to share AI Printable
								with your audience.
							</P>
						</div>
					</div>
					<div className={cn(styles.howitworksitem)}>
						<div
							style={{
								padding: "21px 23px",
								borderRadius: "100%",
								background: "#F6F6F6",
								height: "max-content",
							}}
						>
							<Htag tag="s2-bold">03</Htag>
						</div>
						<div style={{ display: "grid", gap: "8px" }}>
							<Htag tag="s2-medium">Earn:</Htag>
							<P size="p1" style={{ color: "#667085" }}>
								Receive a commission for every sale made through your unique
								affiliate link.
							</P>
						</div>
					</div>
				</div>
			</div>

			<div className={cn(styles.affiliatebenefitscontainer)}>
				<Htag tag="h3" style={{ textAlign: "center" }}>
					Affiliate Benefits
				</Htag>
				<div className={cn(styles.partneritemcontainer)}>
					<div className={cn(styles.affiliatebenefitsitem)}>
						<div className={cn(styles.iconcontainer)}>
							<MedalIcon />
						</div>
						<div style={{ display: "grid", gap: "8px" }}>
							<Htag tag="s2-medium">High Conversion Rate:</Htag>
							<P size="p1" style={{ color: "#5D5D5D" }}>
								Our innovative platform and user-friendly interface ensure high
								conversion rates.
							</P>
						</div>
					</div>

					<div className={cn(styles.affiliatebenefitsitem)}>
						<div className={cn(styles.iconcontainer)}>
							<PercentageIcon />
						</div>
						<div style={{ display: "grid", gap: "8px" }}>
							<Htag tag="s2-medium">Generous Commissions:</Htag>
							<P size="p1" style={{ color: "#5D5D5D" }}>
								Offer exclusive discounts to your audience, making it a win-win
								for everyone.
							</P>
						</div>
					</div>

					<div className={cn(styles.affiliatebenefitsitem)}>
						<div className={cn(styles.iconcontainer)}>
							<HeartHandIcon />
						</div>
						<div style={{ display: "grid", gap: "8px" }}>
							<Htag tag="s2-medium">Dedicated Support:</Htag>
							<P size="p1" style={{ color: "#5D5D5D" }}>
								Monitor your referrals, conversions, and earnings in real-time
								through our affiliate dashboard.
							</P>
						</div>
					</div>

					<div className={cn(styles.affiliatebenefitsitem)}>
						<div className={cn(styles.iconcontainer)}>
							<SettingIcon />
						</div>
						<div style={{ display: "grid", gap: "8px" }}>
							<Htag tag="s2-medium">Regular Updates:</Htag>
							<P size="p1" style={{ color: "#5D5D5D" }}>
								Stay updated with the latest features, products, and promotional
								materials.
							</P>
						</div>
					</div>
				</div>
			</div>

			<div className={cn(styles.affiliatebenefitscontainer)}>
				<Htag tag="h3" style={{ textAlign: "center" }}>
					Who can join?
				</Htag>
				<Htag tag="s2-medium" className={cn(styles.affiliatebenefittext)}>
					Bloggers, influencers, designers, tech enthusiasts, or anyone with a
					passion for innovative design and merchandise! Whether you have a
					large following or a niche audience, you can benefit from our
					affiliate program.
				</Htag>
			</div>

			<div className={cn(styles.bottomcontainer)}>
				<div className={cn(styles.bottomtitlecontainer)}>
					<Htag tag="h2" style={{ textAlign: "center" }}>
						Ready to Partner with AI Printable?
					</Htag>
					<P size="p1" className={cn(styles.topcontainertextp)}>
						Join us in revolutionizing the world of design and merchandise. Sign
						up today and start earning!
					</P>
				</div>
				<Button appearance="black" className={cn(styles.button)}>
					Join now
				</Button>
			</div>
		</div>
	);
};

export default AffiliatePage;
