import { fetchWithCred, handleResponse } from "airedux/helpers";
import axios from "axios";
import { serviceConfig } from "configs";

const fetchWishlist = async (userId: string) => {
	try {
		const response = await axios.post(
			`${serviceConfig.apiHost}${serviceConfig.routes.wishlist}`,
			{ userId }, // Correctly passing userId in the request body
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		return response.data; // Returning the response data directly
	} catch (error) {
		console.error("Error fetching wishlist:", error);
		throw error; // Rethrowing the error for caller to handle
	}
};

const addWishlist = async (payload: any) => {
	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(payload),
	};

	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.wishlist}add`,
		requestOptions
	).then(handleResponse);
};

const deleteWishlist = async (id: any) => {
	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ id: id }),
	};

	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.wishlist}delete`,
		requestOptions
	).then(handleResponse);
};

export const wishlistService = {
	fetchWishlist,
	addWishlist,
	deleteWishlist,
};
