export const variantConstants = {
	FETCH_START: "VARIANT_FETCH_START",
	FETCH_SUCCESS: "VARIANT_FETCH_SUCCESS",
	FETCH_FAILURE: "VARIANT_FETCH_FAILURE",

	FETCH_BY_ID_START: "VARIANT_FETCH_BY_ID_START",
	FETCH_BY_ID_SUCCESS: "VARIANT_FETCH_BY_ID_SUCCESS",
	FETCH_BY_ID_FAILURE: "VARIANT_FETCH_BY_ID_FAILURE",

	ADDED_TO_CART: "VARIANT_ADDED_TO_CART",
	REMOVED_FROM_CART: "VARIANT_REMOVED_FROM_CART",
	ALL_REMOVED_FROM_CART: "VARIANT_ALL_REMOVED_FROM_CART",
	CLEAR_ALL_REMOVED_FROM_CART: "VARIANTS_CLEARED_FROM_CART",

	ADDED_TO_WISHLIST: "VARIANT_ADDED_TO_WISHLIST",
	REMOVED_FROM_WISHLIST: "VARIANT_REMOVED_FROM_WISHLIST",
	WISHLIST_FETCH_START: "VARIANT_WISHLIST_FETCH_START",
	WISHLIST_FETCH_SUCCESS: "VARIANT_WISHLIST_FETCH_SUCCESS",
	WISHLIST_FETCH_FAILURE: "VARIANT_WISHLIST_FETCH_FAILURE",
};
