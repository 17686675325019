import React, { useState } from "react";
import { motion } from "framer-motion";
import styles from "./OurProductItem.module.css";
import cn from "classnames";
import { Button, Htag, P } from "common";

import { ReactComponent as ArrowDownIcon } from "common/Icons/CaretDown.svg";
import { ReactComponent as ArrowUpIcon } from "common/Icons/CaretUp.svg"; // Make sure you import this
import { routeConstants } from "routes";
import { useNavigate } from "react-router-dom";

interface ProductProps {
	icon: JSX.Element;
	name: string;
	description: string;
	price: string;
	sizes: string[];
	colors: string[];
}

interface OurProductItemProps {
	product: ProductProps;
}

const OurProductItem: React.FC<OurProductItemProps> = ({ product }) => {
	const navigate = useNavigate();
	const [showDetails, setShowDetails] = useState(false);
	const [isAnimationCompleted, setAnimationCompleted] = useState(true);

	const variants = {
		hidden: { y: -100, height: 0 },
		visible: { y: 0, height: "auto" },
	};

	const handleAnimationComplete = () => {
		if (!showDetails) {
			setAnimationCompleted(true);
		}
	};

	return (
		<div className={cn(styles.productcontainer)}>
			<div className={cn(styles.productinfocontainer)}>
				<div className={cn(styles.productnamecontainer)}>
					<div className={cn(styles.iconcontainer)}>{product.icon}</div>
					<Htag tag="h4">{product.name}</Htag>
				</div>
				<div
					className={cn(
						showDetails ? styles.showdetailscontainer : styles.dropdowncontainer
					)}
					onClick={() => setShowDetails(!showDetails)}
				>
					{showDetails ? (
						<ArrowUpIcon className={cn(styles.dropdownicon)} />
					) : (
						<ArrowDownIcon className={cn(styles.dropdownicon)} />
					)}
					<P
						size="b2regular"
						className={cn(showDetails && styles.showdetailsname)}
						style={{ cursor: "pointer" }}
					>
						More info
					</P>
				</div>
			</div>
			<P size="b2regular" style={{ color: "#5D5D5D" }}>
				{product.description}
			</P>
			<div className={cn(styles.detailcontainer)}>
				<div className={cn(styles.detailitem)}>
					<P size="b2medium" style={{ color: "#5D5D5D" }}>
						Price:
					</P>
					<P size="b2medium">{product.price}</P>
				</div>
				{product.sizes && (
					<div className={cn(styles.detailitem)}>
						<P size="b2medium" style={{ color: "#5D5D5D" }}>
							Size:
						</P>
						<P size="b2medium">{product.sizes.length} Sizes</P>
					</div>
				)}

				{product.colors && product.colors.length > 0 && (
					<div className={cn(styles.detailitem)}>
						<P size="b2medium" style={{ color: "#5D5D5D" }}>
							Colors:
						</P>
						<P size="b2medium">{product.colors.length} type</P>
					</div>
				)}
			</div>
			{showDetails || !isAnimationCompleted ? (
				<motion.div
					initial="hidden"
					animate={showDetails ? "visible" : "hidden"}
					variants={variants}
					transition={{ duration: 0.2, type: "spring", stiffness: 80 }}
					onAnimationComplete={handleAnimationComplete}
					className={cn(styles.extraDetailContainer)}
				>
					<div className={cn(styles.showdetailsitemscontainer)}>
						<P size="b2medium" style={{ color: "#5D5D5D" }}>
							Sizes:
						</P>
						<div className={cn(styles.showdetailsitems)}>
							{product.sizes.map((size) => (
								<div className={cn(styles.detailitems)}>
									<P size="b2medium">{size}</P>
								</div>
							))}
						</div>
					</div>

					{product.colors && product.colors.length > 0 && (
						<div className={cn(styles.showdetailsitemscontainer)}>
							<P size="b2medium" style={{ color: "#5D5D5D" }}>
								Colors:
							</P>
							<div className={cn(styles.showdetailsitems)}>
								{product.colors.map((color) => (
									<div className={cn(styles.detailitems)}>
										<P size="b2medium">{color}</P>
									</div>
								))}
							</div>
						</div>
					)}

					<Button
						appearance="black"
						style={{ padding: "14px 24px" }}
						onClick={() => navigate(routeConstants.generate.route)}
					>
						Go to generate
					</Button>
				</motion.div>
			) : null}
		</div>
	);
};

export default OurProductItem;
