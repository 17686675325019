import React, { useEffect, useState } from "react";

import styles from "./AboutUsPage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";
import { Htag, P } from "common";
import ReactGA from "react-ga";
import SEOHelmet from "components/SEOHelmet/SEOHelmet";

const AboutUsPage: React.FC = (): JSX.Element => {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const seoProps = {
		title: "About Us - AI Printable: Personalized Gift-Giving Revolution",
		description:
			"Discover how AI Printable is revolutionizing personalized gifts with AI-powered technology. Create unique, customized gifts with ease, no design skills needed.",
		keywords:
			"AI Printable, personalized gifts, AI technology, custom gifts, e-commerce",
		url: "https://www.aiprintable.com/about-us",
		contentType: "website",
		subject: "About AI Printable",
		copyright: "AI Printable, © 2024",
		language: "en",
		robots: "index,follow",
		revised: "Sunday, March 7th, 2024, 5:15 pm",
		abstract:
			"AI Printable uses AI-powered technology to create personalized gifts, making gift-giving simple and creative.",
		topic: "Personalized Gifts",
		summary:
			"AI Printable offers an innovative platform for creating personalized gifts using AI technology.",
		classification: "E-commerce, Personalized Gifts",
		author: "AI Printable, info@aiprintable.com",
		designer: "AI Printable Team",
		replyTo: "info@aiprintable.com",
		owner: "AI Printable",
		identifierURL: "https://www.aiprintable.com",
		directory: "submission",
		pagename: "About Us - AI Printable",
		category: "E-commerce, Technology",
		coverage: "Worldwide",
		distribution: "Global",
		rating: "General",
		revisitAfter: "7 days",
		subtitle: "Revolutionizing Personalized Gifts with AI",
		target: "all",
		handheldFriendly: "True",
		mobileOptimized: "320",
		date: "2024-03-07",
		searchDate: "2024-03-07",
		DCtitle: "About AI Printable",
	};

	return (
		<div className={cn(styles.aboutuspage)}>
			<SEOHelmet {...seoProps} />
			<SubNavbar disabled="Home /" active="About us" pageName="About us" />
			<div className={cn(styles.container)}>
				<Htag tag="h2" className={styles.headstyle}>
					About us
				</Htag>
				<P size="p1" className={styles.textstyle}>
					Welcome to AI Printable ! We're an innovative e-commerce platform that
					makes personalized gift-giving a breeze. Our AI-powered technology
					generates unique images that you can easily visualize on products like
					canvas prints, t-shirts, and more.
				</P>
				<P size="p1" className={styles.textstyle}>
					Creating custom gifts has never been so simple. With just a few
					clicks, our AI turns your ideas into captivating designs, ready to be
					showcased on high-quality products. No design skills? No problem! We
					handle production and shipping, so all you need to do is enjoy the
					creative process.
				</P>
				<P size="p1" className={styles.textstyle}>
					Join us in revolutionizing the way we express ourselves and celebrate
					life's special moments. Together, we'll create and share one-of-a-kind
					gifts that truly matter.
				</P>
			</div>
		</div>
	);
};

export default AboutUsPage;
