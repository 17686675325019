export const single_order = {
	code: 200,
	result: {
		id: 88663422,
		external_id: null,
		store: 9203642,
		status: "draft",
		error: null,
		errorCode: 0,
		shipping: "STANDARD",
		shipping_service_name: "Flat Rate (3-4 business days after fulfillment)",
		created: 1674713657,
		updated: 1674713657,
		recipient: {
			name: "Mekan Hojaberdiyew",
			company: null,
			address1: "19749 Dearborn St",
			address2: null,
			city: "Chatsworth",
			state_code: "CA",
			state_name: "California",
			country_code: "US",
			country_name: "United States",
			zip: "91311",
			phone: null,
			email: null,
			tax_number: null,
		},
		notes: null,
		incomplete_items: [],
		is_sample: false,
		needs_approval: false,
		not_synced: false,
		has_discontinued_items: false,
		can_change_hold: false,
		costs: {
			currency: "USD",
			subtotal: "90.95",
			discount: "0.00",
			shipping: "7.99",
			digitization: "6.50",
			additional_fee: "0.00",
			fulfillment_fee: "0.00",
			tax: "10.22",
			vat: "0.00",
			total: "115.66",
		},
		dashboard_url: "https://www.printful.com/dashboard?order_id=88663422",
		pricing_breakdown: [
			{
				customer_pays: "116.05",
				printful_price: "115.66",
				profit: "0.39",
				currency_symbol: "USD",
			},
		],
		items: [
			{
				id: 60698250,
				external_id: null,
				variant_id: 8623,
				sync_variant_id: 3714616858,
				external_variant_id: "63ca6312a01955",
				quantity: 1,
				price: "14.95",
				retail_price: "16.61",
				name: "corgi and cat fixing the website, oil painting - Asphalt / S",
				product: {
					variant_id: 8623,
					product_id: 248,
					image:
						"https://files.cdn.printful.com/products/248/8623_1581415258.jpg",
					name: "Bella + Canvas 3480 Unisex Jersey Tank with Tear Away Label (Asphalt / S)",
				},
				files: [
					{
						id: 518406246,
						type: "default",
						hash: "0abb29406b2c9022465ed915754bc020",
						url: "https://dl.kraken.io/api/24/22/b0/a947c3c37a0c1908bd19a755ce/c3bc0dac-bfd3-4ee7-a695-9de31cb481bd.jpg",
						filename: "c3bc0dac-bfd3-4ee7-a695-9de31cb481bd.jpg",
						mime_type: "image/jpeg",
						size: 62709,
						width: 250,
						height: 250,
						dpi: null,
						status: "ok",
						created: 1674208018,
						thumbnail_url:
							"https://files.cdn.printful.com/files/0ab/0abb29406b2c9022465ed915754bc020_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/0ab/0abb29406b2c9022465ed915754bc020_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 518406524,
						type: "preview",
						hash: "2613f806c888af68e0f2e089f2601bcc",
						url: null,
						filename: "mens-staple-tank-top-asphalt-front-63ca635261a55.png",
						mime_type: "image/png",
						size: 664901,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1674208083,
						thumbnail_url:
							"https://files.cdn.printful.com/files/261/2613f806c888af68e0f2e089f2601bcc_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/261/2613f806c888af68e0f2e089f2601bcc_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "lifelike",
						value: false,
					},
				],
				sku: null,
				discontinued: false,
				out_of_stock: false,
				product_template_id: null,
			},
			{
				id: 60698251,
				external_id: null,
				variant_id: 15646,
				sync_variant_id: 3714616865,
				external_variant_id: "63ca6312b79043",
				quantity: 2,
				price: "38.00",
				retail_price: "42.22",
				name: "corgi and cat fixing the website, oil painting - Black Heather / M",
				product: {
					variant_id: 15646,
					product_id: 609,
					image:
						"https://files.cdn.printful.com/products/609/15646_1659960073.jpg",
					name: "Bella + Canvas 3345 (Black Heather / M)",
				},
				files: [
					{
						id: 518406246,
						type: "embroidery_chest_left",
						hash: "0abb29406b2c9022465ed915754bc020",
						url: "https://dl.kraken.io/api/24/22/b0/a947c3c37a0c1908bd19a755ce/c3bc0dac-bfd3-4ee7-a695-9de31cb481bd.jpg",
						filename: "c3bc0dac-bfd3-4ee7-a695-9de31cb481bd.jpg",
						mime_type: "image/jpeg",
						size: 62709,
						width: 250,
						height: 250,
						dpi: null,
						status: "ok",
						created: 1674208018,
						thumbnail_url:
							"https://files.cdn.printful.com/files/0ab/0abb29406b2c9022465ed915754bc020_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/0ab/0abb29406b2c9022465ed915754bc020_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 518406595,
						type: "preview",
						hash: "11f8b4746dd18d953b7945c0c8add3cb",
						url: null,
						filename:
							"unisex-sueded-fleece-sweatshirt-black-heather-zoomed-in-63ca63619a36f.png",
						mime_type: "image/png",
						size: 973996,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1674208099,
						thumbnail_url:
							"https://files.cdn.printful.com/files/11f/11f8b4746dd18d953b7945c0c8add3cb_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/11f/11f8b4746dd18d953b7945c0c8add3cb_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "embroidery_type",
						value: "flat",
					},
					{
						id: "thread_colors",
						value: [],
					},
					{
						id: "text_thread_colors",
						value: [],
					},
					{
						id: "thread_colors_3d",
						value: [],
					},
					{
						id: "thread_colors_chest_left",
						value: [],
					},
					{
						id: "text_thread_colors_chest_left",
						value: [],
					},
					{
						id: "thread_colors_chest_center",
						value: [],
					},
					{
						id: "text_thread_colors_chest_center",
						value: [],
					},
					{
						id: "thread_colors_large_center",
						value: [],
					},
					{
						id: "text_thread_colors_large_center",
						value: [],
					},
				],
				sku: null,
				discontinued: false,
				out_of_stock: false,
				product_template_id: null,
			},
		],
		branding_items: [],
		shipments: [],
		packing_slip: {
			custom_order_id: null,
			email: "john.doe@printful.com",
			phone: "288-888-8888",
			message: "Custom packing slip",
			logo_url: null,
			store_name: null,
		},
		retail_costs: {
			currency: "USD",
			subtotal: "101.05",
			discount: "0.00",
			shipping: "9.00",
			tax: "6.00",
			vat: null,
			total: "116.05",
		},
		gift: null,
	},
	extra: [],
};

export const orders_list = [
	{
		address1: "19749 Dearborn St",
		address2: null,
		created: "Thu, 26 Jan 2023 11:14:27 GMT",
		currency: "USD",
		email: "muhammedjepbarov@gmail.com",
		id: 8833422,
		items_count: "4",
		name: "Mekan Hojaberdiyew",
		phone: null,
		status: "completed",
		total: "199.66",
		updated: "Thu, 26 Jan 2023 11:14:27 GMT",
	},
	{
		address1: "19749 Dearborn St",
		address2: null,
		created: "Thu, 26 Jan 2023 11:14:17 GMT",
		currency: "USD",
		email: "muhammedjepbarov@gmail.com",
		id: 88663422,
		items_count: "2",
		name: "Mekan Hojaberdiyew",
		phone: null,
		status: "draft",
		total: "115.66",
		updated: "Thu, 26 Jan 2023 11:14:17 GMT",
	},
	{
		address1: "19749 Dearborn St",
		address2: null,
		created: "Thu, 26 Jan 2023 11:13:37 GMT",
		currency: "USD",
		email: "muhammedjepbarov@gmail.com",
		id: 83422,
		items_count: "1",
		name: "Mekan Hojaberdiyew",
		phone: null,
		status: "canceled",
		total: "46",
		updated: "Thu, 26 Jan 2023 11:13:37 GMT",
	},
];
