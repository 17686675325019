// !!! merri this mockups for get all resources in printful, but we need only resources in current store I will do this in below...

// route to get all products

// curl --request GET \
//   --url https://api.printful.com/products/ \

export const all_sync_resources = {
	code: 200,
	paging: {
		total: 100,
		offset: 10,
		limit: 100,
	},
	result: [
		{
			id: 296045769,
			external_id: "4235234213",
			name: "T-shirt",
			variants: 10,
			synced: 10,
			thumbnail_url:
				"https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
			is_ignored: true,
		},
		{
			id: 294906717,
			external_id: "4131323",
			name: "Cup",
			variants: 10,
			synced: 10,
			thumbnail_url:
				"https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
			is_ignored: true,
		},
	],
};

export const all_resources = {
	code: 200,
	result: [
		{
			id: 362,
			main_category_id: 24,
			type: "T-SHIRT",
			type_name: "T-Shirt",
			title: "Unisex Organic T-Shirt | Econscious EC1000",
			brand: "Econscious",
			model: "Unisex Organic T-Shirt",
			image:
				"https://files.cdn.printful.com/o/upload/product-catalog-img/3e/3eb6dee7f9d2b332b5a165592db03dbe_l",
			variant_count: 10,
			currency: "USD",
			options: [
				{
					id: "thread_colors_chest_left",
					title: "Left chest thread colors",
					type: "multi_select",
					values: {
						"#FFFFFF": "1801 White",
						"#000000": "1800 Black",
						"#96A1A8": "1718 Grey",
						"#A67843": "1672 Old Gold",
						"#FFCC00": "1951 Gold",
						"#E25C27": "1987 Orange",
						"#CC3366": "1910 Flamingo",
						"#CC3333": "1839 Red",
						"#660000": "1784 Maroon",
						"#333366": "1966 Navy",
						"#005397": "1842 Royal",
						"#3399FF": "1695 Aqua/Teal",
						"#6B5294": "1832 Purple",
						"#01784E": "1751 Kelly Green",
						"#7BA35A": "1848 Kiwi Green",
					},
					additional_price: null,
					additional_price_breakdown: [],
				},
				{
					id: "thread_colors_chest_center",
					title: "Center chest thread colors",
					type: "multi_select",
					values: {
						"#FFFFFF": "1801 White",
						"#000000": "1800 Black",
						"#96A1A8": "1718 Grey",
						"#A67843": "1672 Old Gold",
						"#FFCC00": "1951 Gold",
						"#E25C27": "1987 Orange",
						"#CC3366": "1910 Flamingo",
						"#CC3333": "1839 Red",
						"#660000": "1784 Maroon",
						"#333366": "1966 Navy",
						"#005397": "1842 Royal",
						"#3399FF": "1695 Aqua/Teal",
						"#6B5294": "1832 Purple",
						"#01784E": "1751 Kelly Green",
						"#7BA35A": "1848 Kiwi Green",
					},
					additional_price: null,
					additional_price_breakdown: [],
				},
				{
					id: "thread_colors_large_center",
					title: "Large center thread colors",
					type: "multi_select",
					values: {
						"#FFFFFF": "1801 White",
						"#000000": "1800 Black",
						"#96A1A8": "1718 Grey",
						"#A67843": "1672 Old Gold",
						"#FFCC00": "1951 Gold",
						"#E25C27": "1987 Orange",
						"#CC3366": "1910 Flamingo",
						"#CC3333": "1839 Red",
						"#660000": "1784 Maroon",
						"#333366": "1966 Navy",
						"#005397": "1842 Royal",
						"#3399FF": "1695 Aqua/Teal",
						"#6B5294": "1832 Purple",
						"#01784E": "1751 Kelly Green",
						"#7BA35A": "1848 Kiwi Green",
					},
					additional_price: null,
					additional_price_breakdown: [],
				},
				{
					id: "notes",
					title: "Embroidery notes",
					type: "text",
					values: null,
					additional_price: null,
					additional_price_breakdown: [],
				},
				{
					id: "lifelike",
					title: "Lifelike",
					type: "bool",
					values: null,
					additional_price: null,
					additional_price_breakdown: [],
				},
			],
			dimensions: null,
			is_discontinued: false,
			avg_fulfillment_time: null,
			techniques: [
				{
					key: "EMBROIDERY",
					display_name: "Embroidery",
					is_default: false,
				},
				{
					key: "DTG",
					display_name: "DTG printing",
					is_default: true,
				},
			],
			files: [
				{
					id: "embroidery_chest_left",
					type: "embroidery_chest_left",
					title: "Left chest",
					additional_price: "2.95",
				},
				{
					id: "embroidery_chest_center",
					type: "embroidery_chest_center",
					title: "Center chest",
					additional_price: "2.95",
				},
				{
					id: "embroidery_large_center",
					type: "embroidery_large_center",
					title: "Large center",
					additional_price: "3.95",
				},
				{
					id: "default",
					type: "front",
					title: "Front print",
					additional_price: null,
				},
				{
					id: "back",
					type: "back",
					title: "Back print",
					additional_price: "5.95",
				},
				{
					id: "label_inside",
					type: "label_inside",
					title: "Inside label",
					additional_price: "2.49",
				},
				{
					id: "label_outside",
					type: "label_outside",
					title: "Outside label",
					additional_price: "2.49",
				},
				{
					id: "sleeve_left",
					type: "sleeve_left",
					title: "Left sleeve",
					additional_price: "2.49",
				},
				{
					id: "sleeve_right",
					type: "sleeve_right",
					title: "Right sleeve",
					additional_price: "2.49",
				},
				{
					id: "preview",
					type: "mockup",
					title: "Mockup",
					additional_price: null,
				},
			],
			description:
				"Go natural—order this t-shirt and enjoy the comfort of a truly all-natural organic cotton that is grown and harvested without any fertilizers and pesticides. The high-quality ringspun cotton yarns make sure that the fabric is extra soft and durable. If you’re looking to invest in ethical clothing that is sure to last and has a low environmental impact, look no further than this organic t-shirt!\r\n\r\n• 100% certified organic ring-spun cotton\r\n• Fabric weight: 5.5 oz/yd² (186.5 g/m²)\r\n• Regular fit\r\n• Side-seamed construction\r\n• 1×1 rib neck\r\n• Shoulder seam finished with a self-fabric tape\r\n• Double-needle coverstitched seams\r\n• Low environmental impact\r\n• Blank product sourced from Mexico",
		},
		{
			id: 425,
			main_category_id: 124,
			type: "JEWELRY",
			type_name: "Sterling Silver Hexagon Stud Earrings",
			title: "Sterling Silver Hexagon Stud Earrings",
			brand: null,
			model: "Sterling Silver Hexagon Stud Earrings",
			image:
				"https://files.cdn.printful.com/o/products/425/product_1589368462.jpg",
			variant_count: 2,
			currency: "USD",
			options: [],
			dimensions: null,
			is_discontinued: false,
			avg_fulfillment_time: null,
			techniques: [
				{
					key: "ENGRAVING",
					display_name: "Engraving",
					is_default: true,
				},
			],
			files: [
				{
					id: "ear_left",
					type: "ear_left",
					title: "Left ear",
					additional_price: null,
				},
				{
					id: "ear_right",
					type: "ear_right",
					title: "Right ear",
					additional_price: null,
				},
				{
					id: "preview",
					type: "mockup",
					title: "Mockup",
					additional_price: null,
				},
			],
			description:
				"These hexagon earrings are the perfect choice for a trendy, one-of-a-kind accessory or a heartfelt gift. The earrings are made from sterling silver, and come with a coating. They're packed in a chic, black jewelry box with magnetic closure.\r\n\r\n• Sterling silver (AG-925) plate, stud, and back stopper\r\n• Nickel-free plate and coating\r\n• Earring size: 0.68'' x 0.79'' (17.3 x 20 mm)\r\n• Plate thickness: 0.02'' (0.5 mm)\r\n• Silver back stopper included\r\n• Hallmark & stud on the back of the earring\r\n• Packed in an eco-friendly black leatherette-covered box with a magnetic closure and cloth inlay\r\n• Blank product sourced from Poland",
		},
	],
};

export const all_resources_store = {
	code: 200,
	result: [
		{
			id: 294906717,
			external_id: "639872e52fa207",
			name: "TEST_TEST2",
			variants: 2,
			synced: 2,
			thumbnail_url:
				"https://api.deepai.org/job-view-file/cb339ef2-4e62-456d-be3f-ae36541ec9d4/outputs/output.jpg",
			is_ignored: false,
		},
		{
			id: 294906707,
			external_id: "639872d7d4f9e4",
			name: "T-Shirt",
			variants: 2,
			synced: 2,
			thumbnail_url: "https://picsum.photos/200/300",
			is_ignored: false,
		},
		{
			id: 294906704,
			external_id: "639872d0a52642",
			name: "T-Shirt",
			variants: 2,
			synced: 2,
			thumbnail_url: "https://picsum.photos/200/300",
			is_ignored: false,
		},
	],
	extra: [],
	paging: {
		total: 9,
		offset: 0,
		limit: 20,
	},
};

export const single_resource = {
	code: 200,
	result: {
		product: {
			id: 294906704,
			main_category_id: 24,
			type: "T-SHIRT",
			type_name: "T-Shirt",
			title: "Unisex Staple T-Shirt | Bella + Canvas 3001",
			brand: "Gildan",
			model: "2200 Ultra Cotton Tank Top",
			image:
				"https://files.cdn.printful.com/products/12/product_1550594502.jpg",
			variant_count: 30,
			currency: "EUR",
			files: [],
			options: [],
			is_discontinued: false,
			avg_fulfillment_time: 4.3,
			description: "string",
			techniques: [],
		},
		variants: [
			{
				id: 100,
				product_id: 12,
				name: "Gildan 64000 Unisex Softstyle T-Shirt with Tear Away (Black / 2XL)",
				size: "2XL",
				color: "Black",
				color_code: "#14191e",
				color_code2: "string",
				image: "https://files.cdn.printful.com/products/12/629_1517916489.jpg",
				price: "9.85",
				in_stock: true,
				availability_regions: {},
				availability_status: [],
			},
		],
	},
};
