import React, { useEffect } from "react";

import styles from "./NotFoundPage.module.css";
import cn from "classnames";
import { Button, Htag, P } from "common";

import { ReactComponent as NotFoundCode } from "common/Icons/not_found.svg";
import { ReactComponent as ArrowIcon } from "common/Icons/arrow-left.svg";
import { Link, useNavigate } from "react-router-dom";
import { routeConstants } from "routes";
import ReactGA from "react-ga";

const NotFoundPage: React.FC = () => {
	const navigate = useNavigate();

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	return (
		<div className={cn(styles.maincontainer)}>
			<Htag tag="h3">Page not found :(</Htag>
			<NotFoundCode className={cn(styles.icon)} />
			<P size="p1">
				Sorry, the page you are looking for doesn’t exist or has been moved.
				Here are some helpful links
			</P>
			<div className={cn(styles.buttons)}>
				<Button
					appearance="ghost-white"
					icon={<ArrowIcon />}
					onClick={() => navigate(-1)}
					className={cn(styles.backbutton)}
				>
					Go back
				</Button>
				<Link to={routeConstants.root.route}>
					<Button appearance="black" className={cn(styles.rootbutton)}>
						Take me home
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default NotFoundPage;
