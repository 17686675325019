import { fetchWithCred, handleResponse } from "airedux/helpers";

import { serviceConfig } from "configs";
import { transformMockupProducts } from "./transform_data";
import { mockup_product } from "./mock/mockup_product.mock";

const generateImage = async (
	prompt: string,
	negativePrompt: string | "",
	model: any,
	style: any,
	userId: string | null
) => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({
					data: ["https://foo.bar/foo.img", "https://foo.bar/bar.img"],
				});
			}, 2000);
		});
	}

	const requestOptions = {
		method: "POST",
		body: JSON.stringify({
			prompt: prompt,
			negativePrompt: negativePrompt,
			version: model,
			style: style,
			samples: 4,
			user_id: userId,
		}),
		headers: {
			"Content-Type": "application/json",
		},
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.generate_image_route}`,
		requestOptions
	).then(handleResponse);
};

const getUserImages = async (userId: string) => {
	if (serviceConfig.useMockApi) {
		// Mock response for development or testing purposes
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({
					data: [
						"https://foo.bar/image1.jpg",
						"https://foo.bar/image2.jpg",
						// ... more mock images
					],
				});
			}, 1000);
		});
	}

	const requestOptions = {
		method: "GET",
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}/api/v1/user-images/${userId}`, // Update this URL to your actual endpoint
		requestOptions
	).then(handleResponse);
};

const randomGenerateImage = async () => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve) => {
			setTimeout(() => {
				// MIKE never version of response with separated modules of gen and check
				resolve({
					data: [
						{
							data: {
								mockup_task_key: "gt-452606807",
								mockup_store_product_id: 296045769,
								first_product_mockuped: 0,
							},
							status: 1,
						},
						{
							data: {
								mockup_task_key: "gt-1111111",
								mockup_store_product_id: 11111,
								first_product_mockuped: 0,
							},
							status: 1,
						},
					],
				});
				//resolve({ data: [mockup_product] });
			}, 2000);
		});
	}
	const requestOptions = {
		method: "GET",
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.random_generate_image_route}`,
		requestOptions
	).then(handleResponse);
};

const enchance_and_resize = async (
	prompt: string,
	image_url: string,
	id: any
) => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({
					data: [
						{
							data: {
								mockup_task_key: "gt-452606807",
								mockup_store_product_id: 296045769,
								first_product_mockuped: 0,
							},
							status: 1,
						},
						{
							data: {
								mockup_task_key: "gt-1111111",
								mockup_store_product_id: 11111,
								first_product_mockuped: 0,
							},
							status: 1,
						},
					],
				});
			}, 2000);
		});
	}
	const requestOptions = {
		method: "POST",
		body: JSON.stringify({
			prompt: prompt,
			url: image_url,
			generated_image_id: id,
		}),
		headers: {
			"Content-Type": "application/json",
		},
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.enchance_resize_route}`,
		requestOptions
	)
		.then((response) => response.json())
		.then((data) => {
			return data;
		});
};

const checkProducts = async (search_params: string) => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({
					found_incomplete: 1,
					variant_images: [
						{
							sync_product_id: 296045769,
							real_variant_id: 8623,
							sync_variant_id: 3678794710,
							preview_url: "https://foo.bar.img",
						},
						{
							sync_product_id: 296045769,
							real_variant_id: 8623,
							sync_variant_id: 3678794710,
							preview_url: "https://foo.baz.img",
						},
						{
							sync_product_id: 296045769,
							real_variant_id: 8623,
							sync_variant_id: 3678794710,
							preview_url: "https://one.two.img",
						},
					],
				});
				//resolve(mockup_product);
			}, 2000);
		});
	}

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.check_generate_product_route}${search_params}`
	).then(handleResponse);
};

const remakeImage = async (
	prompt: string,
	negativePrompt: string,
	style: string,
	model: any,
	userId: any,
	image: string
): Promise<any> => {
	try {
		const response = await fetch(
			`${serviceConfig.apiHost}${serviceConfig.routes.remake}`,
			{
				method: "POST",
				body: JSON.stringify({
					prompt: prompt,
					negativePrompt: negativePrompt,
					version: model,
					style: style,
					user_id: userId,
					image: image,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("There has been a problem with your fetch operation:", error);
		throw error;
	}
};

export const imageService = {
	generateImage,
	randomGenerateImage,
	getUserImages,
	checkProducts,
	enchance_and_resize,
	remakeImage,
};
