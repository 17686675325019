import { wishlistConstants } from "airedux/constants/wishlist.constants";

const initialState: any = {
	loading: false,
	images: [],
	products: [],
	error: null,
};

const wishlist = (state = initialState, action: any) => {
	switch (action.type) {
		case wishlistConstants.FETCH_WISHLIST_REQUEST:
		case wishlistConstants.ADD_WISHLIST_REQUEST:
		case wishlistConstants.DELETE_WISHLIST_REQUEST:
			return {
				...state,
				loading: true,
			};
		case wishlistConstants.FETCH_WISHLIST_SUCCESS: {
			const { images, products } = action.payload.data; // Destructuring directly from payload.data
			return {
				...state,
				loading: false,
				images: images || [], // Fallback to empty array if not provided
				products: products || [], // Fallback to empty array if not provided
				error: null,
			};
		}

		case wishlistConstants.ADD_WISHLIST_SUCCESS: {
			const wishData = action.payload.data; // Assuming action.payload contains { type, data }
			if (wishData.type === "image") {
				return {
					...state,
					images: [...state.images, wishData],
					loading: false,
				};
			} else if (wishData.type === "product") {
				return {
					...state,
					products: [...state.products, wishData],
					loading: false,
				};
			}
			return state;
		}
		case wishlistConstants.DELETE_WISHLIST_SUCCESS: {
			// Assuming payload for DELETE_WISHLIST_SUCCESS contains { id, type }
			const { id, type } = action.payload;
			if (type === "image") {
				return {
					...state,
					images: state.images.filter((item: any) => item._id !== id),
					loading: false,
				};
			} else if (type === "product") {
				return {
					...state,
					products: state.products.filter((item: any) => item._id !== id),
					loading: false,
				};
			}
			return state;
		}
		case wishlistConstants.FETCH_WISHLIST_FAILURE:
		case wishlistConstants.ADD_WISHLIST_FAILURE:
		case wishlistConstants.DELETE_WISHLIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default wishlist;
