import { route_prefix } from "configs";

export const routeConstants = {
	root: {
		route: `${route_prefix}/`,
		name: "Home",
	},
	generate: {
		route: `${route_prefix}/generate`,
		name: "Generate",
	},
	generated_product: {
		route: `${route_prefix}/generated-product`,
		name: "Generated Product",
	},
	client_product_page: {
		route: `${route_prefix}/client/product-page`,
		name: "Client Product Page",
	},
	admin_product_page: {
		route: `${route_prefix}/admin/product-page`,
		name: "Admin Product Page",
	},
	client_product_details: {
		route: `${route_prefix}/client/product-details`,
		name: "Client Product Page",
	},
	shop_product_details: {
		route: `${route_prefix}/shop/product-details`,
		name: "Shop Product Details",
	},
	design: {
		route: `${route_prefix}/design`,
		name: "Design",
	},
	checkout: {
		route: `${route_prefix}/checkout`,
		name: "Checkout",
	},
	shippingaddress: {
		route: `${route_prefix}/shipping-address`,
		name: "Shipping Address",
	},
	paymentcard: {
		route: `${route_prefix}/payment-card`,
		name: "Payment Card",
	},
	ourProducts: {
		route: `${route_prefix}/our-products`,
		name: "Our Products",
	},
	shop: {
		route: `${route_prefix}/shop`,
		name: "Shop",
	},
	blog: {
		route: `${route_prefix}/blogs`,
		name: "Blogs",
	},
	affiliate: {
		route: `${route_prefix}/affiliate`,
		name: "Affiliate",
	},
	blog_details: {
		route: `${route_prefix}/blog`,
		name: "Blog",
	},
	userGeneratedImages: {
		route: `${route_prefix}/user-generated-images`,
		name: "User Generated Images",
	},
	category_filter: {
		route: `${route_prefix}/category-filter`,
		name: "Category Filter",
	},
	generated_image_wishlist: {
		route: `${route_prefix}/image-wishlist`,
		name: "Generated Image Wishlist",
	},
	generated_product_wishlist: {
		route: `${route_prefix}/product-wishlist`,
		name: "Generated Product Wishlist",
	},
	contact_us: {
		route: `${route_prefix}/contact-us`,
		name: "Contact us",
	},
	about_us: {
		route: `${route_prefix}/about-us`,
		name: "About us",
	},
	login: {
		route: `${route_prefix}/login/`,
		name: "Login",
	},
	register: {
		route: `${route_prefix}/register/`,
		name: "Register",
	},
	logout: {
		route: `${route_prefix}/logout/`,
		name: "Logout",
	},
	my_account: {
		route: `${route_prefix}/account/`,
		name: "Profile",
	},
	my_orders: {
		route: `${route_prefix}/orders/`,
		name: "Order History",
	},
	my_address: {
		route: `${route_prefix}/address/`,
		name: "Addresses",
	},
	my_payment_cards: {
		route: `${route_prefix}/payment_cards/`,
		name: "Payment cards",
	},
	checkout_result: {
		route: `${route_prefix}/checkout-result/`,
		name: "Checkout Result",
	},
	privacy_statement: {
		route: `${route_prefix}/privacy-statement/`,
		name: "Privacy Statement",
	},
	terms_conditions: {
		route: `${route_prefix}/terms-conditions/`,
		name: "Terms & Conditions",
	},
	help_centre: {
		route: `${route_prefix}/help-centre/`,
		name: "Help Centre",
	},
	redesign: {
		route: `${route_prefix}/redesign/`,
		name: "ReDesign",
	},
	feature: {
		route: `${route_prefix}/feature/`,
		name: "Feature",
	},
};
