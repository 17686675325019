import React, { useState } from "react";

import AuthVerification from "components/Auth/AuthVerification";
import AuthForget from "components/Auth/AuthForget";
import AuthNewPassword from "components/Auth/AuthNewPassword";

const ForgetModal: React.FC = () => {
	const [stage, set_stage] = useState(1);
	const [validationData, set_validationData] = useState({
		authMethod: "",
		credentials: "",
		validator_phone_number: "",
		responseMessage: "",
		registerToken: "",
	});

	return (
		<>
			{stage === 1 && (
				<AuthForget
					onStageChange={(stage: number) => set_stage(stage)}
					handleValidationData={(data: any) => set_validationData(data)}
				/>
			)}
			{stage === 2 && (
				<AuthVerification
					onStageChange={(stage: number) => set_stage(stage)}
					validationData={validationData}
					handleValidationData={(data: any) => set_validationData(data)}
				/>
			)}
			{stage === 3 && (
				<AuthNewPassword
					validationData={validationData}
					handleValidationData={(data: any) => set_validationData(data)}
				/>
			)}
		</>
	);
};

export default ForgetModal;
