import { fetchWithCred, handleResponse } from "airedux/helpers";
import { serviceConfig } from "configs";

const post_subscribe = async (payload: any) => {
	const requestOptions = {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json",
		},
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.subscribe}`,
		requestOptions
	)
		.then(handleResponse)
		.then((response: any) => ({
			message: response.message,
			status: response.status,
			...response,
		}));
};

const post_contact_us = async (payload: any) => {
	const requestOptions = {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json",
		},
	};

	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.contact}`,
		requestOptions
	)
		.then(handleResponse)
		.then((response: any) => ({
			message: response.message,
			status: response.status,
			...response,
		}));
};

export const otherServices = {
	post_subscribe,
	post_contact_us,
};
