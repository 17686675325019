import React from "react";

import { PaymentCardItemProps } from "./PaymentCardItem.props";
import styles from "./PaymentCardItem.module.css";
import cn from "classnames";
import { P } from "common/P/P";

import { ReactComponent as MasterCardIcon } from "common/Icons/Logo/Master.svg";
import { ReactComponent as VisaIcon } from "common/Icons/Logo/Visa.svg";

export const PaymentCardItem = ({
	className,
	mastercard,
	active,
	data,
	onRemove,
	...props
}: PaymentCardItemProps): JSX.Element => {
	return (
		data && (
			<div
				className={cn(
					active ? styles.active : styles.paymentcarditem,
					className
				)}
				{...props}
			>
				<div className={cn(styles.cardinfo)}>
					{mastercard ? (
						<>
							<MasterCardIcon />
							<P size="b2medium">
								****
								{data.number &&
									data.number.slice(
										data.number.length / 1.5,
										data.number.length
									)}
							</P>
						</>
					) : (
						<>
							<VisaIcon />
							<P size="b2medium">
								****
								{data.number &&
									data.number.slice(
										data.number.length / 1.5,
										data.number.length
									)}
							</P>
						</>
					)}
				</div>
				<div className={cn(styles.buttons)}>
					<P size="b1underline">Edit</P>
					<P size="b1underline" onClick={() => onRemove(data.id)}>
						Remove
					</P>
				</div>
			</div>
		)
	);
};
