import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import styles from "./Navbar.module.css";
import cn from "classnames";

import { P } from "common/P/P";

import { ReactComponent as MailIcon } from "common/Icons/mail.svg";
import { ReactComponent as HelpIcon } from "common/Icons/help-circle.svg";
import { ReactComponent as Logo } from "common/Icons/Logo/ai_printable_mobile.svg";
import { ReactComponent as HamburgerIcon } from "common/Icons/menu.svg";
import { ReactComponent as CancelIcon } from "common/Icons/x.svg";

import { routeConstants } from "routes";

const HamburgerContent = ({ setOpen, handleNavigation }: any): JSX.Element => {
	const handleClick = (route: any) => {
		handleNavigation(route);

		setOpen(false);
	};

	const recipient = "info@aiprintable.com";
	const mailtoLink = `mailto:${recipient}`;

	return (
		<div className={cn(styles.hamburgercontainer)}>
			<ul>
				<li onClick={() => handleClick(routeConstants.feature.route)}>
					<P size="b1medium">Create</P>
				</li>

				<li onClick={() => handleClick(routeConstants.design.route)}>
					<P size="b1medium">Designs</P>
				</li>

				<li onClick={() => handleClick(routeConstants.ourProducts.route)}>
					<P size="b1medium">Products</P>
				</li>

				<li onClick={() => handleClick(routeConstants.shop.route)}>
					<P size="b1medium">Shop</P>
				</li>
				{/* 
				<li onClick={() => handleClick(routeConstants.blog.route)}>
					<P size="b1medium">Blogs</P>
				</li> */}

				<a
					href={mailtoLink}
					style={{
						textDecoration: "none",
						color: "inherit",
						cursor: "pointer",
					}}
					target="_blank"
				>
					<li className={cn(styles.withicon)}>
						<MailIcon />
						<P size="b1medium">{recipient}</P>
					</li>
				</a>
				<li
					className={cn(styles.withicon)}
					onClick={() => handleClick(routeConstants.help_centre.route)}
				>
					<HelpIcon />
					<P size="b1medium">Help</P>
				</li>
				<li onClick={() => handleClick(routeConstants.about_us.route)}>
					<P size="b1medium">About us</P>
				</li>
			</ul>
		</div>
	);
};

const HamburgerMenu = ({ handleNavigation }: any): JSX.Element => {
	const [open, setOpen] = useState(false);

	const toggleBurger = () => {
		setOpen(!open);
	};

	return (
		<nav>
			<ClickAwayListener
				onClickAway={() => {
					if (open === true) {
						setOpen(false);
					}
				}}
			>
				<>
					<div className={cn(styles.hamburgerfirstrow)}>
						<div onClick={() => handleNavigation(routeConstants.root.route)}>
							<Logo />
						</div>
						{open === true ? (
							<CancelIcon onClick={toggleBurger} />
						) : (
							<HamburgerIcon onClick={toggleBurger} />
						)}
					</div>
					{open === true && (
						<HamburgerContent
							setOpen={setOpen}
							handleNavigation={handleNavigation}
						/>
					)}
				</>
			</ClickAwayListener>
		</nav>
	);
};

export default HamburgerMenu;
