export const mockup_product = {
	found_incomplete: 0,
	code: 200,
	result: {
		sync_product: {
			id: 296045769,
			external_id: "63ae5a6b5ab4b5",
			name: "Company Logo2",
			variants: 16,
			synced: 16,
			thumbnail_url:
				"https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
			is_ignored: false,
		},
		sync_variants: [
			{
				id: 3678794710,
				external_id: "63ae5a6b5b72f6",
				sync_product_id: 296045769,
				name: "Company Logo2 - Asphalt / S",
				synced: true,
				variant_id: 8623,
				main_category_id: 23,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 8623,
					product_id: 248,
					image:
						"https://files.cdn.printful.com/products/248/8623_1581415258.jpg",
					name: "Bella + Canvas 3480 Unisex Jersey Tank with Tear Away Label (Asphalt / S)",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653503,
						type: "preview",
						hash: "bb3e0ef5a986bebafc6d4548a8a74b18",
						url: null,
						filename: "mens-staple-tank-top-asphalt-front-63ae5aab027ee.png",
						mime_type: "image/png",
						size: 635132,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370860,
						thumbnail_url:
							"https://files.cdn.printful.com/files/bb3/bb3e0ef5a986bebafc6d4548a8a74b18_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/bb3/bb3e0ef5a986bebafc6d4548a8a74b18_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "lifelike",
						value: false,
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794711,
				external_id: "63ae5a6b607f58",
				sync_product_id: 296045769,
				name: "Company Logo2 - White / 10",
				synced: true,
				variant_id: 9704,
				main_category_id: 13,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 9704,
					product_id: 323,
					image:
						"https://files.cdn.printful.com/products/323/9704_1540122780.jpg",
					name: "All-Over Print Youth Leggings (Size 10)",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653504,
						type: "preview",
						hash: "80bade30a6db6b51631b860b39ddcd65",
						url: null,
						filename:
							"all-over-print-youth-leggings-white-front-63ae5aaaec890.png",
						mime_type: "image/png",
						size: 347239,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370860,
						thumbnail_url:
							"https://files.cdn.printful.com/files/80b/80bade30a6db6b51631b860b39ddcd65_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/80b/80bade30a6db6b51631b860b39ddcd65_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "stitch_color",
						value: "white",
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794712,
				external_id: "63ae5a6b61b4e1",
				sync_product_id: 296045769,
				name: "Company Logo2 - White / L",
				synced: true,
				variant_id: 7791,
				main_category_id: 11,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 7791,
					product_id: 198,
					image:
						"https://files.cdn.printful.com/products/198/7791_1590478604.jpg",
					name: "All-Over Print Dress (White / L)",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653507,
						type: "preview",
						hash: "8a827b7816b32808b11f650e8a733711",
						url: null,
						filename: "all-over-print-dress-white-front-63ae5aababc4f.png",
						mime_type: "image/png",
						size: 420161,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370861,
						thumbnail_url:
							"https://files.cdn.printful.com/files/8a8/8a827b7816b32808b11f650e8a733711_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/8a8/8a827b7816b32808b11f650e8a733711_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "stitch_color",
						value: "white",
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794713,
				external_id: "63ae5a6b62ccb3",
				sync_product_id: 296045769,
				name: "Company Logo2 - White / M",
				synced: true,
				variant_id: 10871,
				main_category_id: 28,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 10871,
					product_id: 388,
					image:
						"https://files.cdn.printful.com/products/388/10871_1582103273.jpg",
					name: "All-Over Print Unisex Hoodie (M)",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653516,
						type: "preview",
						hash: "5bf3f61294e43f03d5c7c50fc1dac9ce",
						url: null,
						filename:
							"all-over-print-unisex-hoodie-white-front-63ae5aac3eae5.png",
						mime_type: "image/png",
						size: 436123,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370862,
						thumbnail_url:
							"https://files.cdn.printful.com/files/5bf/5bf3f61294e43f03d5c7c50fc1dac9ce_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/5bf/5bf3f61294e43f03d5c7c50fc1dac9ce_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "stitch_color",
						value: "white",
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794714,
				external_id: "63ae5a6b6401a9",
				sync_product_id: 296045769,
				name: "Company Logo2 - White / M",
				synced: true,
				variant_id: 16032,
				main_category_id: 217,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 16032,
					product_id: 630,
					image:
						"https://files.cdn.printful.com/products/630/16032_1660914152.jpg",
					name: "All-Over Print Bandana (White / M)",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653534,
						type: "preview",
						hash: "ca69c2e86f4c63faf8e9c696216e20e0",
						url: null,
						filename: "all-over-print-bandana-white-m-front-63ae5aac6f570.png",
						mime_type: "image/png",
						size: 727566,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370863,
						thumbnail_url:
							"https://files.cdn.printful.com/files/ca6/ca69c2e86f4c63faf8e9c696216e20e0_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/ca6/ca69c2e86f4c63faf8e9c696216e20e0_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "stitch_color",
						value: "white",
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794715,
				external_id: "63ae5a6b6520f5",
				sync_product_id: 296045769,
				name: "Company Logo2 - Baby Pink / M",
				synced: true,
				variant_id: 10490,
				main_category_id: 105,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 10490,
					product_id: 370,
					image:
						"https://files.cdn.printful.com/products/370/10491_1620375498.jpg",
					name: "All We Do Is JHY001 Kids Hoodie (Baby Pink / M)",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653505,
						type: "preview",
						hash: "16577a7e6a4e930d8f562b25e1ef2c0c",
						url: null,
						filename: "kids-hoodie-baby-pink-front-63ae5aab72efc.png",
						mime_type: "image/png",
						size: 492504,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370860,
						thumbnail_url:
							"https://files.cdn.printful.com/files/165/16577a7e6a4e930d8f562b25e1ef2c0c_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/165/16577a7e6a4e930d8f562b25e1ef2c0c_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "embroidery_type",
						value: "flat",
					},
					{
						id: "thread_colors",
						value: [],
					},
					{
						id: "text_thread_colors",
						value: [],
					},
					{
						id: "thread_colors_3d",
						value: [],
					},
					{
						id: "thread_colors_chest_center",
						value: [],
					},
					{
						id: "text_thread_colors_chest_center",
						value: [],
					},
					{
						id: "thread_colors_chest_left",
						value: [],
					},
					{
						id: "text_thread_colors_chest_left",
						value: [],
					},
					{
						id: "lifelike",
						value: false,
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794716,
				external_id: "63ae5a6b669103",
				sync_product_id: 296045769,
				name: "Company Logo2 - Black / M",
				synced: true,
				variant_id: 10287,
				main_category_id: 24,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 10287,
					product_id: 362,
					image:
						"https://files.cdn.printful.com/products/362/10286_1554804731.jpg",
					name: " Unisex Organic T-shirt (Black / M)",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653506,
						type: "preview",
						hash: "4c8e20b5a980bb3a7ea335de8334dc39",
						url: null,
						filename: "unisex-organic-t-shirt-black-front-63ae5aaba4fd9.png",
						mime_type: "image/png",
						size: 470740,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370861,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4c8/4c8e20b5a980bb3a7ea335de8334dc39_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4c8/4c8e20b5a980bb3a7ea335de8334dc39_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "embroidery_type",
						value: "flat",
					},
					{
						id: "thread_colors",
						value: [],
					},
					{
						id: "text_thread_colors",
						value: [],
					},
					{
						id: "thread_colors_3d",
						value: [],
					},
					{
						id: "thread_colors_chest_left",
						value: [],
					},
					{
						id: "text_thread_colors_chest_left",
						value: [],
					},
					{
						id: "thread_colors_chest_center",
						value: [],
					},
					{
						id: "text_thread_colors_chest_center",
						value: [],
					},
					{
						id: "thread_colors_large_center",
						value: [],
					},
					{
						id: "text_thread_colors_large_center",
						value: [],
					},
					{
						id: "lifelike",
						value: false,
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794717,
				external_id: "63ae5a6b6806b8",
				sync_product_id: 296045769,
				name: "Company Logo2 - Black Heather / M",
				synced: true,
				variant_id: 15646,
				main_category_id: 7,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 15646,
					product_id: 609,
					image:
						"https://files.cdn.printful.com/products/609/15646_1659960073.jpg",
					name: "Bella + Canvas 3345 (Black Heather / M)",
				},
				files: [
					{
						id: 509653287,
						type: "embroidery_chest_left",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653545,
						type: "preview",
						hash: "24389ea4fd7781d1a6bfcbc35671d49a",
						url: null,
						filename:
							"unisex-sueded-fleece-sweatshirt-black-heather-zoomed-in-63ae5ab12b2c3.png",
						mime_type: "image/png",
						size: 945506,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370867,
						thumbnail_url:
							"https://files.cdn.printful.com/files/243/24389ea4fd7781d1a6bfcbc35671d49a_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/243/24389ea4fd7781d1a6bfcbc35671d49a_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "embroidery_type",
						value: "flat",
					},
					{
						id: "thread_colors",
						value: [],
					},
					{
						id: "text_thread_colors",
						value: [],
					},
					{
						id: "thread_colors_3d",
						value: [],
					},
					{
						id: "thread_colors_chest_left",
						value: [],
					},
					{
						id: "text_thread_colors_chest_left",
						value: [],
					},
					{
						id: "thread_colors_chest_center",
						value: [],
					},
					{
						id: "text_thread_colors_chest_center",
						value: [],
					},
					{
						id: "thread_colors_large_center",
						value: [],
					},
					{
						id: "text_thread_colors_large_center",
						value: [],
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794718,
				external_id: "63ae5a6b698061",
				sync_product_id: 296045769,
				name: "Company Logo2 - White / M",
				synced: true,
				variant_id: 11949,
				main_category_id: 45,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 11949,
					product_id: 458,
					image:
						"https://files.cdn.printful.com/products/458/11949_1608131476.jpg",
					name: "All-Over Print Beanie (M)",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653533,
						type: "preview",
						hash: "a7a4c52b8af0fc766c18f61cc8056124",
						url: null,
						filename: "all-over-print-beanie-white-front-63ae5aaca083c.png",
						mime_type: "image/png",
						size: 809295,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370862,
						thumbnail_url:
							"https://files.cdn.printful.com/files/a7a/a7a4c52b8af0fc766c18f61cc8056124_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/a7a/a7a4c52b8af0fc766c18f61cc8056124_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "stitch_color",
						value: "white",
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794719,
				external_id: "63ae5a6b6ab1e5",
				sync_product_id: 296045769,
				name: "Company Logo2 - White / 15″×17″",
				synced: true,
				variant_id: 8894,
				main_category_id: 49,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 8894,
					product_id: 262,
					image:
						"https://files.cdn.printful.com/products/262/8894_1556096073.jpg",
					name: "All-Over Drawstring Bag (15″×17″)",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653508,
						type: "preview",
						hash: "506fb27f79db5a7c3e929ca16959fff8",
						url: null,
						filename:
							"all-over-print-drawstring-bag-white-mockup-63ae5aac2668d.png",
						mime_type: "image/png",
						size: 774210,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370861,
						thumbnail_url:
							"https://files.cdn.printful.com/files/506/506fb27f79db5a7c3e929ca16959fff8_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/506/506fb27f79db5a7c3e929ca16959fff8_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "stitch_color",
						value: "black",
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794720,
				external_id: "63ae5a6b6bd7a9",
				sync_product_id: 296045769,
				name: "Company Logo2 - White / One Size",
				synced: true,
				variant_id: 12584,
				main_category_id: 21,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 12584,
					product_id: 490,
					image:
						"https://files.cdn.printful.com/products/490/12584_1621257054.jpg",
					name: "All-Over Print Flag (White / One Size)",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653511,
						type: "preview",
						hash: "c24daa3be2b9c3210e2f00d82b63b3d1",
						url: null,
						filename: "all-over-print-flag-white-front-63ae5aabad1ca.png",
						mime_type: "image/png",
						size: 633015,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370861,
						thumbnail_url:
							"https://files.cdn.printful.com/files/c24/c24daa3be2b9c3210e2f00d82b63b3d1_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/c24/c24daa3be2b9c3210e2f00d82b63b3d1_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "stitch_color",
						value: "white",
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794721,
				external_id: "63ae5a6b6d0835",
				sync_product_id: 296045769,
				name: "Company Logo2 - Black / 50×100 cm",
				synced: true,
				variant_id: 11800,
				main_category_id: 22,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 11800,
					product_id: 441,
					image:
						"https://files.cdn.printful.com/products/441/11800_1603288347.jpg",
					name: "ARTG (50×100 cm / Black)",
				},
				files: [
					{
						id: 509653287,
						type: "embroidery_corner_right",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653548,
						type: "preview",
						hash: "90c3e7257742740902f3546ebe778d07",
						url: null,
						filename:
							"turkish-cotton-towel-black-50x100-cm-zoomed-in-63ae5ab213948.png",
						mime_type: "image/png",
						size: 606605,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370868,
						thumbnail_url:
							"https://files.cdn.printful.com/files/90c/90c3e7257742740902f3546ebe778d07_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/90c/90c3e7257742740902f3546ebe778d07_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "embroidery_type",
						value: "flat",
					},
					{
						id: "thread_colors",
						value: [],
					},
					{
						id: "text_thread_colors",
						value: [],
					},
					{
						id: "thread_colors_3d",
						value: [],
					},
					{
						id: "thread_colors_corner_right",
						value: [],
					},
					{
						id: "text_thread_colors_corner_right",
						value: [],
					},
					{
						id: "thread_colors_large_center",
						value: [],
					},
					{
						id: "text_thread_colors_large_center",
						value: [],
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794722,
				external_id: "63ae5a6b6e19f9",
				sync_product_id: 296045769,
				name: "Company Logo2 - 10″×10″",
				synced: true,
				variant_id: 6239,
				main_category_id: 55,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 6239,
					product_id: 1,
					image:
						"https://files.cdn.printful.com/products/1/6239_1527678729.jpg",
					name: "Enhanced Matte Paper Poster 10″×10″",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653523,
						type: "preview",
						hash: "0d829a18ffd0ffe689c5fcf568f9fbc5",
						url: null,
						filename:
							"enhanced-matte-paper-poster-(in)-10x10-front-63ae5aacee2e6.png",
						mime_type: "image/png",
						size: 388067,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370862,
						thumbnail_url:
							"https://files.cdn.printful.com/files/0d8/0d829a18ffd0ffe689c5fcf568f9fbc5_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/0d8/0d829a18ffd0ffe689c5fcf568f9fbc5_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [],
				is_ignored: false,
			},
			{
				id: 3678794723,
				external_id: "63ae5a6b6f31d5",
				sync_product_id: 296045769,
				name: "Company Logo2 - 12″×12″",
				synced: true,
				variant_id: 823,
				main_category_id: 57,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 823,
					product_id: 3,
					image: "https://files.cdn.printful.com/products/3/823_1581583554.jpg",
					name: "Canvas 12″×12″",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653524,
						type: "preview",
						hash: "79e30e34899844ae846987fc193569ef",
						url: null,
						filename: "canvas-(in)-12x12-wall-63ae5aad3e8a2.png",
						mime_type: "image/png",
						size: 590211,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370862,
						thumbnail_url:
							"https://files.cdn.printful.com/files/79e/79e30e34899844ae846987fc193569ef_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/79e/79e30e34899844ae846987fc193569ef_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [],
				is_ignored: false,
			},
			{
				id: 3678794727,
				external_id: "63ae5a6b706553",
				sync_product_id: 296045769,
				name: "Company Logo2 - White / One Size",
				synced: true,
				variant_id: 9063,
				main_category_id: 16,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 9063,
					product_id: 279,
					image:
						"https://files.cdn.printful.com/products/279/9063_1534847488.jpg",
					name: "All-Over Print Backpack",
				},
				files: [
					{
						id: 509653287,
						type: "default",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653531,
						type: "preview",
						hash: "2d92eff000f5f36ff344c52ec0336acf",
						url: null,
						filename: "all-over-print-backpack-white-front-63ae5aac91a0d.png",
						mime_type: "image/png",
						size: 689521,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370862,
						thumbnail_url:
							"https://files.cdn.printful.com/files/2d9/2d92eff000f5f36ff344c52ec0336acf_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/2d9/2d92eff000f5f36ff344c52ec0336acf_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "stitch_color",
						value: "black",
					},
				],
				is_ignored: false,
			},
			{
				id: 3678794732,
				external_id: "63ae5a6b71a2f1",
				sync_product_id: 296045769,
				name: "Company Logo2 - Black / M",
				synced: true,
				variant_id: 9899,
				main_category_id: 85,
				warehouse_product_variant_id: null,
				retail_price: null,
				sku: null,
				currency: "USD",
				product: {
					variant_id: 9899,
					product_id: 340,
					image:
						"https://files.cdn.printful.com/products/340/9899_1590149381.jpg",
					name: "Port Authority K500 Embroidered Polo Shirt (Black / M)",
				},
				files: [
					{
						id: 509653287,
						type: "embroidery_chest_left",
						hash: "4cab22f94bfa237b5ba414e8db2aad86",
						url: "https://api.deepai.org/job-view-file/f0c1b308-ed68-4ad5-867a-e61b0f8512f2/outputs/output.jpg",
						filename: "output.jpg",
						mime_type: "image/jpeg",
						size: 2519617,
						width: 3200,
						height: 3200,
						dpi: null,
						status: "ok",
						created: 1672370795,
						thumbnail_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/4ca/4cab22f94bfa237b5ba414e8db2aad86_preview.png",
						visible: true,
						is_temporary: false,
					},
					{
						id: 509653550,
						type: "preview",
						hash: "e94ac296590f0df373cbc769646ff4c3",
						url: null,
						filename: "premium-polo-shirt-black-zoomed-in-63ae5ab26a39a.png",
						mime_type: "image/png",
						size: 627993,
						width: 1000,
						height: 1000,
						dpi: null,
						status: "ok",
						created: 1672370868,
						thumbnail_url:
							"https://files.cdn.printful.com/files/e94/e94ac296590f0df373cbc769646ff4c3_thumb.png",
						preview_url:
							"https://files.cdn.printful.com/files/e94/e94ac296590f0df373cbc769646ff4c3_preview.png",
						visible: false,
						is_temporary: false,
					},
				],
				options: [
					{
						id: "embroidery_type",
						value: "flat",
					},
					{
						id: "thread_colors",
						value: [],
					},
					{
						id: "text_thread_colors",
						value: [],
					},
					{
						id: "thread_colors_3d",
						value: [],
					},
					{
						id: "thread_colors_chest_left",
						value: [],
					},
					{
						id: "text_thread_colors_chest_left",
						value: [],
					},
					{
						id: "thread_colors_sleeve_left_top",
						value: [],
					},
					{
						id: "text_thread_colors_sleeve_left_top",
						value: [],
					},
					{
						id: "thread_colors_sleeve_right_top",
						value: [],
					},
					{
						id: "text_thread_colors_sleeve_right_top",
						value: [],
					},
					{
						id: "thread_colors_large_center",
						value: [],
					},
					{
						id: "text_thread_colors_large_center",
						value: [],
					},
				],
				is_ignored: false,
			},
		],
	},
	extra: [],
};
