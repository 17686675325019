import React, { useState } from "react";

import { ProductSliderProps } from "./ProductSlider.props";
import styles from "./ProductSlider.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import cn from "classnames";

export const ProductSlider = ({
	images,
	className,
	...props
}: ProductSliderProps): JSX.Element => {
	const [currentSlide, setCurrentSlide] = useState<number>(0);

	const sliderSettings = {
		dots: false,
		arrows: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 0,
		beforeChange: (current: number, next: number) => setCurrentSlide(next),
	};
	return (
		<Slider {...sliderSettings}>
			{images.map((image, index) => (
				<div key={index} className={cn(styles.imagediv)}>
					<img
						className={`slider-image ${
							index === currentSlide ? "active" : ""
						} ${cn(styles.image)}`}
						src={image}
						alt={`Product Image ${index + 1}`}
						loading="lazy"
					/>
				</div>
			))}
		</Slider>
	);
};
