import React from "react";

import { BlogItemProps } from "./BlogItem.props";
import styles from "./BlogItem.module.css";
import cn from "classnames";
import { P } from "common/P/P";
import { Htag } from "common/Htag/Htag";

export const BlogItem = ({
	className,
	src,
	alt,
	text,
	date,
	...props
}: BlogItemProps): JSX.Element => {
	return (
		<div className={cn(styles.container, className)} {...props}>
			<img src={src} alt={alt} className={cn(styles.image)} loading="lazy" />
			<Htag tag="s1">{text}</Htag>
			<P size="b1medium" className={cn(styles.date)}>
				{date}
			</P>
		</div>
	);
};
