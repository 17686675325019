import styles from "./Cart.module.css";
import cn from "classnames";

import CartRow from "./CartRow";
import { getVariantCartItems } from "airedux/selectors";
import { connect } from "react-redux";

const CartBody = ({
	items,
	invoicePage,
	checkoutPage,
	orderPage,
}: any): JSX.Element => {
	console.log("ITEMS", items);
	return (
		<div className={cn(styles.cartbodydiv)}>
			{items &&
				items.map((item: any, idx: number) => (
					<CartRow
						invoicePage={invoicePage}
						checkoutPage={checkoutPage}
						orderPage={orderPage}
						discount={true}
						key={idx}
						item={item}
					/>
				))}
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		items: getVariantCartItems(state),
	};
};

export default connect(mapStateToProps)(CartBody);
