export const transformResources = (data: any) => ({
	id: data.id,
	main_category_id: data.main_category_id,
	type: data.type,
	type_name: data.type_name,
	title: data.title,
	brand: data.brand,
	model: data.model,
	image: data.image,
	variant_count: data.variant_count,
	currency: data.currency,
	//files: data.files.map((inner_data: any) => ({
	//	id: inner_data.id,
	//	type: inner_data.type,
	//	title: inner_data.title,
	//	additional_price: inner_data.additional_price,
	//})),
	//options: data.options.map((inner_data: any) => ({
	//	id: inner_data.id,
	//	title: inner_data.title,
	//	type: inner_data.type,
	//	values: inner_data.values.map((value_data: any) => ({
	//		flat: value_data.flat,
	//		//threedimention: value_data.3d,
	//		both: value_data.both,
	//	})),
	//	additional_price: inner_data.additional_price,
	//	additional_price_breakdown: inner_data.additional_price_breakdown.map(
	//		(additional_data: any) => ({
	//			flat: additional_data.flat,
	//			//threedimention: additional_data.3d,
	//			both: additional_data.both,
	//		})
	//	),
	//})),
	is_discontinued: data.is_discontinued,
	avg_fulfillment_time: data.avg_fulfillment_time,
	description: data.description,
	//techniques: data.techniques.map((inner_data: any) => ({
	//	key: inner_data.key,
	//	display_name: inner_data.display_name,
	//	is_default: inner_data.is_default,
	//})),
});

export const transformResource = (data: any) => ({
	product: {
		id: data.product.id,
		main_category_id: data.product.main_category_id,
		type: data.product.type,
		type_name: data.product.type_name,
		title: data.product.title,
		brand: data.product.brand,
		model: data.product.model,
		image: data.product.image,
		variant_count: data.product.variant_count,
		currency: data.product.currency,
		files: data.product.files,
		options: data.product.options,
		is_discontinued: data.product.is_discontinued,
		avg_fulfillment_time: data.product.avg_fulfillment_time,
		description: data.product.description,
		techniques: data.product.techniques,
	},
	variants: data.variants.map((variant: any) => ({
		id: variant.id,
		product_id: variant.product_id,
		name: variant.name,
		size: variant.size,
		color: variant.color,
		color_code: variant.color_code,
		color_code2: variant.color_code2,
		image: variant.image,
		price: variant.price,
		in_stock: variant.in_stock,
		availability_regions: variant.availability_regions,
		availability_status: variant.availability_status,
	})),
});
