import React, { useEffect } from "react";

import styles from "./CheckoutResultPage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { onlinePaymentService } from "airedux/services";
import { toastMessage } from "airedux/helpers";
import { routeConstants } from "routes";

import { ReactComponent as TickIcon } from "common/Icons/tick-square.svg";

import ReactGA from "react-ga";
import { Button, Htag, P } from "common";

const CheckoutResultPage: React.FC = (): JSX.Element => {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	const update_paymentOrder = async ({ type, order_id, email }: any) => {
		ReactGA.event({
			category: "UpdatePaymentOrder",
			action: "UpdatePaymentOrder",
			label: "Update Payment Order",
			value: 0,
		});
		//http://localhost:3000/checkout-result/?type=success&order_id=abc
		const response =
			type === "success"
				? await onlinePaymentService.validate_online_payment(order_id, email)
				: await onlinePaymentService.cancel_online_payment(order_id, email);

		toastMessage({
			type: response.success === true ? "success" : "error",
			message: response.message,
			position: "center-top",
		});
		// navigate(routeConstants.root.route);
	};
	const navigate = useNavigate();
	const location = useLocation();
	useEffect(() => {
		const search_params = new URLSearchParams(location.search);
		const type: any = search_params.get("type");
		const order_id: any = search_params.get("order_id");
		const email: any = search_params.get("email");
		update_paymentOrder({ type, order_id, email });
	}, []);

	return (
		<div className={cn(styles.checkoutresultpage)}>
			<SubNavbar
				disabled="Home /"
				active="Checkout Result"
				pageName="Checkout Result"
			/>
			<div className={cn(styles.container)}>
				<TickIcon />
				<Htag tag="h4">Your order has been placed</Htag>
				<P size="p1" className={cn(styles.successtext)}>
					Pull up a chair, sit back and relax as your order is on its way to
					you!
				</P>
				<Link to={routeConstants.root.route} style={{ width: "100%" }}>
					<Button appearance="black" className={cn(styles.successbutton)}>
						Done
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default CheckoutResultPage;
