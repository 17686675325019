import React, { useEffect } from "react";

import styles from "./App.module.css";
import cn from "classnames";

import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter as Router } from "react-router-dom";
import MainWrapper from "components/MainWrapper/MainWrapper";
import { PromptContext } from "components/GenerateProduct/PromptContext";

import ReactGA from "react-ga";
import { serviceConfig } from "configs";
import useBlockLogs from "hooks/useBlockLogs";
import { gapi } from "gapi-script";
import { LoadScript } from "@react-google-maps/api";

const TRACKING_ID = serviceConfig.googleAnalyticsTrackingId;
ReactGA.initialize(TRACKING_ID);

const App: React.FC = (): JSX.Element => {
	const [prompt, setPrompt] = React.useState("");

	const showLogs = serviceConfig.show_logs;

	useBlockLogs(showLogs);

	const googleClientId =
		"919411038787-n4g3jr4lokeia30m8isp59mlb9o48vj8.apps.googleusercontent.com";

	useEffect(() => {
		const start = () => {
			gapi.client.init({
				clientId: googleClientId,
				scope: "",
			});
		};
		gapi.load("client:auth2", start);
	});

	return (
		<PromptContext.Provider value={{ prompt, setPrompt }}>
			<Router>
				<LoadScript
					googleMapsApiKey={
						process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
						"AIzaSyB-PtbnR9_2PHVPZAHblScDRb16KOZbICw"
					}
					libraries={["places"]}
				>
					<div className={cn(styles.app)}>
						<MainWrapper />
					</div>
				</LoadScript>
			</Router>
		</PromptContext.Provider>
	);
};

export default App;
