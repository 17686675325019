import { fetchWithCred, handleResponse } from "airedux/helpers";
import { serviceConfig } from "configs";
import { orders_list, single_order } from "./mock/order.mock";

const createOrder = async (payload: any) => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(single_order);
			}, 1000);
		});
	}

	const requestOptions = {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json",
		},
	};

	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.apiPrefix}${serviceConfig.routes.sync_orders}`,
		requestOptions
	)
		.then(handleResponse)
		.then((response: any) => response);
};

const fetchAll = async (userId: any) => {
	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.apiPrefix}${serviceConfig.routes.sync_orders}?userId=${userId}`
	)
		.then(handleResponse)
		.then((response: any) => response.data);
};

const fetchById = async (id: any) => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(single_order);
			}, 1000);
		});
	}

	return fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.apiPrefix}/orders/${id}`
	)
		.then(handleResponse)
		.then((response) => response.data);
};

export const orderService = {
	createOrder,
	fetchAll,
	fetchById,
};
