import React from "react";

import { CategoryItemProps } from "./CategoryItem.props";
import styles from "./CategoryItem.module.css";
import cn from "classnames";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";

export const CategoryItem = ({
	icon,
	iconalt,
	label,
	className,
	withIcon,
	active,
	labelcatlist,
	...props
}: CategoryItemProps): JSX.Element => {
	return (
		<>
			{withIcon ? (
				<div className={cn(styles.categoryitem, className)} {...props}>
					<img src={icon} alt={iconalt} />
					<Htag tag="s2-medium">{label}</Htag>
				</div>
			) : (
				<div
					className={cn(
						active ? styles.categoryactive : styles.category,
						className
					)}
					{...props}
				>
					<P size="b1medium" style={{ cursor: "pointer" }}>
						{labelcatlist}
					</P>
				</div>
			)}
		</>
	);
};
