import React from "react";

import { CheckboxProps } from "./Checkbox.props";
import styles from "./Checkbox.module.css";
import cn from "classnames";

export const Checkbox = ({
	appearance,
	className,
	...props
}: CheckboxProps): JSX.Element => {
	return (
		<input
			type="checkbox"
			{...props}
			className={cn(styles.checkbox, className, {
				[styles.default]: appearance === "default",
				[styles.checked]: appearance === "checked",
				[styles.disabled]: appearance === "disabled",
			})}
		></input>
	);
};
