import React from "react";

import { InputProps } from "./Input.props";
import styles from "./Input.module.css";
import cn from "classnames";
import { P } from "common/P/P";

export const Input = ({
	appearance = "default",
	label,
	className,
	type,
	value,
	name,
	placeholder,
	editButton,
	onChange,
	children,
	maxLength,
	minLength,
	withoutBorder,
	...props
}: InputProps): JSX.Element => {
	return (
		<>
			{label ? (
				<div className={cn(styles.withlabel)}>
					<label>
						<P size="b1medium">{label}</P>
					</label>
					{editButton ? (
						<div
							className={
								!children ? cn(styles.editclassdiv) : cn(styles.editbuttondiv)
							}
						>
							<input
								type={type}
								value={value}
								name={name}
								onChange={onChange}
								{...props}
								className={cn(
									withoutBorder ? styles.inputwithoutborder : styles.input,
									className,
									{
										[styles.default]: appearance === "default",
										[styles.error]: appearance === "error",
										[styles.bordernone]: appearance === "bordernone",
									}
								)}
								placeholder={placeholder}
								maxLength={maxLength}
								minLength={minLength}
							></input>
							{children}
						</div>
					) : (
						<input
							type={type}
							value={value}
							name={name}
							onChange={onChange}
							{...props}
							className={cn(
								withoutBorder ? styles.inputwithoutborder : styles.input,
								className,
								{
									[styles.default]: appearance === "default",
									[styles.error]: appearance === "error",
									[styles.bordernone]: appearance === "bordernone",
								}
							)}
							placeholder={placeholder}
							maxLength={maxLength}
							minLength={minLength}
						></input>
					)}

					{appearance === "error" && (
						<span className={cn(styles.errormessage)}>
							Password is a required field.
						</span>
					)}
				</div>
			) : (
				<>
					<input
						type={type}
						value={value}
						name={name}
						onChange={onChange}
						{...props}
						className={cn(
							withoutBorder ? styles.inputwithoutborder : styles.input,
							className,
							{
								[styles.default]: appearance === "default",
								[styles.error]: appearance === "error",
								[styles.bordernone]: appearance === "bordernone",
							}
						)}
						placeholder={placeholder}
						maxLength={maxLength}
						minLength={minLength}
					></input>
					{appearance === "error" && (
						<span className={cn(styles.errormessage)}>
							Password is a required field.
						</span>
					)}
				</>
			)}
		</>
	);
};
