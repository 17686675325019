import { resourceConstants } from "airedux/constants";

const initialState = {
	id: null,
};

export const resourcePage = (state = initialState, { type, payload }: any) => {
	switch (type) {
		case resourceConstants.FETCH_BY_ID_SUCCESS:
			return {
				...state,
				id: payload.id,
			};
		default:
			return state;
	}
};
