import * as R from "ramda";

import { resourceConstants as actionConstants } from "airedux/constants";

const initialState = {
	loading: false,
	error: false,
	ids: [],
};

interface IAction {
	type: string;
	payload: Array<any>;
}

export const resourcesPage = (
	state = initialState,
	{ type, payload }: IAction
) => {
	switch (type) {
		case actionConstants.FETCH_SUCCESS:
			return R.mergeRight(state, {
				ids: R.pluck("id", payload),
			});

		//case actionConstants.LOAD_MORE_SUCCESS:
		//	const ids = R.pluck('id', payload)
		//	return R.mergeRight(state, {
		//		ids: R.concat(state.ids, ids),
		//	})

		default:
			return state;
	}
};
