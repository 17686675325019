import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { ModalProps } from "./Modal.props";
import styles from "./Modal.module.css";
import cn from "classnames";

import { ReactComponent as CancelIcon } from "common/Icons/x.svg";
import { P } from "common/P/P";
import { Htag } from "common/Htag/Htag";

export const Modal = ({
	title,
	children,
	openModal,
	setOpenModal,
	button,
	modalClass,
	desc,
	clickOut = true,
	...props
}: ModalProps): JSX.Element => {
	useEffect(() => {
		if (openModal) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.removeProperty("overflow");
		};
	}, [openModal]);

	const modalVariants = {
		hidden: {
			opacity: 0,
			scale: 0.9,
		},
		visible: {
			opacity: 1,
			scale: 1,
			transition: {
				duration: 0.3,
			},
		},
		exit: {
			opacity: 0,
			scale: 0.9,
			transition: {
				duration: 0.2,
			},
		},
	};

	return (
		<AnimatePresence mode="wait">
			{openModal && (
				<>
					<div
						className={cn(styles.darkbg)}
						onClick={() => clickOut && setOpenModal(false)}
					/>
					<div className={cn(styles.centered)}>
						<motion.div
							className={modalClass ? cn(styles.loginmodal) : cn(styles.modal)}
							initial="hidden"
							animate="visible"
							exit="exit"
							variants={modalVariants}
						>
							<CancelIcon
								onClick={() => setOpenModal(false)}
								className={cn(styles.closebtn)}
							/>
							{(title || desc) && (
								<div className={cn(desc && styles.modalheader)}>
									<Htag tag="h4" className={cn(!desc && styles.heading)}>
										{title}
									</Htag>
									<P size="p1">{desc}</P>
								</div>
							)}

							<div className={cn(styles.modalcontent)}>{children}</div>
							<div className={cn(styles.modalactions)}>
								<div className={cn(styles.actionscontainer)}>{button}</div>
							</div>
						</motion.div>
					</div>
				</>
			)}
		</AnimatePresence>
	);
};
