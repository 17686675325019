import React from "react";

import styles from "./SubNavbar.module.css";
import cn from "classnames";

import { Breadcrumb, Button, Htag, P } from "common";
import { ReactComponent as ArrowIcon } from "common/Icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";

interface SubNavbarProps {
	disabled: string;
	active: string;
	pageName: string;
	subname?: string;
}

const SubNavbar: React.FC<SubNavbarProps> = ({
	disabled,
	active,
	pageName,
	subname,
}): JSX.Element => {
	const navigate = useNavigate();

	return (
		<div className={cn(styles.subnavbar)}>
			<div className={cn(styles.buttondiv)}>
				<Button
					appearance="ghost-white"
					icon={<ArrowIcon />}
					className={cn(styles.button)}
					onClick={() => navigate(-1)}
				>
					<P size="b2regular">Go back</P>
				</Button>
				{subname ? (
					<Htag tag="s1" className={cn(styles.twocolumns)}>
						{pageName} <sub>{subname}</sub>
					</Htag>
				) : (
					<Htag tag="s1">{pageName} </Htag>
				)}
			</div>
			<Breadcrumb disabled={disabled} active={active} />
		</div>
	);
};

export default SubNavbar;
