export const all_countries = {
	code: 200,
	result: [
		{
			name: "Andorra",
			code: "AD",
			states: null,
		},
		{
			name: "United Arab Emirates",
			code: "AE",
			states: null,
		},
		{
			name: "Afghanistan",
			code: "AF",
			states: null,
		}, // THIS INFORMATIONS DON'T NEED IN SELECT ADDRESS INFORMATION INPUTS
		// ONLY USA FOR NOW
		{
			name: "United States",
			code: "US",
			states: [
				{
					code: "AA",
					name: "Armed Forces Americas (except Canada)",
				},
				{
					code: "AE",
					name: "Armed Forces",
				},
				{
					code: "AK",
					name: "Alaska",
				},
				{
					code: "AL",
					name: "Alabama",
				},
				{
					code: "AP",
					name: "Armed Forces Pacific",
				},
				{
					code: "AR",
					name: "Arkansas",
				},
				{
					code: "AS",
					name: "American Samoa",
				},
				{
					code: "AZ",
					name: "Arizona",
				},
				{
					code: "CA",
					name: "California",
				},
				{
					code: "CO",
					name: "Colorado",
				},
				{
					code: "CT",
					name: "Connecticut",
				},
				{
					code: "DC",
					name: "District of Columbia",
				},
				{
					code: "DE",
					name: "Delaware",
				},
				{
					code: "FL",
					name: "Florida",
				},
				{
					code: "FM",
					name: "Federated States of Micronesia",
				},
				{
					code: "GA",
					name: "Georgia",
				},
				{
					code: "GU",
					name: "Guam",
				},
				{
					code: "HI",
					name: "Hawaii",
				},
				{
					code: "IA",
					name: "Iowa",
				},
				{
					code: "ID",
					name: "Idaho",
				},
				{
					code: "IL",
					name: "Illinois",
				},
				{
					code: "IN",
					name: "Indiana",
				},
				{
					code: "KS",
					name: "Kansas",
				},
				{
					code: "KY",
					name: "Kentucky",
				},
				{
					code: "LA",
					name: "Louisiana",
				},
				{
					code: "MA",
					name: "Massachusetts",
				},
				{
					code: "MD",
					name: "Maryland",
				},
				{
					code: "ME",
					name: "Maine",
				},
				{
					code: "MH",
					name: "Marshall Islands",
				},
				{
					code: "MI",
					name: "Michigan",
				},
				{
					code: "MN",
					name: "Minnesota",
				},
				{
					code: "MO",
					name: "Missouri",
				},
				{
					code: "MP",
					name: "Northern Mariana Islands",
				},
				{
					code: "MS",
					name: "Mississippi",
				},
				{
					code: "MT",
					name: "Montana",
				},
				{
					code: "NC",
					name: "North Carolina",
				},
				{
					code: "ND",
					name: "North Dakota",
				},
				{
					code: "NE",
					name: "Nebraska",
				},
				{
					code: "NH",
					name: "New Hampshire",
				},
				{
					code: "NJ",
					name: "New Jersey",
				},
				{
					code: "NM",
					name: "New Mexico",
				},
				{
					code: "NV",
					name: "Nevada",
				},
				{
					code: "NY",
					name: "New York",
				},
				{
					code: "OH",
					name: "Ohio",
				},
				{
					code: "OK",
					name: "Oklahoma",
				},
				{
					code: "OR",
					name: "Oregon",
				},
				{
					code: "PA",
					name: "Pennsylvania",
				},
				{
					code: "PR",
					name: "Puerto Rico",
				},
				{
					code: "PW",
					name: "Palau",
				},
				{
					code: "RI",
					name: "Rhode Island",
				},
				{
					code: "SC",
					name: "South Carolina",
				},
				{
					code: "SD",
					name: "South Dakota",
				},
				{
					code: "TN",
					name: "Tennessee",
				},
				{
					code: "TX",
					name: "Texas",
				},
				{
					code: "UT",
					name: "Utah",
				},
				{
					code: "VA",
					name: "Virginia",
				},
				{
					code: "VI",
					name: "Virgin Islands",
				},
				{
					code: "VT",
					name: "Vermont",
				},
				{
					code: "WA",
					name: "Washington",
				},
				{
					code: "WI",
					name: "Wisconsin",
				},
				{
					code: "WV",
					name: "West Virginia",
				},
				{
					code: "WY",
					name: "Wyoming",
				},
			],
		},
	],
};
