/* eslint-disable no-case-declarations */
import { addressConstants as actionConstants } from "airedux/constants";

export const address = (state = {}, { type, payload }: any) => {
	switch (type) {
		case actionConstants.ON_SELECT:
			return payload;
		case actionConstants.ON_DESELECT:
			return payload;

		default:
			return state;
	}
};

export default address;
