import { wishlistConstants } from "airedux/constants/wishlist.constants";
import { wishlistService } from "airedux/services";
import { Dispatch } from "redux";

// Assuming wishlistService.fetchWishlist is an asynchronous function
export const fetchWishlist = (userId: string) => async (dispatch: Dispatch) => {
	dispatch({ type: wishlistConstants.FETCH_WISHLIST_REQUEST });

	try {
		const data = await wishlistService.fetchWishlist(userId);
		dispatch({
			type: wishlistConstants.FETCH_WISHLIST_SUCCESS,
			payload: data,
		});
	} catch (error) {
		const err = error as any;
		dispatch({
			type: wishlistConstants.FETCH_WISHLIST_FAILURE,
			payload:
				err.response?.data.message ||
				"An error occurred while fetching the wishlist",
		});
	}
};

export const addWishlist = (payload: any) => async (dispatch: any) => {
	dispatch({ type: wishlistConstants.ADD_WISHLIST_REQUEST });
	try {
		const data = await wishlistService.addWishlist(payload);
		dispatch({
			type: wishlistConstants.ADD_WISHLIST_SUCCESS,
			payload: data,
		});
	} catch (error: any) {
		dispatch({
			type: wishlistConstants.ADD_WISHLIST_FAILURE,
			payload: error.toString(),
		});
	}
};

export const deleteWishlist = (id: any, type: any) => async (dispatch: any) => {
	dispatch({ type: wishlistConstants.DELETE_WISHLIST_REQUEST });
	try {
		const data = await wishlistService.deleteWishlist(id);
		dispatch({
			type: wishlistConstants.DELETE_WISHLIST_SUCCESS,
			payload: { id, type },
		});
	} catch (error: any) {
		dispatch({
			type: wishlistConstants.DELETE_WISHLIST_FAILURE,
			payload: error.toString(),
		});
	}
};
