/* eslint-disable no-case-declarations */
import { resourceConstants as actionConstants } from "airedux/constants";

const initialState = { loading: false, error: false, data: {} };

export const resource = (state = initialState, action: any) => {
	switch (action.type) {
		case actionConstants.FETCH_START:
			return {
				...state,
				loading: true,
				error: false,
			};

		case actionConstants.FETCH_SUCCESS:
		case actionConstants.FETCH_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
			};

		case actionConstants.FETCH_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
			};

		default:
			return state;
	}
};
