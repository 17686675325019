import React, { useEffect } from "react";

import { NotificationModalProps } from "./NotificationModal.props";
import styles from "./NotificationModal.module.css";
import cn from "classnames";

import { ReactComponent as CancelIcon } from "common/Icons/x.svg";

export const NotificationModal = ({
	title,
	children,
	openModal,
	setOpenModal,
	button,
	modalClass,
	...props
}: NotificationModalProps): JSX.Element => {
	useEffect(() => {
		if (openModal) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.removeProperty("overflow");
		};
	}, [openModal]);

	return (
		<>
			<div className={cn(styles.darkbg)} onClick={() => setOpenModal(false)} />
			<div className={cn(styles.centered)}>
				<div className={modalClass ? cn(styles.loginmodal) : cn(styles.modal)}>
					<button
						className={cn(styles.closebtn)}
						onClick={() => setOpenModal(false)}
					>
						<CancelIcon />
					</button>
					<div className={styles.modalbody}>
						<div className={cn(styles.modalheader)}>
							<h5 className={cn(styles.heading)}>{title}</h5>
						</div>
						<div className={cn(styles.modalcontent)}>{children}</div>
						<div className={cn(styles.modalactions)}>
							<div className={cn(styles.actionscontainer)}>{button}</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
