export const wishlistConstants = {
	FETCH_WISHLIST_REQUEST: "FETCH_WISHLIST_REQUEST",
	FETCH_WISHLIST_SUCCESS: "FETCH_WISHLIST_SUCCESS",
	FETCH_WISHLIST_FAILURE: "FETCH_WISHLIST_FAILURE",

	ADDED_TO_WISHLIST: "RESOURCE_ADDED_TO_WISHLIST",
	REMOVED_FROM_WISHLIST: "RESOURCE_REMOVED_FROM_WISHLIST",

	// Adding an item to the wishlist
	ADD_WISHLIST_REQUEST: "ADD_WISHLIST_REQUEST",
	ADD_WISHLIST_SUCCESS: "ADD_WISHLIST_SUCCESS",
	ADD_WISHLIST_FAILURE: "ADD_WISHLIST_FAILURE",

	// Removing an item from the wishlist
	DELETE_WISHLIST_REQUEST: "DELETE_WISHLIST_REQUEST",
	DELETE_WISHLIST_SUCCESS: "DELETE_WISHLIST_SUCCESS",
	DELETE_WISHLIST_FAILURE: "DELETE_WISHLIST_FAILURE",
};
