/* eslint-disable no-mixed-spaces-and-tabs */
import * as R from "ramda";

export const getCategoryById = (data: any, id: any) => R.prop(id, data);

export const getCategories = (state: any) => {
	const categories =
		state.category && !!state.category.data
			? R.map(
					(id: any) => getCategoryById(state.category.data, id),
					state.categoryPage.ids
			  )
			: [];
	return categories;
};
