import React, { useState } from "react";

import { Button, Htag, Input, P, TextArea } from "common";

import { Modal } from "common/Modal/Modal";
import { toastMessage } from "airedux/helpers";
import ReactGA from "react-ga";
import Spinner from "modules/loaders/Spinner/Spinner";
import { serviceConfig } from "configs/service.config";
import SEOHelmet from "components/SEOHelmet/SEOHelmet";

const RedesignPage: React.FC = () => {
	const [prompt, setPrompt] = useState("");
	const [image, setImage] = useState<File | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [result, setResult] = useState<{
		description?: string;
		imageUrl?: string;
	}>({});

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			setImage(e.target.files[0]);
		}
	};

	const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setPrompt(e.target.value);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!image || !prompt) {
			toastMessage({
				type: "error",
				message: "Please provide both an image and a prompt.",
				position: "center-top",
			});

			return;
		}

		const formData = new FormData();
		formData.append("image", image);
		formData.append("prompt", prompt);

		setIsLoading(true);

		try {
			const response = await fetch(
				`${serviceConfig.apiHost}${serviceConfig.routes.processImage}`,
				{
					method: "POST",
					body: formData,
				}
			);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			setResult({
				description: data.data.description,
				imageUrl: data.data.modifiedImage, // Assuming this is a URL
			});
			setIsLoading(false);
		} catch (error) {
			console.error("There was a problem with your fetch operation:", error);
			setIsLoading(false);
		}
	};

	const seoProps = {
		title: "AI Redesign - Transform Your Images | AI Printable",
		description:
			"Revolutionize your images with AI Printable's AI Redesign feature. Upload your image, provide a creative prompt, and let our AI transform your visuals into stunning artworks.",
		keywords:
			"AI image redesign, AI Printable, image transformation, creative image modification, AI art, image prompt",
		url: "https://www.aiprintable.com/redesign", // The URL of the redesign page
		contentType: "article",
		subject: "AI-Powered Image Redesign",
		language: "EN",
		robots: "index, follow",
		revised: "Sunday, March 8th, 2024, 5:15 pm",
		abstract:
			"Use AI Printable's AI Redesign to turn your images into artworks based on creative prompts.",
		topic: "Image Redesign",
		summary:
			"AI Printable offers a unique AI-driven service to redesign images based on user prompts, transforming ordinary photos into extraordinary pieces of art.",
		classification: "Design, Art, AI Technology",
		author: "AI Printable, info@aiprintable.com",
		designer: "AI Printable Design Team",
		replyTo: "info@aiprintable.com",
		owner: "AI Printable",
		identifierURL: "https://www.aiprintable.com/redesign",
		directory: "submission",
		pagename: "AI Printable Redesign Feature",
		category: "Technology, Art, Design",
		coverage: "Worldwide",
		distribution: "Global",
		rating: "Safe for Kids",
		revisitAfter: "7 days",
		subtitle: "Explore AI-Powered Creativity",
		target: "all",
		handheldFriendly: "True",
		mobileOptimized: "320",
		date: new Date().toISOString().split("T")[0],
		searchDate: new Date().toISOString().split("T")[0],
		DCtitle: "AI Redesign by AI Printable",
	};

	return (
		<div>
			<SEOHelmet {...seoProps} />
			<form onSubmit={handleSubmit}>
				<div>
					<input type="file" onChange={handleImageChange} />
				</div>
				<div>
					<textarea
						placeholder="Enter prompt here"
						value={prompt}
						onChange={handlePromptChange}
					/>
				</div>
				<button type="submit">Submit</button>
			</form>

			{isLoading && <Spinner />}

			{result.description && (
				<div>
					<p>{result.description}</p>
					{result.imageUrl && (
						<img
							src={result.imageUrl}
							alt="Modified Image"
							className="object-cover w-[400px] h-[400px]"
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default RedesignPage;
