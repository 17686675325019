/* eslint-disable no-case-declarations */
import { resourceConstants, variantConstants } from "airedux/constants";
import * as R from "ramda";

const initialState: any = { loading: false, error: false, data: {} };

export const variant = (state = initialState, { type, payload }: any) => {
	switch (type) {
		case resourceConstants.FETCH_BY_ID_SUCCESS:
			const newState = {
				loading: false,
				error: false,
				data: R.mergeRight(
					state.data,
					R.indexBy(R.prop<string, string>("id"), [payload])
				),
			};
			return R.mergeRight(state, newState);

		case variantConstants.FETCH_BY_ID_SUCCESS:
			return R.mergeRight(state, {
				...state,
				data: R.assoc(payload.product_id, payload, state.data),
			});

		default:
			return state;
	}
};
