import React, { useEffect, useState } from "react";

import styles from "./TermsConditionsPage.module.css";
import cn from "classnames";
import SubNavbar from "components/SubNavbar/SubNavbar";
import { Button, Htag, P } from "common";
import { ReactComponent as HamburgerIcon } from "common/Icons/menu.svg";
import { ReactComponent as CancelIcon } from "common/Icons/x.svg";
import ReactGA from "react-ga";
import SEOHelmet from "components/SEOHelmet/SEOHelmet";

interface Section {
	id: any;
	title: string;
	content: string;
	subcontent?: string;
}

const sections: Section[] = [
	{
		id: 1,
		title: "Use of Our Services",
		content:
			"You may use our website and services only for lawful purposes and in accordance with these terms and conditions.",
	},
	{
		id: 2,
		title: "User Accounts",
		content:
			"To access certain features of our website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.",
	},
	{
		id: 3,
		title: "Intellectual Property",
		content:
			"The content, design, and software of our website are owned or licensed by AI Printable and are protected by copyright, trademark, and other intellectual property laws.",
	},
	{
		id: 4,
		title: "User Content",
		content:
			"You retain ownership of any content that you post or upload to our website, but you grant us a non-exclusive, transferable, sub-licensable, royalty-free license to use, copy, modify, create derivative works, and display your content.",
	},
	{
		id: 5,
		title: "Prohibited Conduct",
		content:
			"You may not use our website or services to engage in any conduct that is unlawful, infringing, defamatory, or harmful to others.",
	},
	{
		id: 6,
		title: "Disclaimer of Warranties",
		content:
			"We provide our website and services on an 'as is' and 'as available' basis, without warranties of any kind, either express or implied.",
	},
	{
		id: 7,
		title: "Limitation of Liability",
		content:
			"We will not be liable for any damages arising out of or in connection with your use of our website or services.",
	},
	{
		id: 8,
		title: "Indemnification",
		content:
			"You agree to indemnify and hold us and our affiliates, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, arising out of your use of our website or services or your violation of these terms and conditions.",
	},
	{
		id: 9,
		title: "Termination",
		content:
			"We may terminate your access to our website or services at any time, for any reason, without notice.",
	},
	{
		id: 10,
		title: "Governing Law",
		content:
			"These terms and conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without giving effect to any principles of conflicts of law.",
	},
	{
		id: 11,
		title: "Changes to Terms and Conditions",
		content:
			"We may modify these terms and conditions at any time, and such modifications will be effective immediately upon posting.",
	},
	{
		id: 12,
		title: "Entire Agreement",
		content:
			"These terms and conditions constitute the entire agreement between you and aiprintable regarding your use of our website and services.",
		subcontent:
			"Thank you for choosing aiprintable. We value your business and look forward to serving you.",
	},
];

const TermsConditionsPage: React.FC = (): JSX.Element => {
	const [open, setOpen] = useState(false);
	const handleLinkClick = (sectionid: any) => {
		const sectionElement = document.querySelector(
			`[data-section-id="${sectionid}"]`
		);

		if (sectionElement) {
			sectionElement.scrollIntoView({ behavior: "smooth" });
		}
		setOpen(false);
	};
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	const toggleBurger = () => {
		setOpen(!open);
	};

	useEffect(() => {
		if (open) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.removeProperty("overflow");
		};
	}, [open]);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const seoProps = {
		title: "Terms & Conditions - AI Printable",
		description:
			"Read the terms and conditions governing your use of AI Printable. Understand your rights and responsibilities as you engage with our AI-driven design tools and services.",
		keywords:
			"AI Printable terms, user agreement, legal terms, service conditions",
		url: "https://www.aiprintable.com/terms-conditions",
		contentType: "website",
		subject: "Terms & Conditions of AI Printable",
		language: "EN",
		robots: "index, follow",
		revised: "Sunday, March 8th, 2024, 5:15 pm",
		abstract:
			"Official terms and conditions page of AI Printable, outlining the rules and guidelines for using our website and services.",
		topic: "Legal Terms of Service",
		summary:
			"This page provides a comprehensive overview of the terms and conditions for using AI Printable's website and services, including user rights, intellectual property, and limitations of liability.",
		classification: "Legal, Terms of Service",
		author: "AI Printable, info@aiprintable.com",
		designer: "AI Printable Design Team",
		replyTo: "info@aiprintable.com",
		owner: "AI Printable",
		identifierURL: "https://www.aiprintable.com/terms-conditions",
		directory: "submission",
		pagename: "AI Printable Terms & Conditions",
		category: "Legal, Service Terms",
		coverage: "Worldwide",
		distribution: "Global",
		rating: "General",
		revisitAfter: "7 days",
		subtitle: "User Agreement & Legal Terms",
		target: "all",
		handheldFriendly: "True",
		mobileOptimized: "320",
		date: new Date().toISOString().split("T")[0],
		searchDate: new Date().toISOString().split("T")[0],
		DCtitle: "AI Printable Terms & Conditions",
	};

	return (
		<div className={cn(styles.termsconditionspage)}>
			<SEOHelmet {...seoProps} />
			<SubNavbar
				disabled="Home /"
				active="Terms & Conditions"
				pageName="Terms & Conditions"
			/>
			<div className={cn(styles.container)}>
				{window.innerWidth > 767 ? (
					<div className={styles.links}>
						<Htag tag="h3" className={styles.topics}>
							Topics
						</Htag>
						{sections.map((section) => (
							<Htag tag="s2-bold">
								<a
									key={section.id}
									className={styles.linkitem}
									onClick={() => handleLinkClick(section.id)}
								>
									{section.title}
								</a>
							</Htag>
						))}
					</div>
				) : (
					<>
						<div className={cn(styles.hamburgerfirstrow)}>
							{open === false && (
								<Button
									appearance="ghost-white"
									onClick={toggleBurger}
									className={styles.buttontopic}
									icon={<HamburgerIcon className={styles.hamburgericon} />}
								>
									Choose topics
								</Button>
							)}
						</div>
						{open === true && (
							<div className={styles.darkbg} onClick={() => setOpen(false)}>
								<div className={styles.mobilelinks}>
									<div className={styles.mobileburger}>
										<Htag tag="h3">Topics</Htag>
										<CancelIcon onClick={toggleBurger} />
									</div>
									{sections.map((section) => (
										<Htag tag="s2-bold">
											<a
												key={section.id}
												className={styles.linkitem}
												onClick={() => handleLinkClick(section.id)}
											>
												{section.title}
											</a>
										</Htag>
									))}
								</div>
							</div>
						)}
					</>
				)}

				<div
					className={
						window.innerWidth > 767 ? styles.sections : styles.mobilesections
					}
				>
					<Htag tag="s1">
						Welcome to aiprintable . By using our website or services, you agree
						to be bound by the following terms and conditions:
					</Htag>
					{sections.map((section) => (
						<div
							key={section.id}
							data-section-id={section.id}
							className={styles.contentitem}
						>
							<Htag tag="s2-bold">{section.title}</Htag>
							<P size="p1">{section.content}</P>
							<P size="p1">{section.subcontent && section.subcontent}</P>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default TermsConditionsPage;
