import React from "react";
import { connect } from "react-redux";
import { getVariantTotalCount } from "airedux/selectors";
import { ErrorBoundary } from "modules/errors";

import styles from "./Cart.module.css";
import cn from "classnames";
import { Htag } from "common/Htag/Htag";
import { Button, P } from "common";
import CartBody from "./CartBody";
import { routeConstants } from "routes";
import { Link } from "react-router-dom";

const Cart = ({
	totalCount,
	totalPrice,
	handleNavigation,
}: any): JSX.Element => {
	return (
		<div className={cn(styles.cart)}>
			<div className={cn(styles.header)}>
				<P size="b1medium">Cart ({totalCount})</P>
			</div>
			<CartBody />
			<div className={cn(styles.bottomdiv)}>
				<div className={cn(styles.pricediv)}>
					<P size="b1regular">Terms & Service</P>
					<div className={cn(styles.price)}>
						<Htag tag="s2-medium">Total:</Htag>
						<Htag tag="s2-medium">${totalPrice}</Htag>
					</div>
				</div>
				<div className={cn(styles.checkout)}>
					{/* <Button appearance="ghost-white">View Cart ({totalCount})</Button> */}
					{totalCount > 0 ? (
						<div
							onClick={() => handleNavigation(routeConstants.checkout.route)}
						>
							<Button appearance={`black`} style={{ width: "100%" }}>
								Checkout
							</Button>
						</div>
					) : (
						<div>
							<Button
								appearance={`disabled`}
								style={{ width: "100%", cursor: "not-allowed" }}
							>
								Checkout
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	const totalData = getVariantTotalCount(state);
	console.log("TOTAL DATA", totalData);
	return {
		totalCount: totalData.totalCount,
		totalPrice: totalData.totalPrice,
	};
};

export default connect(mapStateToProps)(Cart);
