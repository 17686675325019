import { toast } from "react-toastify";

export const toastMessage = ({
	type = "default",
	message = "Alert",
	position = "top-right",
	autoClose = 3000,
	hideProgressBar = false,
	closeOnClick = true,
	pauseOnHover = true,
	draggable = true,
	progress = undefined,
	theme = "dark",
}: any) => {
	switch (type) {
		case "success":
			return toast.success(message, {
				position: position,
				autoClose: autoClose,
				hideProgressBar: hideProgressBar,
				closeOnClick: closeOnClick,
				pauseOnHover: pauseOnHover,
				draggable: draggable,
				progress: progress,
				theme: theme,
			});
		case "error":
			return toast.error(message, {
				position: position,
				autoClose: autoClose,
				hideProgressBar: hideProgressBar,
				closeOnClick: closeOnClick,
				pauseOnHover: pauseOnHover,
				draggable: draggable,
				progress: progress,
				theme: theme,
			});
		case "info":
			return toast.info(message, {
				position: position,
				autoClose: autoClose,
				hideProgressBar: hideProgressBar,
				closeOnClick: closeOnClick,
				pauseOnHover: pauseOnHover,
				draggable: draggable,
				progress: progress,
				theme: theme,
			});
		case "warning":
			return toast.warn(message, {
				position: position,
				autoClose: autoClose,
				hideProgressBar: hideProgressBar,
				closeOnClick: closeOnClick,
				pauseOnHover: pauseOnHover,
				draggable: draggable,
				progress: progress,
				theme: theme,
			});
		case "default":
			return toast(message, {
				position: position,
				autoClose: autoClose,
				hideProgressBar: hideProgressBar,
				closeOnClick: closeOnClick,
				pauseOnHover: pauseOnHover,
				draggable: draggable,
				progress: progress,
				theme: theme,
			});
		default:
			return toast(message, {
				position: position,
				autoClose: autoClose,
				hideProgressBar: hideProgressBar,
				closeOnClick: closeOnClick,
				pauseOnHover: pauseOnHover,
				draggable: draggable,
				progress: progress,
				theme: theme,
			});
	}
};
