import { connect } from "react-redux";
import cn from "classnames";
import { ReactComponent as UserIcon } from "common/Icons/user.svg";
import { P } from "common/P/P";

import styles from "./Navbar.module.css";
import { useNavigate } from "react-router-dom";
import { routeConstants } from "routes";

const LoginProfileButton = ({ setLoginOpen, loginOpen, loggedIn }: any) => {
	const navigate = useNavigate();
	return (
		<div
			className={cn(styles.profiledrop)}
			onClick={() =>
				!loggedIn
					? navigate(`${routeConstants.root.route}?auth=login`) //setLoginOpen(!loginOpen)
					: navigate(routeConstants.my_account.route)
			}
		>
			<div className={cn(styles.profile)}>
				<span className={cn(styles.profilebg)}>
					<UserIcon className={cn(styles.usericon)} />
				</span>
				<P size="b2medium">{loggedIn ? "Profile" : "Join | Login"}</P>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	loggedIn: state.auth.loggedIn,
});

export default connect(mapStateToProps)(LoginProfileButton);
