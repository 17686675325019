/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./Auth.module.css";
import cn from "classnames";

import { Modal } from "common/Modal/Modal";
import { Button, Input } from "common";
import Spinner from "modules/loaders/Spinner/Spinner";
import { toastMessage } from "airedux/helpers";
import { routeConstants } from "routes";
import { useNavigate } from "react-router-dom";
import { authActions } from "airedux/actions";

const AuthNewPassword = ({
	onStageChange,
	handleValidationData,
	validationData,
}: any) => {
	const [loading, set_loading] = useState(false);

	const [inputs, setInputs] = useState({
		email: validationData.credentials,
		password: "",
		confirm_password: "",
	});
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	};

	const dispatch = useDispatch();

	const handleSubmit = (e: any) => {
		e.preventDefault();

		if (inputs.password.length < 8) {
			toastMessage({
				type: "warning",
				message: "Passwords length should be greater than 8 symbols",
				position: "center-top",
			});
		} else if (inputs.password !== inputs.confirm_password) {
			toastMessage({
				type: "warning",
				message: "Passwords doesn't match",
				position: "center-top",
			});
		} else {
			set_loading(true);
			dispatch(
				authActions.reset_password(
					validationData.registerToken,
					inputs,
					set_loading
				)
			);
		}
	};

	const navigate = useNavigate();
	return (
		<>
			<Modal
				title="Create new password"
				openModal={true}
				clickOut={false}
				setOpenModal={(e: any) =>
					e === false && navigate(`${routeConstants.root.route}`)
				}
				modalClass={true}
				button={
					!loading ? (
						<Button
							appearance="black"
							className={cn(styles.buttoncomponent)}
							onClick={handleSubmit}
						>
							Create
						</Button>
					) : (
						<Spinner />
					)
				}
			>
				<form onSubmit={!loading ? handleSubmit : () => ({})}>
					<div className={cn(styles.logincontent)}>
						<div className={cn(styles.inputs)}>
							<Input
								label="New password"
								appearance="default"
								type="password"
								name="password"
								autoFocus
								placeholder="must be 8 character "
								onChange={handleChange}
								required
							/>
							<Input
								label="Confirm"
								appearance="default"
								type="password"
								name="confirm_password"
								placeholder="must be 8 character"
								onChange={handleChange}
								required
							/>
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
};

export default AuthNewPassword;
