import React from "react";

import { BreadcrumbProps } from "./Breadcrumb.props";
import styles from "./Breadcrumb.module.css";
import cn from "classnames";
import { P } from "common/P/P";

export const Breadcrumb = ({
	className,
	disabled,
	active,
	...props
}: BreadcrumbProps): JSX.Element => {
	return (
		<div className={cn(styles.container, className)} {...props}>
			<P size="b2regular" className={cn(styles.disabled)}>
				{disabled}
			</P>
			<P size="b2medium" className={cn(styles.active)}>
				{active}
			</P>
		</div>
	);
};
