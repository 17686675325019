import React from "react";
import classNames from "classnames";
import styles from "./Spinner.module.css"; // Make sure this path matches your file structure

const Spinner = () => {
	return (
		<div className={styles.blocks}>
			<div className={classNames(styles.block, styles.black)}></div>
			<div className={classNames(styles.block, styles.red)}></div>
		</div>
	);
};

export default Spinner;
