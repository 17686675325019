import {
	authBearerHeader,
	fetchWithCred,
	handleResponse,
} from "airedux/helpers";
import { serviceConfig } from "configs";
import { transformResources as transformResponse } from "./transform_data";
import { all_resources, all_sync_resources } from "./mock/resource.mock";
import { productVariants } from "./mock/productVariants.mock";

import { mockup_product } from "./mock/mockup_product.mock";

const createResource = async (
	name = "Test-product",
	imageUrl = "https://img.freepik.com/free-psd/isolated-black-t-shirt-front_125540-1167.jpg?w=2000"
) => {
	const requestOptions: any = {
		method: "POST",
		body: JSON.stringify({
			name: name,
			thumbnail: imageUrl,
		}),

		headers: {
			"Content-Type": "application/json",
			...(await authBearerHeader(serviceConfig.keys.printful_token_key)),
		},
		credentials: "same-origin",
		mode: "no-cors",
	};

	return await fetchWithCred(
		`${serviceConfig.urls.printful_url}${serviceConfig.routes.product_info}`,
		requestOptions
	).then(handleResponse);
};

const fetchAllResources = async () => {
	return await fetch("/test-random")
		.then((response) => response.json())
		.then((response) => console.log(response))
		.catch((err) => console.error(err));
};

const fetchAll = async (query_string = "", storeType: string) => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(all_sync_resources);
			}, 1000);
		});
	}
	const tunnel_printful_url = `${serviceConfig.apiTunnelUrl}`;
	const requestOptions = {
		headers: {
			req_url: "https://api.printful.com/store/products",
			method: "get",
			store_type: storeType,
		},
	};
	return await fetchWithCred(
		`${tunnel_printful_url}${query_string}`,
		requestOptions
	).then(handleResponse);
};

const fetchAll_data = async (storeType: string) => {
	const response = await fetchAll("", storeType);
	return response.result.map(transformResponse);
};

const fetchById = async (id: any, storeType: string) => {
	if (serviceConfig.useMockApi) {
		return new Promise((resolve, reject) => {
			resolve(mockup_product);
		});
	}

	const requestOptions = {
		method: "GET",
		headers: {
			store_type: storeType,
		},
	};
	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.routes.get_store_by_id}/${id}`,
		requestOptions
	).then(handleResponse);
};

const fetchById_data = async (id: any, storeType: string) => {
	const response = await fetchById(id, storeType);
	return response;
};

const fetchProductFromShopById = async (storeId: any, productId: any) => {
	const requestOptions = {
		method: "GET",
	};
	return await fetchWithCred(
		`${serviceConfig.apiHost}${serviceConfig.apiPrefix}/shops/${storeId}/products/${productId}`,
		requestOptions
	).then(handleResponse);
};

const fetchProductFromShopById_data = async (storeId: any, productId: any) => {
	const response = await fetchProductFromShopById(storeId, productId);
	return response;
};

export const resourceService = {
	fetchAll,
	fetchAll_data,
	fetchById,
	fetchById_data,
	createResource,
	fetchAllResources,
	fetchProductFromShopById_data,
};
