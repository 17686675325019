import React, { useState } from "react";

import styles from "./FeaturePage.module.css";
import cn from "classnames";
import { Button, Htag, Input, P, TextArea } from "common";

import { ReactComponent as TextToImageIcon } from "common/Icons/text-to-image.svg";
import { ReactComponent as WallArtIcon } from "common/Icons/shot.svg";
import { ReactComponent as PresentIcon } from "common/Icons/present.svg";
import { ReactComponent as MagicIcon } from "common/Icons/MagicWand.svg";
import { toastMessage } from "airedux/helpers";

import Spinner from "modules/loaders/Spinner/Spinner";
import SEOHelmet from "components/SEOHelmet/SEOHelmet";
import FeatureItem from "common/FeatureItem/FeatureItem";
import { routeConstants } from "routes/routeConstants";

const mockData: any = [
	{
		icon: <TextToImageIcon />,
		name: "Text to Image",
		description:
			"Provide a text prompt, and our AI crafts unique designs for t-shirts, canvases, and more. Ideal for personalizing your space, wardrobe, or creating the perfect gift",
		button: {
			appearance: "black",
			label: "Try it for free",
			icon: <MagicIcon />,
			link: routeConstants.generate.route,
		},
	},
	{
		icon: <WallArtIcon />,
		name: "Wall Art Designer",
		description:
			"Upload an image of your space—be it a room or office—and optionally include a description. Our AI will then craft a tailored canvas design perfect for your wall art needs",
		button: {
			appearance: "disabled",
			label: "Coming soon",
			icon: null,
			link: null,
		},
	},
	{
		icon: <PresentIcon />,
		name: "Surprise Design Unveiling",
		description:
			"Share a glimpse of your desired theme, and our designers will secretly craft an AI-assisted piece. Order, and enjoy the suspense until your unique canvas or t-shirt arrives",
		button: {
			appearance: "disabled",
			label: "Coming soon",
			icon: null,
			link: null,
		},
	},
];

const FeaturePage: React.FC = () => {
	const [loading, setLoading] = useState(false);

	const seoProps = {
		title: "Explore Our Range of Personalized Products - AI Printable",
		description:
			"Discover the unique, personalized products available at AI Printable. From custom canvas prints to designer t-shirts, find the perfect item for yourself or as a gift.",
		keywords:
			"personalized products, AI Printable products, custom canvas prints, designer t-shirts, AI-generated designs",
		url: "https://www.aiprintable.com/our-products",
		contentType: "website",
		subject: "AI Printable Product Range",
		copyright: "AI Printable, © 2024",
		language: "en",
		robots: "index,follow",
		revised: "Sunday, March 7th, 2024, 5:15 pm",
		abstract:
			"AI Printable offers a wide range of personalized products, leveraging AI technology to create unique designs tailored to your preferences.",
		topic: "Personalized Products",
		summary:
			"At AI Printable, find a diverse selection of personalized products designed with AI. Explore our catalog and customize your favorite items today.",
		classification: "E-commerce, Personalized Products",
		author: "AI Printable, info@aiprintable.com",
		designer: "AI Printable Team",
		replyTo: "info@aiprintable.com",
		owner: "AI Printable",
		identifierURL: "https://www.aiprintable.com",
		directory: "submission",
		pagename: "Our Products - AI Printable",
		category: "Design, Personalization",
		coverage: "Worldwide",
		distribution: "Global",
		rating: "General",
		revisitAfter: "7 days",
		subtitle: "Personalization at Its Best",
		target: "all",
		handheldFriendly: "True",
		mobileOptimized: "320",
		date: "2024-03-07",
		searchDate: "2024-03-07",
		DCtitle: "AI Printable's Personalized Products",
	};

	return (
		<div className={cn(styles.container)}>
			<SEOHelmet {...seoProps} />
			<div className={cn(styles.titlecontainer)}>
				<Htag tag="h2">Our generative services</Htag>
				<P size="b1regular" style={{ color: "#6D6D6D" }}>
					Products that we are covering for you
				</P>
			</div>
			<div className={cn(styles.productallcontainers)}>
				{mockData.map((item: any) => (
					<FeatureItem key={item.name} data={item} />
				))}
			</div>
		</div>
	);
};

export default FeaturePage;
