import { ReactNode, useState } from "react";

import "./CollapseInformation.scss";

import { ReactComponent as MinusIcon } from "common/Icons/minus.svg";
import { ReactComponent as PlusIcon } from "common/Icons/plus.svg";

interface ItemProps {
	title: string | JSX.Element | any;
	children: ReactNode;
	contentStyle?: string;
	innerClassName?: string;
	lineClassName?: string;
	openCollapse?: boolean;
}

export const CollapseInformationItem: React.FC<ItemProps> = ({
	title,
	children,
	contentStyle,
	innerClassName,
	lineClassName,
	openCollapse,
}) => {
	const [opened, setOpened] = useState(openCollapse ? openCollapse : false);
	return (
		<li className="collapse-list__item">
			<div className={`collapse-item, ${opened && "collapse-item--opened"}`}>
				<div
					className={`collapse-item__line ${lineClassName}`}
					onClick={() => {
						setOpened(!opened);
					}}
				>
					<span className="collapse-item__title">{title}</span>
					<span className="collapse-item__icon">
						{opened ? (
							<MinusIcon className="collapse-icons" />
						) : (
							<PlusIcon className="collapse-icons" />
						)}
					</span>
				</div>
				<div className={`collapse-item__inner ${innerClassName}`}>
					<div className={`collapse-item__content ${contentStyle}`}>
						<span className="collapse-item__paragraph">{children}</span>
					</div>
				</div>
			</div>
		</li>
	);
};
