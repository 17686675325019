import React, { useState } from "react";

import styles from "./OurProducts.module.css";
import cn from "classnames";
import { Button, Htag, Input, P, TextArea } from "common";
import OurProductItem from "common/OurProductItem/OurProductItem";

import { ReactComponent as CanvasIcon } from "common/Icons/ImageSquare.svg";
import { ReactComponent as TshirtIcon } from "common/Icons/TShirt.svg";
import { Modal } from "common/Modal/Modal";
import { toastMessage } from "airedux/helpers";
import { otherServices } from "airedux/services";
import ReactGA from "react-ga";
import Spinner from "modules/loaders/Spinner/Spinner";
import SEOHelmet from "components/SEOHelmet/SEOHelmet";

const mockProducts = [
	{
		icon: <CanvasIcon />,
		name: "Canvas",
		description:
			"Our canvas art uses eco-friendly high-res printing with water-resistant HP latex inks, vibrant poly-cotton canvas, durable pine wood stretch bars, and includes hanging hardware for easy installation.",
		price: "$23 - $38",
		sizes: [
			"8x8 inch",
			"12x12 inch",
			"16x16 inch",
			"18x18 inch",
			"24x24 inch",
			"36x36 inch",
		],
		colors: [],
	},
	{
		icon: <TshirtIcon />,
		name: "T-shirts",
		description:
			"Our DTF-printed shirts feature single-color designs, a retail fit, shoulder taping, tear-away label, and are available in various sizes, made from 100% Airlume combed cotton weighing 4.2 oz.",
		price: "$23 - $38",
		sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
		colors: ["Black", "White", "Maroon", "Blue", "Forest Green", "Orange"],
	},
];

const OurProducts: React.FC = () => {
	const [showRecommendModal, setShowRecommendModal] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [suggestion, setSuggestion] = useState("");
	const [loading, setLoading] = useState(false);

	const handleFormSubmit = async (e: any) => {
		e.preventDefault();

		// Assuming ReactGA and otherServices are already imported
		ReactGA.event({
			category: "ProductRecommendation",
			action: "RecommendProduct",
			label: "Product Recommendation",
			value: 0,
		});

		// Assuming you have a loading state in your component, update it
		setLoading(true);

		const formData = {
			full_name: name,
			email: email,
			subject: "Product Recommendation",
			content: suggestion,
		};

		try {
			const contact_response = await otherServices.post_contact_us(formData);

			if (contact_response.status === 1) {
				toastMessage({
					type: "success",
					message: contact_response.message,
					position: "top-right",
				});
				setName("");
				setEmail("");
				setSuggestion("");
				setShowRecommendModal(false);
				setLoading(false);
			} else {
				toastMessage({
					type: "error",
					message: contact_response.message,
					position: "top-right",
				});
				setLoading(false);
			}
		} catch (error: any) {
			toastMessage({
				type: "error",
				message: `Error: ${error.message}`,
				position: "top-right",
			});
			setLoading(false);
		}
	};

	const seoProps = {
		title: "Explore Our Range of Personalized Products - AI Printable",
		description:
			"Discover the unique, personalized products available at AI Printable. From custom canvas prints to designer t-shirts, find the perfect item for yourself or as a gift.",
		keywords:
			"personalized products, AI Printable products, custom canvas prints, designer t-shirts, AI-generated designs",
		url: "https://www.aiprintable.com/our-products",
		contentType: "website",
		subject: "AI Printable Product Range",
		copyright: "AI Printable, © 2024",
		language: "en",
		robots: "index,follow",
		revised: "Sunday, March 7th, 2024, 5:15 pm",
		abstract:
			"AI Printable offers a wide range of personalized products, leveraging AI technology to create unique designs tailored to your preferences.",
		topic: "Personalized Products",
		summary:
			"At AI Printable, find a diverse selection of personalized products designed with AI. Explore our catalog and customize your favorite items today.",
		classification: "E-commerce, Personalized Products",
		author: "AI Printable, info@aiprintable.com",
		designer: "AI Printable Team",
		replyTo: "info@aiprintable.com",
		owner: "AI Printable",
		identifierURL: "https://www.aiprintable.com",
		directory: "submission",
		pagename: "Our Products - AI Printable",
		category: "Design, Personalization",
		coverage: "Worldwide",
		distribution: "Global",
		rating: "General",
		revisitAfter: "7 days",
		subtitle: "Personalization at Its Best",
		target: "all",
		handheldFriendly: "True",
		mobileOptimized: "320",
		date: "2024-03-07",
		searchDate: "2024-03-07",
		DCtitle: "AI Printable's Personalized Products",
	};

	return (
		<div className={cn(styles.container)}>
			<SEOHelmet {...seoProps} />
			<div className={cn(styles.titlecontainer)}>
				<Htag tag="h2">Our products</Htag>
				<P size="b1regular" style={{ color: "#6D6D6D" }}>
					Products that we are covering for you
				</P>
			</div>
			<div className={cn(styles.productallcontainers)}>
				{mockProducts.map((product) => (
					<OurProductItem key={product.name} product={product} />
				))}
				<div className={cn(styles.productcontainer)}>
					<Htag tag="h3">Didn’t find what you are searching for?</Htag>
					<P size="b2regular" style={{ color: "#5D5D5D" }}>
						Recommend the product you want
					</P>
					<Button
						appearance="black"
						style={{ padding: "12px 24px" }}
						onClick={() => setShowRecommendModal(true)}
					>
						Recommend
					</Button>
				</div>
			</div>
			{showRecommendModal && (
				<Modal
					title="Product recommendation"
					desc="Give us suggestion what kind of product you want?"
					openModal={showRecommendModal}
					setOpenModal={setShowRecommendModal}
				>
					<form
						style={{ display: "grid", gap: "24px" }}
						onSubmit={handleFormSubmit}
					>
						<Input
							label="Full Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							placeholder="Enter your full name"
							appearance="default"
							type="text"
							name="name"
						/>
						<Input
							label="Your Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="Enter your email"
							appearance="default"
							type="email"
							name="email"
						/>
						<TextArea
							label="What are you suggesting"
							value={suggestion}
							onChange={(e) => setSuggestion(e.target.value)}
							placeholder="Enter your suggestion"
							appearance="default"
							name="message"
						/>
						{!loading ? (
							<Button
								appearance="black"
								type="submit"
								style={{ padding: "14px 24px" }}
							>
								Submit
							</Button>
						) : (
							<Spinner />
						)}
					</form>
				</Modal>
			)}
		</div>
	);
};

export default OurProducts;
