import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { useLocation } from "react-router-dom";
import AuthLogin from "components/Auth/AuthLogin";
import RegisterModal from "../RegisterModal/RegisterModal";
import ForgetModal from "../ForgetModal/ForgetModal";

const AuthWrapper: any = ({ loggedIn }: any) => {
	const [authType, setAuthType] = useState("");

	const location = useLocation();
	useEffect(() => {
		try {
			const search_params = new URLSearchParams(location.search);
			const auth: any = search_params.get("auth");
			if (loggedIn) {
				setAuthType("");
			} else {
				switch (auth) {
					case "login":
						setAuthType("login");
						break;
					case "register":
						setAuthType("register");
						break;
					case "forgot-password":
						setAuthType("forgot-password");
						break;
					default:
						setAuthType("");
						break;
				}
			}
		} catch (err: any) {
			console.log(err);
		}
	}, [loggedIn, location.search]);

	return authType === "login" ? (
		<AuthLogin />
	) : authType === "register" ? (
		<RegisterModal />
	) : (
		authType === "forgot-password" && <ForgetModal />
	);
};

const mapStateToProps = (state: any) => ({
	loggedIn: state.auth.loggedIn,
});

export default connect(mapStateToProps)(AuthWrapper);
