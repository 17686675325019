import React from "react";
import { useParams } from "react-router-dom";
import ProductPage from "./ProductPage";

interface ProductPageWrapperProps {
	storeType: "client" | "admin";
}

const ProductPageWrapper: React.FC<ProductPageWrapperProps> = ({
	storeType,
}) => {
	const { id } = useParams();

	return <ProductPage res_id={id} storeType={storeType} />;
};

export default ProductPageWrapper;
